import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const socket = new WebSocket(
      this.data.get("socketUrl") + this.data.get("userId")
    );

    socket.onopen = (e) => {
      console.log("D365 Importer Connection Established");
      const msg = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "D365NotificationChannel",
          code: this.data.get("userId"),
        }),
      };
      socket.send(JSON.stringify(msg));
    };

    socket.onmessage = (event) => {
      if (
        JSON.parse(event.data).type !== "ping" &&
        JSON.parse(event.data).type !== "welcome" &&
        JSON.parse(event.data).type !== "confirm_subscription"
      ) {
        console.log(event.data);
        const rcd_msg = JSON.parse(event.data).message.message;
        const path = rcd_msg.page;
        const current_location = window.location.pathname.split("/").pop();
        if (rcd_msg.job_run === "true" && current_location === path) {
          location.reload();
        }
        if (rcd_msg.job_run === "error") {
          $.notify(
            {
              message:
                "Something Went Wrong. SCmple Support team has been notified.",
            },
            {
              type: "danger",
              placement: {
                align: "center",
              },
            }
          );
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      }
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `[close] Connection Closed, code=${event.code} reason=${event.reason}`
        );
        setTimeout(() => {
          this.connect();
        }, 1000);
      } else {
        console.log("[Close] Connection Died");
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  }
}
