import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toSelect", "fromSelect"];

  connect() {
    this.updateChildOptions();
  }

  updateChildOptions() {
    const parentBaseUom =
      this.fromSelectTarget.options[this.fromSelectTarget.selectedIndex].text;
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch("/bills_of_materials/fetch_uom_options.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        parentBaseUom,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.populate(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the AJAX call
        console.error(error);
      });

    const selectField = this.fromSelectTarget;
    selectField.dispatchEvent(new Event("select2:add", { bubbles: true }));
  }

  populate(options) {
    if (options) {
      this.toSelectTarget.innerHTML = "";
      for (const optionHtml of options) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = optionHtml;
        const optionElement = tempDiv.firstChild;

        this.toSelectTarget.appendChild(optionElement);
      }
    }
  }
}
