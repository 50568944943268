import useCsvPlugin from "@usecsv/js";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    importerKey: String,
    formAuthenticityToken: String,
    resourceName: String,
    importType: String,
  };

  validateDataAndReturnErrors(rows, metadata, localStorageValue = null) {
    return new Promise((resolve, reject) => {
      return fetch(`/${this.resourceNameValue}/import/validate_csv.json`, {
        method: "POST",
        body: JSON.stringify({ rows, metadata, localStorageValue }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "X-CSRF-Token": this.formAuthenticityTokenValue,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Validation failed");
          }
        })
        .then((data) => {
          console.log(data);
          resolve(data.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  launch() {
    useCsvPlugin({
      importerKey: this.importerKeyValue,
      user: { userId: "12345" },
      metadata: {},
      onData: (data) => {
        fetch(`/${this.resourceNameValue}/import`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "X-CSRF-Token": this.formAuthenticityTokenValue,
          },
        });
      },
      onClose: () => {
        localStorage.removeItem("billOfMaterialRowsData");
        window.location.href = `/${this.resourceNameValue}`;
        console.log("Importer is closed");
      },
      onRecordsInitial: ({
        uploadId,
        fileName,
        importerId,
        batch,
        user,
        metadata,
        rows,
      }) => {
        localStorage.setItem("billOfMaterialRowsData", JSON.stringify(rows));
        return this.validateDataAndReturnErrors(rows)
          .then((results) => {
            const arr = results.filter((data) => !!data);

            if (arr.length > 0) {
              return arr;
            } else {
              return;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
      onRecordEdit: ({
        uploadId,
        fileName,
        importerId,
        user,
        metadata,
        row,
      }) => {
        const rowsData = JSON.parse(
          localStorage.getItem("billOfMaterialRowsData")
        );
        if (rowsData) {
          const matchingRow = rowsData.find((data) => data.row === row.row);
          if (matchingRow) {
            Object.assign(matchingRow.data, row.data);
            localStorage.setItem(
              "billOfMaterialRowsData",
              JSON.stringify(rowsData)
            );
          }
        }
        return this.validateDataAndReturnErrors(
          row,
          metadata,
          JSON.parse(localStorage.getItem("billOfMaterialRowsData"))
        )
          .then((results) => {
            const arr = results.filter((data) => !!data);

            if (arr.length > 0) {
              return arr;
            } else {
              return;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  }
}
