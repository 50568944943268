import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["oktaFields", "input"];

  connect() {
    this.toggle();
  }

  toggle() {
    this.oktaFieldsTarget.classList.toggle(
      "d-none",
      this.isCheckedRadioNotOkta()
    );
  }

  isCheckedRadioNotOkta() {
    return this.inputTargets.find((input) => input.checked).value != "okta";
  }
}
