import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["timeText", "inputGroupContainer", "timeInput"];

  formatTime() {
    const localTimezone =
      this.inputGroupContainerTarget.dataset.userLocationTimezone;
    const browserTimezoneLongFormat =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const companyTimezone = this.inputGroupContainerTarget.value;
    const timeString = this.timeInputTarget.value;
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    // Make an AJAX call using fetch
    fetch(
      "/settings/administration/background_job_executions/parse_time.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          localTimezone,
          browserTimezoneLongFormat,
          companyTimezone,
          timeString,
        }),
      },
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.timeTextTarget.textContent = data.time;
      })
      .catch((error) => {
        // Handle any errors that occurred during the AJAX call
        console.error(error);
      });
  }
}
