import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "select", "template"];

  selectTargetConnected(element) {
    this.setContainerInnerHtml(element);
  }

  toggle(event) {
    const input = event.currentTarget;
    this.setContainerInnerHtml(input);
  }

  setContainerInnerHtml(input) {
    let selected = input.options[input.selectedIndex].value;

    if (selected === "other" && this.hasTemplateTarget) {
      this.containerTarget.innerHTML = "";
      const fields = this.templateTarget.content.cloneNode(true);
      this.containerTarget.appendChild(fields);
      this.containerTarget.classList.toggle("d-none", false);
    } else {
      if (this.containerTarget.querySelector("input")) {
        this.containerTarget.querySelector("input").setAttribute("value", "");
      }
      this.containerTarget.classList.toggle("d-none", true);
    }
  }
}
