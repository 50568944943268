import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectedUom", "output"];
  static values = {
    prepend: { type: String, default: "/ " },
  };

  outputTargetConnected(element) {
    element.innerHTML = `${this.prependValue}${this.inputUomText}`;
  }

  updateUomLabel() {
    this.outputTargets.forEach((outputTarget) => {
      outputTarget.innerHTML = `${this.prependValue}${this.inputUomText}`;
    });
  }

  get inputUomText() {
    return this.selectedUomTarget.options[this.selectedUomTarget.selectedIndex]
      .text;
  }
}
