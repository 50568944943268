import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "countrySelect",
    "zipcode",
    "state",
    "zipcodeError",
    "city",
  ];

  connect() {
    const input = this.countrySelectTarget;
    const selectedCountry = input.options[input.selectedIndex].value;
    // this.setContainerInnerHtml(selectedCountry);
  }

  toggle(event) {
    const input = event.currentTarget;
    const selectedCountry = input.options[input.selectedIndex].value;
    // this.setContainerInnerHtml(selectedCountry);
  }

  validateZipcode(event) {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    const zipcode = this.zipcodeTarget.value;
    const input = this.countrySelectTarget;
    const selectedCountry = input.options[input.selectedIndex].value;

    fetch("/suppliers/validate_zipcode.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        zipcode,
        selectedCountry,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          this.zipcodeErrorTarget.classList.toggle("d-none", true);
          this.stateTarget.value = data.state;
          this.cityTarget.value = data.city;
          if (data.city) {
            this.cityTarget.value = data.city;
            this.cityTarget.readOnly = true;
          } else {
            this.cityTarget.value = "";
            this.cityTarget.readOnly = false;
          }
        } else {
          this.zipcodeErrorTarget.classList.toggle("d-none", false);
          this.zipcodeErrorTarget.textContent = "Invalid Pincode";
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the AJAX call
        console.error(error);
      });
  }

  setContainerInnerHtml(selectedCountry) {
    const COUNTRIES = ["US", "CA"];
    // const templateId =
    //   COUNTRIES.indexOf(selectedCountry) > -1 ? `state-${selectedCountry}-select` : "state-text"
    const templateId = `state-${selectedCountry}-select`;
    const template = document.getElementById(templateId);
    const fields = template.content.cloneNode(true);

    this.containerTarget.innerHTML = "";
    this.containerTarget.appendChild(fields);

    const selectField = this.containerTarget.querySelector("select");
    if (selectField) {
      selectField.dispatchEvent(new Event("select2:add", { bubbles: true }));
    }
  }
}
