import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["noticeDiv", "inputGroupContainer"];

  connect() {
    const currentTime = new Date();
    const browserTimezone = this.getTimezoneAbbreviation(currentTime);
    console.log(browserTimezone);
    const browserTimezoneLongFormat =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const companyTimezone = this.inputGroupContainerTarget.value;
    this.inputGroupContainerTarget.dataset.userLocationTimezone =
      browserTimezone;
    this.inputGroupContainerTarget.dataset.browserLocationTimezone =
      browserTimezoneLongFormat;
    if (browserTimezone == companyTimezone) {
      this.noticeDivTarget.classList.add("d-none");
    } else {
      this.noticeDivTarget.classList.remove("d-none");
    }
  }

  getTimezoneAbbreviation(dateTime) {
    const timezoneOffset = dateTime.getTimezoneOffset() / 60;
    const timezoneAbbreviations = {
      "-12": "IDLW",
      "-11": "NT",
      "-10": "HST",
      "-9": "AKST",
      "-8": "PST",
      "-7": "MST",
      "-6": "CST",
      "-5": "EST",
      "-5.5": "IST",
      "-4": "AST",
      "-3.5": "NST",
      "-3": "BRT",
      "-2": "AT",
      "-1": "WAT",
      "0": "GMT",
      "1": "CET",
      "2": "EET",
      "3": "MSK",
      "3.5": "IRST",
      "4": "GST",
      "4.5": "AFT",
      "5": "IST",
      "5.5": "IST",
      "5.75": "NPT",
      "6": "BST",
      "6.5": "MST",
      "7": "THA",
      "8": "CST",
      "9": "JST",
      "9.5": "ACST",
      "10": "AEST",
      "10.5": "ACDT",
      "11": "AEDT",
      "12": "NZST",
      "-4": "EDT",
      "-3": "ADT",
      "-2": "BRST",
      "-1": "NDT",
      "1": "BST",
      "3": "EEST",
      "4": "PKT",
      "5": "MVT",
      "5.5": "SLST",
      "5.75": "NPT",
      "6": "BIOT",
      "6.5": "CCT",
      "7": "CXT",
      "8": "AWST",
      "8.75": "ACWST",
      "9": "KST",
      "9.5": "ACDT",
      "10": "AEDT",
      "10.5": "NFT",
      "11": "VUT",
      "12": "FJT",
      "12.75": "NZDT",
      "13": "PHOT",
      "14": "LINT",
    };

    return timezoneAbbreviations[timezoneOffset.toString()] || "GMT";
  }
}
