import useCsvPlugin from "@usecsv/js";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    importerKey: String,
    materialTypes: Array,
    formAuthenticityToken: String,
    resourceName: String,
    importType: String,
  };

  validateDataAndReturnErrors(materialType, lotSizeType, lotSize) {
    const isValidMaterialType = this.materialTypesValue.includes(materialType);
    const data = {};
    if (!isValidMaterialType) {
      data["Material Type"] = [
        {
          level: "error",
          message: "Invalid material type", // error message
        },
      ];
    }
    if (lotSizeType !== "Exact" && lotSize === "") {
      data["Lot Size"] = [
        {
          level: "error",
          message:
            "Lot Size is required for Lot Size Type of Fixed and Minimum", // error message
        },
      ];
    }
    if (Object.keys(data).length !== 0) {
      return data;
    }
    return null;
  }

  launch() {
    useCsvPlugin({
      importerKey: this.importerKeyValue,
      user: { userId: "12345" },
      metadata: {
        type: this.importTypeValue,
      },
      onData: (data) => {
        console.log(this.importTypeValue);
        fetch(`/${this.resourceNameValue}/import`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "X-CSRF-Token": this.formAuthenticityTokenValue,
          },
        });
      },
      onClose: () => console.log("Importer is closed"),
      onRecordsInitial: ({
        uploadId,
        fileName,
        importerId,
        batch,
        user,
        metadata,
        rows,
      }) => {
        const arr = [];
        rows.forEach((row) => {
          const data = this.validateDataAndReturnErrors(
            row.data["Material Type"].value,
            row.data["Lot Size Type"].value,
            row.data["Lot Size"].value
          );
          if (!!data) {
            arr.push({ row: row.row, data });
          }
        });
        if (arr.length > 0) {
          return arr;
        }
        return;
      },
      onRecordEdit: ({
        uploadId,
        fileName,
        importerId,
        user,
        metadata,
        row,
      }) => {
        const data = this.validateDataAndReturnErrors(
          row.data["Material Type"].value
        );
        if (!!data) {
          return { row: row.row, data };
        }
        return;
      },
    });
  }
}
