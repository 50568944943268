import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["domainName"];

  validateDomain() {
    let url = this.domainNameTarget.value;
    let domain = this.extractDomain(url);
    this.domainNameTarget.value = domain;
  }

  extractDomain(url) {
    var rx = /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/;
    var arr = rx.exec(url);
    return arr && arr[1];
  }
}
