import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "container"];

  addAssociation(event) {
    event.preventDefault();
    let content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().getTime()
    );
    this.containerTarget.insertAdjacentHTML("beforeend", content);

    const selectFields = this.containerTarget.querySelectorAll("select");
    if (selectFields) {
      selectFields.forEach((selectField) =>
        selectField.dispatchEvent(new Event("select2:add", { bubbles: true }))
      );
    }
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-form-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.classList.add("d-none");
  }
}
