import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reasonInput", "loginButton"];

  login(event) {
    $("#id").val(event.target.dataset.userId);

    this.reasonInputTargets.forEach((reasonInputTarget) => {
      reasonInputTarget.addEventListener("input", () => {
        const confirmText = reasonInputTarget.value.trim().toLowerCase();
        const loginButton = this.loginButtonTarget;
        if (confirmText == "") {
          loginButton.setAttribute("disabled", true);
        } else {
          loginButton.removeAttribute("disabled");
        }
      });
    });
  }
}
