import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";
import debounced from "debounced";

import Adaptable from "@adaptabletools/adaptable/agGrid";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { LicenseManager } from "@ag-grid-enterprise/core";
import warningIcon from "../../assets/images/media/bell.svg";

const agGridModules = [
  ClientSideRowModelModule,
  GridChartsModule,
  ExcelExportModule,
  CsvExportModule,
];

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-058892}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SCmple_Solutions,_LLC}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{SCmple}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{SCmple}_need_to_be_licensed___{SCmple}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_May_2025}____[v3]_[01]_MTc0NzY5NTYwMDAwMA==9a12018820cebed1f8fdc148715f8ef3",
);

let stimulus = {};

export default class extends Controller {
  static targets = [
    "parametersForm",
    "material",
    "scenario",
    "aggregationType",
    "chart",
    "stock",
    "remainingShelfLife",
    "safetyStockType",
    "fixedSafetyStockQuanity",
    "safetyStockDuration",
    "safetyStockDurationPeriod",
    "lotSizeType",
    "fixedLotSize",
    "minimumLotSize",
    "lotSizeRoundingValue",
    "yield",
    "supplier",
    "purchaseOrder",
    "demandVariationContainer",
    "errorModal",
    "errorModalTemplate",
    "scenarioError",
    "scenarioErrorModalContainer",
    "bellIcon",
    "errorCountSpan",
    "errorBoxContainer",
    "submitButton",
    "blankCapacityContainer",
    "demandTable",
    "timelineStartDate",
    "timelineEndDate",
    "timelineDatePicker",
    "shelfLifeValue",
    "shelfLifePeriod",
    "sellByPeriod",
    "supplierMaterialsContainer",
    "supplierMaterials",
    "supplierPercentageAllocation",
    "supplierLotSizeType",
    "supplierFixedLotSize",
    "supplierMinimumLotSize",
    "supplierLotSizeRoundingValue",
    "poExpDate",
    "campaignStrategy",
    "campaignStartDate",
    "campaignDurationInMonths",
    "campaignTimelineInMonths",
    "scenariosLoader",
    "workOrder",
    "mosType",
    "monthsOfSupply",
  ];

  initialize() {
    debounced.initialize({ ...debounced.events, changeDate: { wait: 1000 } });
  }

  async connect() {
    stimulus = this;
    localStorage.removeItem("Scenario Planning");

    const adaptableOptions = {
      primaryKey: "id",
      adaptableId: "Scenario Planning",
      licenseKey:
        "AppName=scmple|Owner=SCmple|StartDate=2024-05-16|EndDate=2025-05-16|Ref=AdaptableLicense|TS=1715858732458|C=3296523092,500258536,1260976079,2023408945,3740126853,1302728593,2345346467",
      exportOptions: {
        exportDateFormat: "MMM yyyy",
        appendFileTimestamp: true,
      },
      gridFilterOptions: {
        clearGridFilterOnStartUp: true,
      },
      predicateOptions: {
        customPredicateDefs: [
          {
            id: "cumicapacity",
            label: "Capacity",
            columnScope: {
              ColumnIds: ["capacity"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              let cumu_produc_plan =
                stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  params.node,
                  "cumulated_production_plan",
                );
              let capacity =
                stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  params.node,
                  "capacity",
                );
              if (params.value === null) {
                return false;
              }
              if (
                parseInt(cumu_produc_plan.replace(/,/g, ""), 10) >
                parseInt(capacity.replace(/,/g, ""), 10)
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          {
            id: "exp_inventory",
            label: "Available",
            columnScope: {
              ColumnIds: ["campaign_available"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              let month_year = params.node.data.month_year;
              let expired_lots_count =
                stimulus.getExpiredStockQuantity(month_year);
              if (expired_lots_count > 0) {
                return true;
              }
            },
          },
          {
            id: "exp_purchase_order",
            label: "Available",
            columnScope: {
              ColumnIds: ["campaign_available"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              let month_year = params.node.data.month_year;
              let expired_pos_count = stimulus.getExpiredPoQuantity(month_year);
              if (expired_pos_count > 0) {
                return true;
              }
            },
          },
          {
            id: "edited_forecast",
            label: "calculated_demand",
            columnScope: {
              ColumnIds: ["calculated_demand"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              let month_year = params.node.data.month_year;
              if (stimulus.editedDemands) {
                const editedDemand =
                  stimulus.editedDemands[params.node.data.month_year];
                if (editedDemand) {
                  return true;
                }
              }
            },
          },
          {
            id: "past_po",
            label: "projected_receipts",
            columnScope: {
              ColumnIds: ["projected_receipts"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              return stimulus.pastPOAvailable(params.node.data.month_year);
            },
          },
          {
            id: "past_wo",
            label: "work_orders",
            columnScope: {
              ColumnIds: ["work_orders"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              return stimulus.pastWOAvailable(params.node.data.month_year);
            },
          },
          {
            id: "custom_planned_orders",
            label: "planned_orders",
            columnScope: {
              ColumnIds: ["campaign_planned_orders"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              const changed_planned_orders =
                stimulus.basePlannedOrderChangedData(params.node);
              if (changed_planned_orders.length > 0) {
                return true;
              }
            },
          },
          {
            id: "sell_by_period",
            label: "mos",
            columnScope: {
              ColumnIds: ["mos"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              return stimulus.sellByPeriodExceedingMos(params.node);
            },
          },
          {
            id: "supllier_allocation",
            label: "planned_orders",
            columnScope: {
              ColumnIds: ["campaign_planned_orders"],
            },
            moduleScope: ["formatColumn"],
            handler(params) {
              return stimulus.plannedOrderAllocated(params.node);
            },
          },
        ],
      },
      actionColumnOptions: {
        actionColumns: [
          {
            columnId: "view_alerts",
            friendlyName: " ",
            actionColumnSettings: {
              suppressMenu: true,
              resizable: false,
            },
            actionColumnButton: {
              icon: {
                style: { height: 20, width: 20 },
                src: warningIcon,
                className: "text-right",
              },
              onClick: (button, context) => {
                stimulus.showAlertBox(button, context);
              },
              hidden: (button, context) => {
                return stimulus.alertButtonToHide(context);
              },
              disabled: (button, context) => {
                return false;
              },
              buttonStyle: (button, context) => ({
                className: `mx-auto button-${context.primaryKeyValue}`,
              }),
            },
          },
        ],
      },
      predefinedConfig: {
        Dashboard: {
          IsHidden: true,
        },
        Layout: {
          CurrentLayout: "Monthly",
          Layouts: [
            {
              Columns: [
                "month_year",
                "demand",
                "calculated_demand",
                "ddmrp_demand",
                "projected_receipts",
                "safety_stock",
                "ddmrp_safety_stock",
                "planned_orders",
                "work_orders",
                "campaign_planned_orders",
                "campaign_production_plan",
                "campaign_cumulated_production_plan",
                "campaign_available",
                "campaign_projected_inventory",
                "mos",
                "capacity",
                "view_alerts",
              ],
              Name: "Monthly",
              ColumnWidthMap: {
                month_year: 90,
                demand: 90,
                calculated_demand: 90,
                ddmrp_demand: 80,
                projected_receipts: 100,
                safety_stock: 90,
                ddmrp_safety_stock: 100,
                planned_orders: 90,
                work_orders: 80,
                campaign_planned_orders: 120,
                campaign_production_plan: 100,
                campaign_cumulated_production_plan: 100,
                campaign_available: 100,
                campaign_projected_inventory: 100,
                mos: 90,
                capacity: 100,
                view_alerts: 60,
              },
            },
            {
              Columns: [
                "quarter",
                "quarterly_base_demand",
                "quarterly_calculated_demand",
                "quarterly_projected_receipts",
                "quarterly_safety_stock",
                "quarterly_production_plan",
                "quarterly_cumulated_production_plan",
                "quarterly_available",
                "quarterly_projected_inventory",
                "capacity",
              ],
              Name: "Quarterly",
              ColumnWidthMap: {
                quarter: 100,
                quarterly_base_demand: 120,
                quarterly_calculated_demand: 140,
                quarterly_projected_receipts: 140,
                quarterly_safety_stock: 140,
                quarterly_production_plan: 140,
                quarterly_cumulated_production_plan: 180,
                quarterly_available: 140,
                quarterly_projected_inventory: 140,
                capacity: 100,
              },
            },
            {
              Columns: [
                "year",
                "yearly_base_demand",
                "yearly_calculated_demand",
                "yearly_projected_receipts",
                "yearly_safety_stock",
                "yearly_production_plan",
                "yearly_cumulated_production_plan",
                "yearly_available",
                "yearly_projected_inventory",
                "capacity",
              ],
              Name: "Yearly",
              ColumnWidthMap: {
                year: 100,
                yearly_base_demand: 120,
                yearly_calculated_demand: 140,
                yearly_projected_receipts: 140,
                yearly_safety_stock: 140,
                yearly_production_plan: 140,
                yearly_cumulated_production_plan: 180,
                yearly_available: 140,
                yearly_projected_inventory: 140,
                capacity: 100,
              },
            },
          ],
        },
        FormatColumn: {
          FormatColumns: [
            {
              Style: {
                BackColor: "#f8d7da",
                ForeColor: "#842029",
              },
              Scope: {
                ColumnIds: ["capacity", "cumulated_production_plan"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "cumicapacity",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#f8d7da",
                ForeColor: "#842029",
              },
              Scope: {
                ColumnIds: ["campaign_available"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "exp_inventory",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#f8d7da",
                ForeColor: "#842029",
              },
              Scope: {
                ColumnIds: ["campaign_available"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "exp_purchase_order",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#fff3cd",
                ForeColor: "#856404",
              },
              Scope: {
                ColumnIds: ["calculated_demand"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "edited_forecast",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#cce5ff",
                ForeColor: "#004085",
              },
              Scope: {
                ColumnIds: ["projected_receipts"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "past_po",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#cce5ff",
                ForeColor: "#004085",
              },
              Scope: {
                ColumnIds: ["work_orders"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "past_wo",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#cce5ff",
                ForeColor: "#004085",
              },
              Scope: {
                ColumnIds: ["campaign_planned_orders"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "custom_planned_orders",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#f8d7da",
                ForeColor: "#842029",
              },
              Scope: {
                ColumnIds: ["mos"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "sell_by_period",
                  },
                ],
              },
            },
            {
              Style: {
                BackColor: "#cce5ff",
                ForeColor: "#004085",
              },
              Scope: {
                ColumnIds: ["campaign_planned_orders"],
              },
              Rule: {
                Predicates: [
                  {
                    PredicateId: "supllier_allocation",
                  },
                ],
              },
            },
            {
              CellAlignment: "Right",
              Scope: {
                DataTypes: ["Number"],
              },
              DisplayFormat: {
                Formatter: "NumberFormatter",
                Options: {
                  FractionDigits: 2,
                },
              },
            },
          ],
        },
        CalculatedColumn: {
          CalculatedColumns: [
            {
              ColumnId: "quarter",
              FriendlyName: "Quarter",
              Query: {
                ScalarExpression: "CALCULATE_QUARTER()",
              },
              CalculatedColumnSettings: {
                DataType: "String",
              },
            },
            {
              ColumnId: "year",
              FriendlyName: "Year",
              Query: {
                ScalarExpression: "CALCULATE_YEAR()",
              },
              CalculatedColumnSettings: {
                DataType: "String",
              },
            },
            {
              ColumnId: "calculated_demand",
              FriendlyName: "Scenario Demand",
              Query: {
                ScalarExpression: "VAR('CALCULATED_DEMAND', [id])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_base_demand",
              FriendlyName: "Quarterly Base Demand",
              Query: {
                AggregatedScalarExpression:
                  "SUM([demand], GROUP_BY([quarter]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_base_demand",
              FriendlyName: "Yearly Base Demand",
              Query: {
                AggregatedScalarExpression: "SUM([demand], GROUP_BY([year]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_calculated_demand",
              FriendlyName: "Quarterly Calculated Demand",
              Query: {
                AggregatedScalarExpression:
                  "SUM([calculated_demand], GROUP_BY([quarter]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_calculated_demand",
              FriendlyName: "Yearly Calculated Demand",
              Query: {
                AggregatedScalarExpression:
                  "SUM([calculated_demand], GROUP_BY([year]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_projected_receipts",
              FriendlyName: "Quarterly Open PO",
              Query: {
                AggregatedScalarExpression:
                  "SUM([projected_receipts], GROUP_BY([quarter]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_projected_receipts",
              FriendlyName: "Yearly Open PO",
              Query: {
                AggregatedScalarExpression:
                  "SUM([projected_receipts], GROUP_BY([year]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "safety_stock",
              FriendlyName: "Safety Stock",
              Query: {
                ScalarExpression: "CALCULATE_SAFETY_STOCK()",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "ddmrp_safety_stock",
              FriendlyName: "DDMRP Safety Stock",
              Query: {
                ScalarExpression: "CALCULATE_DDMRP_SAFETY_STOCK()",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_safety_stock",
              FriendlyName: "Quarterly Safety Stock",
              Query: {
                AggregatedScalarExpression:
                  "SUM([safety_stock], GROUP_BY([quarter]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_safety_stock",
              FriendlyName: "Yearly Safety Stock",
              Query: {
                AggregatedScalarExpression:
                  "SUM([safety_stock], GROUP_BY([year]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "planned_orders",
              FriendlyName: "Planned Orders",
              Query: {
                ScalarExpression:
                  "CALCULATE_PLANNED_ORDER([calculated_demand], [projected_receipts], [safety_stock], [work_orders])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "campaign_planned_orders",
              FriendlyName: "Campaign Planned Orders",
              Query: {
                ScalarExpression:
                  "CALCULATE_CAMPAIGN_PLANNED_ORDER([month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "production_plan",
              FriendlyName: "Production Plan",
              Query: {
                ScalarExpression:
                  "[planned_orders] + [projected_receipts] + [work_orders]",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "campaign_production_plan",
              FriendlyName: "Production Plan",
              Query: {
                ScalarExpression:
                  "CALCULATE_CAMPAIGN_PRODUCTION_PLAN([month_year], [projected_receipts], [work_orders])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_production_plan",
              FriendlyName: "Quarterly Planned Order",
              Query: {
                AggregatedScalarExpression:
                  "SUM([planned_orders], GROUP_BY([quarter]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_production_plan",
              FriendlyName: "Yearly Planned Order",
              Query: {
                AggregatedScalarExpression:
                  "SUM([planned_orders], GROUP_BY([year]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "cumulated_production_plan",
              FriendlyName: "Cumulated Prod. Plan",
              Query: {
                ScalarExpression:
                  "CALCULATE_CUMULATED_PRODUCTION_PLAN([month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "campaign_cumulated_production_plan",
              FriendlyName: "Cumulated Prod. Plan",
              Query: {
                ScalarExpression:
                  "CALCULATE_CAMPAIGN_CUMULATED_PRODUCTION_PLAN([month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_cumulated_production_plan",
              FriendlyName: "Quarterly Cumulated Prod. Plan",
              Query: {
                AggregatedScalarExpression:
                  "MAX([cumulated_production_plan], GROUP_BY([quarter]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_cumulated_production_plan",
              FriendlyName: "Yearly Cumulated Prod. Plan",
              Query: {
                AggregatedScalarExpression:
                  "MAX([cumulated_production_plan], GROUP_BY([year]))",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "available",
              FriendlyName: "Available Inventory",
              Query: {
                ScalarExpression:
                  "VAR('PREVIOUS_MONTH_PROJECTED_INVENTORY', [id]) + [projected_receipts] + [work_orders] + [planned_orders] - [calculated_demand] - [safety_stock] - VAR('EXPIRED_STOCK', [month_year]) - VAR('EXPIRED_PO', [month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "campaign_available",
              FriendlyName: "Available Inventory",
              Query: {
                ScalarExpression:
                  "VAR('PREVIOUS_MONTH_CAMPAIGN_PROJECTED_INVENTORY', [id]) + [projected_receipts] + [work_orders] + [campaign_planned_orders] - [calculated_demand] - [safety_stock] - VAR('EXPIRED_STOCK', [month_year]) - VAR('EXPIRED_PO', [month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_available",
              FriendlyName: "Quarterly Available",
              Query: {
                ScalarExpression: "CALCULATE_QUARTERLY_AVAILABLE()",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_available",
              FriendlyName: "Yearly Available",
              Query: {
                ScalarExpression: "CALCULATE_YEARLY_AVAILABLE()",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "projected_inventory",
              FriendlyName: "Projected Inventory",
              Query: {
                ScalarExpression: "[available] + [safety_stock]",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "campaign_projected_inventory",
              FriendlyName: "Projected Inventory",
              Query: {
                ScalarExpression: "[campaign_available] + [safety_stock]",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "quarterly_projected_inventory",
              FriendlyName: "Quarterly Proj. Inventory",
              Query: {
                ScalarExpression: "CALCULATE_QUARTERLY_PROJECTED_INVENTORY()",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "yearly_projected_inventory",
              FriendlyName: "Yearly Proj. Inventory",
              Query: {
                ScalarExpression: "CALCULATE_YEARLY_PROJECTED_INVENTORY()",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "mos",
              FriendlyName: "Months of Supply",
              Query: {
                ScalarExpression: "CALCULATE_MOS()",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "capacity",
              FriendlyName: "Yearly Capacity",
              Query: {
                ScalarExpression: "CALCULATE_CAPACITY([month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "projected_receipts",
              FriendlyName: "Open\nP.O.",
              Query: {
                ScalarExpression: "CALCULATE_PURCHASE_ORDER([month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
            {
              ColumnId: "work_orders",
              FriendlyName: "Work Orders",
              Query: {
                ScalarExpression: "CALCULATE_WORK_ORDER([month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
          ],
        },
      },
      expressionOptions: {
        customQueryVariables: {
          CALCULATED_DEMAND: (context) => {
            const currentRowId = context.args[0];
            const currentRow =
              context.adaptableApi.gridApi.getRowNodeForPrimaryKey(
                currentRowId,
              );
            if (this.editedDemands) {
              const editedDemand =
                this.editedDemands[currentRow.data.month_year];
              if (editedDemand) {
                return stimulus.formatNumberAsPerUom(editedDemand);
              }
            }
            const rowMonthYearTimestamp = currentRow.data.month_year.valueOf();
            const demand = currentRow.data.demand;
            let result = demand;
            let updatedDemand = demand;

            if (stimulus.hasDemandVariationContainerTarget) {
              stimulus.demandVariationContainerTargets.forEach(
                (demandVariationFormGroup) => {
                  const demandVariationInputValue = stimulus.parseNumber(
                    demandVariationFormGroup.querySelector(".demand-variation")
                      .value,
                  );
                  const demandVariationType =
                    demandVariationFormGroup.querySelector(
                      ".demand-variation-type",
                    ).value;
                  const demandVariationStartMonthString =
                    demandVariationFormGroup.querySelector(
                      ".demand-variation-start-month",
                    ).value;
                  const demandVariationEndMonthString =
                    demandVariationFormGroup.querySelector(
                      ".demand-variation-end-month",
                    ).value;
                  const demandVariationStartTimestamp = Date.parse(
                    demandVariationStartMonthString,
                  );
                  const demandVariationEndTimestamp = Date.parse(
                    demandVariationEndMonthString,
                  );

                  if (demandVariationType === "exact") {
                    updatedDemand = result + demandVariationInputValue;
                  } else {
                    const demandVariation = demandVariationInputValue / 100;
                    updatedDemand = result + demand * demandVariation;
                  }

                  if (
                    demandVariationStartTimestamp &&
                    demandVariationEndTimestamp
                  ) {
                    if (
                      demandVariationStartTimestamp <= rowMonthYearTimestamp &&
                      rowMonthYearTimestamp <= demandVariationEndTimestamp
                    ) {
                      result = updatedDemand;
                    }
                  } else if (
                    demandVariationStartTimestamp ||
                    demandVariationEndTimestamp
                  ) {
                    if (
                      demandVariationStartTimestamp <= rowMonthYearTimestamp ||
                      rowMonthYearTimestamp <= demandVariationEndTimestamp
                    ) {
                      result = updatedDemand;
                    }
                  } else {
                    result = updatedDemand;
                  }
                },
              );
            }
            const yieldFactor = stimulus.parseNumber(
              stimulus.yieldTarget.value,
            );
            const actualDemandUnrounded =
              result - result * (1 - stimulus.baseYieldFactor / 100);
            const actualDemand =
              Math.round((actualDemandUnrounded + Number.EPSILON) * 100) / 100;
            result = actualDemand / (yieldFactor / 100);
            result = Math.round((result + Number.EPSILON) * 100) / 100;

            stimulus.updateStockLots(currentRow.data.month_year, result);
            stimulus.updatePurchaseOrders(currentRow.data.month_year, result);

            return stimulus.formatNumberAsPerUom(result);
          },
          PREVIOUS_MONTH_PROJECTED_INVENTORY: (context) => {
            let previousMonthProjectedInventory = stimulus.getTotalStock();

            const currentRowId = Number(context.args);
            const firstRowID =
              context.adaptableApi.gridApi.getFirstRowNode().data.id;

            if (currentRowId !== firstRowID) {
              for (let id = firstRowID; id < currentRowId; id++) {
                let currentIterationRow =
                  context.adaptableApi.gridApi.getRowNodeForPrimaryKey(id);
                if (!currentIterationRow) continue;
                let currentIterationCalculatedDemand = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "calculated_demand",
                  ),
                );
                let currentIterationSafetyStock = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "safety_stock",
                  ),
                );
                let currentIterationProjectedReceipts = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "projected_receipts",
                  ),
                );
                let currentIterationWorkOrders = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "work_orders",
                  ),
                );
                let currentIterationExpiredStock =
                  stimulus.getExpiredStockQuantity(
                    currentIterationRow.data.month_year,
                  );

                let currentIterationExpiredPo = stimulus.getExpiredPoQuantity(
                  currentIterationRow.data.month_year,
                );

                let currentIterationProductionPlan = 0;
                let currentIterationAvailable =
                  previousMonthProjectedInventory -
                  currentIterationCalculatedDemand -
                  currentIterationSafetyStock +
                  currentIterationWorkOrders +
                  currentIterationProjectedReceipts -
                  currentIterationExpiredPo -
                  currentIterationExpiredStock;

                if (currentIterationAvailable < 0) {
                  currentIterationProductionPlan =
                    stimulus.calculateProductionPlan(
                      Math.abs(`${currentIterationAvailable}`),
                      currentIterationRow.data.month_year,
                    );
                }

                previousMonthProjectedInventory =
                  previousMonthProjectedInventory +
                  currentIterationProductionPlan -
                  currentIterationCalculatedDemand +
                  currentIterationWorkOrders +
                  currentIterationProjectedReceipts -
                  currentIterationExpiredPo -
                  currentIterationExpiredStock;
              }
            }

            return stimulus.formatNumberAsPerUom(
              previousMonthProjectedInventory,
            );
          },
          PREVIOUS_MONTH_CAMPAIGN_PROJECTED_INVENTORY: (context) => {
            let previousMonthProjectedInventory = stimulus.getTotalStock();

            const currentRowId = Number(context.args);
            const firstRowID =
              context.adaptableApi.gridApi.getFirstRowNode().data.id;

            if (currentRowId !== firstRowID) {
              for (let id = firstRowID; id < currentRowId; id++) {
                let currentIterationRow =
                  context.adaptableApi.gridApi.getRowNodeForPrimaryKey(id);
                if (!currentIterationRow) continue;
                let currentIterationCalculatedDemand = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "calculated_demand",
                  ),
                );
                let currentIterationSafetyStock = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "safety_stock",
                  ),
                );
                let currentIterationProjectedReceipts = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "projected_receipts",
                  ),
                );

                let currentIterationWorkOrders = stimulus.parseNumber(
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    currentIterationRow,
                    "work_orders",
                  ),
                );

                let currentIterationExpiredStock =
                  stimulus.getExpiredStockQuantity(
                    currentIterationRow.data.month_year,
                  );

                let currentIterationExpiredPo = stimulus.getExpiredPoQuantity(
                  currentIterationRow.data.month_year,
                );

                let currentIterationProductionPlan = 0;
                let currentIterationAvailable =
                  previousMonthProjectedInventory -
                  currentIterationCalculatedDemand -
                  currentIterationSafetyStock +
                  currentIterationWorkOrders +
                  currentIterationProjectedReceipts -
                  currentIterationExpiredPo -
                  currentIterationExpiredStock;

                if (currentIterationAvailable < 0) {
                  let campaign_lot = null;
                  if (stimulus.campaignStartDateTarget.value) {
                    campaign_lot = stimulus.getValueAsPerCampaign(
                      currentIterationRow.data.month_year,
                    );
                    currentIterationProductionPlan =
                      stimulus.calculateProductionPlan(
                        Math.abs(`${currentIterationAvailable}`),
                        currentIterationRow.data.month_year,
                        campaign_lot,
                      );
                  } else {
                    currentIterationProductionPlan =
                      stimulus.calculateProductionPlan(
                        Math.abs(`${currentIterationAvailable}`),
                        currentIterationRow.data.month_year,
                      );
                  }
                }

                previousMonthProjectedInventory =
                  previousMonthProjectedInventory +
                  currentIterationProductionPlan -
                  currentIterationCalculatedDemand +
                  currentIterationWorkOrders +
                  currentIterationProjectedReceipts -
                  currentIterationExpiredPo -
                  currentIterationExpiredStock;
              }
            }

            return stimulus.formatNumberAsPerUom(
              previousMonthProjectedInventory,
            );
          },
          EXPIRED_STOCK: (context) => {
            const monthYear = context.args[0];
            const expiredStock = stimulus.getExpiredStockQuantity(monthYear);
            return stimulus.formatNumberAsPerUom(expiredStock);
          },
          EXPIRED_PO: (context) => {
            const monthYear = context.args[0];
            const expiredPo = stimulus.getExpiredPoQuantity(monthYear);
            return stimulus.formatNumberAsPerUom(expiredPo);
          },
        },
        customScalarFunctions: {
          CALCULATE_QUARTER: {
            handler(args, context) {
              const month = context.node.data.month_year.getMonth();
              return `Q${
                Math.floor(month / 3) + 1
              } ${context.node.data.month_year.getFullYear()}`;
            },
          },
          CALCULATE_YEAR: {
            handler(args, context) {
              return context.node.data.month_year.getFullYear();
            },
          },
          CALCULATE_SAFETY_STOCK: {
            handler(args, context) {
              if (stimulus.getSafetyStockType() === "fixed") {
                const fixedSafetyStockInput =
                  stimulus.fixedSafetyStockQuanityTarget.value;

                return stimulus.formatNumberAsPerUom(fixedSafetyStockInput);
              }

              const currentMonthYear = context.node.data.month_year;
              const safetyStockDuration = stimulus.parseNumber(
                stimulus.safetyStockDurationTarget.value,
              );
              const safetyStockDurationPeriod =
                stimulus.safetyStockDurationPeriodTarget.value;
              let safetyStockDurationInMonths = 0;

              switch (safetyStockDurationPeriod) {
                case "Days":
                  if ([365, 366].includes(safetyStockDuration)) {
                    safetyStockDurationInMonths = 12;
                  } else {
                    safetyStockDurationInMonths = safetyStockDuration / 30;
                  }
                  break;
                case "Years":
                  safetyStockDurationInMonths = safetyStockDuration * 12;
                  break;
                default:
                  safetyStockDurationInMonths = safetyStockDuration;
              }

              const numberOfMonths = Math.ceil(safetyStockDurationInMonths);
              const lastMonthMultiplier = safetyStockDurationInMonths % 1;
              let safetyStock = 0;
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();

              for (let index = 1; index <= numberOfMonths; index++) {
                const newDate = new Date(currentMonthYear.getTime()); // Create a copy of the date object
                newDate.setMonth(newDate.getMonth() + index); // Add one month
                const rowNodeAtNewDate = allRowNodes.find(
                  (node) =>
                    node.data.month_year.toString() === newDate.toString(),
                );

                if (rowNodeAtNewDate) {
                  const calculatedDemandAtNewDate = stimulus.parseNumber(
                    context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                      rowNodeAtNewDate,
                      "calculated_demand",
                    ),
                  );

                  if (index === numberOfMonths && lastMonthMultiplier > 0) {
                    safetyStock +=
                      calculatedDemandAtNewDate * lastMonthMultiplier;
                  } else {
                    safetyStock += calculatedDemandAtNewDate;
                  }
                }
              }

              return stimulus.formatNumberAsPerUom(safetyStock);
            },
          },
          CALCULATE_DDMRP_SAFETY_STOCK: {
            handler(args, context) {
              if (stimulus.getSafetyStockType() === "fixed") {
                const fixedSafetyStockInput =
                  stimulus.fixedSafetyStockQuanityTarget.value;

                return stimulus.formatNumberAsPerUom(fixedSafetyStockInput);
              }

              const currentMonthYear = context.node.data.month_year;
              const safetyStockDuration = stimulus.parseNumber(
                stimulus.safetyStockDurationTarget.value,
              );
              const safetyStockDurationPeriod =
                stimulus.safetyStockDurationPeriodTarget.value;
              let safetyStockDurationInMonths = 0;

              switch (safetyStockDurationPeriod) {
                case "Days":
                  if ([365, 366].includes(safetyStockDuration)) {
                    safetyStockDurationInMonths = 12;
                  } else {
                    safetyStockDurationInMonths = safetyStockDuration / 30;
                  }
                  break;
                case "Years":
                  safetyStockDurationInMonths = safetyStockDuration * 12;
                  break;
                default:
                  safetyStockDurationInMonths = safetyStockDuration;
              }

              const numberOfMonths = Math.ceil(safetyStockDurationInMonths);
              const lastMonthMultiplier = safetyStockDurationInMonths % 1;
              let safetyStock = 0;
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();

              for (let index = 1; index <= numberOfMonths; index++) {
                const newDate = new Date(currentMonthYear.getTime()); // Create a copy of the date object
                newDate.setMonth(newDate.getMonth() + index); // Add one month
                const rowNodeAtNewDate = allRowNodes.find(
                  (node) =>
                    node.data.month_year.toString() === newDate.toString(),
                );
                if (rowNodeAtNewDate) {
                  const calculatedDemandAtNewDate = stimulus.parseNumber(
                    context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                      rowNodeAtNewDate,
                      "ddmrp_demand",
                    ),
                  );

                  if (index === numberOfMonths && lastMonthMultiplier > 0) {
                    safetyStock +=
                      calculatedDemandAtNewDate * lastMonthMultiplier;
                  } else {
                    safetyStock += calculatedDemandAtNewDate;
                  }
                }
              }

              return stimulus.formatNumberAsPerUom(safetyStock);
            },
          },
          CALCULATE_PLANNED_ORDER: {
            handler(args, context) {
              const demand = args[0];
              const projectedReceipts = args[1];
              const safetyStock = args[2];
              const workOrders = args[3];
              const previousMonthProjectedInventory =
                context.evaluateCustomQueryVariable(
                  "PREVIOUS_MONTH_PROJECTED_INVENTORY",
                  context.node.id,
                );
              const expiredStock = stimulus.getExpiredStockQuantity(
                context.node.data.month_year,
              );
              const expiredPo = stimulus.getExpiredPoQuantity(
                context.node.data.month_year,
              );
              let productionPlan = 0;
              let available =
                previousMonthProjectedInventory +
                projectedReceipts +
                workOrders -
                demand -
                safetyStock -
                expiredStock -
                expiredPo;

              if (available < 0) {
                productionPlan = stimulus.calculateProductionPlan(
                  Math.abs(available),
                  context.node.data.month_year,
                );
              }
              return stimulus.formatNumberAsPerUom(productionPlan);
            },
          },
          CALCULATE_CAMPAIGN_PLANNED_ORDER: {
            handler(args, context) {
              if (stimulus.campaignStartDateTarget.value) {
                return stimulus.getValueAsPerCampaign(
                  context.node.data.month_year,
                );
              } else {
                const planned_orders =
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    context.node,
                    "planned_orders",
                  );
                return stimulus.formatNumberAsPerUom(
                  parseFloat(planned_orders.replace(/,/g, "")),
                );
              }
            },
          },
          CALCULATE_CAMPAIGN_PRODUCTION_PLAN: {
            handler(args, context) {
              let month_year = args[0];
              let campaign_planned_orders = stimulus.parseNumber(
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  context.node,
                  "campaign_planned_orders",
                ),
              );
              let projected_receipts = args[1];
              let work_orders = args[2];
              return stimulus.formatNumberAsPerUom(
                campaign_planned_orders + projected_receipts + work_orders,
              );
            },
          },
          CALCULATE_CUMULATED_PRODUCTION_PLAN: {
            handler(args, context) {
              const month_year = args[0];
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const rowNodesToSum = allRowNodes.filter(
                (rowNode) =>
                  rowNode.data.month_year.getFullYear() ===
                    month_year.getFullYear() &&
                  rowNode.data.month_year.getMonth() <= month_year.getMonth(),
              );
              const cumulatedProductionPlan = rowNodesToSum.reduce(
                (accumulator, currentNode) => {
                  return (
                    accumulator +
                    stimulus.parseNumber(
                      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                        currentNode,
                        "planned_orders",
                      ),
                    ) +
                    stimulus.parseNumber(
                      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                        currentNode,
                        "projected_receipts",
                      ),
                    ) +
                    stimulus.parseNumber(
                      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                        currentNode,
                        "work_orders",
                      ),
                    )
                  );
                },
                0,
              );

              return stimulus.formatNumberAsPerUom(cumulatedProductionPlan);
            },
          },
          CALCULATE_CAMPAIGN_CUMULATED_PRODUCTION_PLAN: {
            handler(args, context) {
              const month_year = args[0];
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const rowNodesToSum = allRowNodes.filter(
                (rowNode) =>
                  rowNode.data.month_year.getFullYear() ===
                    month_year.getFullYear() &&
                  rowNode.data.month_year.getMonth() <= month_year.getMonth(),
              );
              const cumulatedProductionPlan = rowNodesToSum.reduce(
                (accumulator, currentNode) => {
                  return (
                    accumulator +
                    stimulus.parseNumber(
                      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                        currentNode,
                        "campaign_planned_orders",
                      ),
                    ) +
                    stimulus.parseNumber(
                      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                        currentNode,
                        "projected_receipts",
                      ),
                    ) +
                    stimulus.parseNumber(
                      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                        currentNode,
                        "work_orders",
                      ),
                    )
                  );
                },
                0,
              );

              return stimulus.formatNumberAsPerUom(cumulatedProductionPlan);
            },
          },
          CALCULATE_CAPACITY: {
            handler(args, context) {
              const month_year = args[0];
              const year = month_year.getFullYear();
              let capacity = stimulus.getTotalCapacityForYear(year);
              return capacity;
            },
          },
          CALCULATE_PURCHASE_ORDER: {
            handler(args, context) {
              const month_year = args[0];
              let po = 0;
              let past_po = 0;
              stimulus.purchaseOrderTargets.forEach((purchaseOrderTarget) => {
                if (
                  purchaseOrderTarget.querySelector(`.form-check-input`).checked
                ) {
                  let due_date_input =
                    purchaseOrderTarget.querySelector(".po_due_date");
                  let due_month_year = moment(
                    due_date_input.value,
                    "YYYY-MM-DD",
                  ).toDate();
                  if (
                    due_month_year.getMonth() == month_year.getMonth() &&
                    due_month_year.getFullYear() == month_year.getFullYear()
                  ) {
                    po += parseFloat(
                      purchaseOrderTarget.querySelector(".po_quantity").value,
                    );
                  }

                  const allRowNodes =
                    context.adaptableApi.gridApi.getAllRowNodes();
                  const firstRow = allRowNodes[0];
                  if (month_year === firstRow.data.month_year) {
                    const currentDate = firstRow.data.month_year;
                    const isPastMonth =
                      due_month_year.getFullYear() <
                        currentDate.getFullYear() ||
                      (due_month_year.getFullYear() ===
                        currentDate.getFullYear() &&
                        due_month_year.getMonth() < currentDate.getMonth());

                    if (isPastMonth) {
                      past_po += parseFloat(
                        purchaseOrderTarget.querySelector(".po_quantity").value,
                      );
                    }
                  }
                }
              });
              return stimulus.formatNumberAsPerUom(po + past_po);
            },
          },
          CALCULATE_WORK_ORDER: {
            handler(args, context) {
              const month_year = args[0];
              let wo = 0;
              let past_wo = 0;
              stimulus.workOrderTargets.forEach((workOrderTarget) => {
                if (
                  workOrderTarget.querySelector(`.form-check-input`).checked
                ) {
                  let due_date_input =
                    workOrderTarget.querySelector(".wo_due_date");
                  let due_month_year = moment(
                    due_date_input.value,
                    "YYYY-MM-DD",
                  ).toDate();
                  if (
                    due_month_year.getMonth() == month_year.getMonth() &&
                    due_month_year.getFullYear() == month_year.getFullYear()
                  ) {
                    wo += parseFloat(
                      workOrderTarget.querySelector(".wo_quantity").value,
                    );
                  }

                  const allRowNodes =
                    context.adaptableApi.gridApi.getAllRowNodes();
                  const firstRow = allRowNodes[0];
                  if (month_year === firstRow.data.month_year) {
                    const currentDate = firstRow.data.month_year;
                    const isPastMonth =
                      due_month_year.getFullYear() <
                        currentDate.getFullYear() ||
                      (due_month_year.getFullYear() ===
                        currentDate.getFullYear() &&
                        due_month_year.getMonth() < currentDate.getMonth());

                    if (isPastMonth) {
                      past_wo += parseFloat(
                        workOrderTarget.querySelector(".wo_quantity").value,
                      );
                    }
                  }
                }
              });
              return stimulus.formatNumberAsPerUom(wo + past_wo);
            },
          },
          CALCULATE_QUARTERLY_AVAILABLE: {
            handler(args, context) {
              const quarter =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  context.node,
                  "quarter",
                );
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const currentQuarterRowNodes = allRowNodes.filter((rowNode) => {
                return (
                  quarter ===
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    rowNode,
                    "quarter",
                  )
                );
              });
              const quarterlyAvailable =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  currentQuarterRowNodes[currentQuarterRowNodes.length - 1],
                  "available",
                );

              return quarterlyAvailable;
            },
          },
          CALCULATE_YEARLY_AVAILABLE: {
            handler(args, context) {
              const year =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  context.node,
                  "year",
                );
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const currentYearRowNodes = allRowNodes.filter((rowNode) => {
                return (
                  year ===
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    rowNode,
                    "year",
                  )
                );
              });
              const yearlyAvailable =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  currentYearRowNodes[currentYearRowNodes.length - 1],
                  "available",
                );

              return yearlyAvailable;
            },
          },
          CALCULATE_QUARTERLY_PROJECTED_INVENTORY: {
            handler(args, context) {
              const quarter =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  context.node,
                  "quarter",
                );
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const currentQuarterRowNodes = allRowNodes.filter((rowNode) => {
                return (
                  quarter ===
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    rowNode,
                    "quarter",
                  )
                );
              });
              const quarterlyProjectedInventory =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  currentQuarterRowNodes[currentQuarterRowNodes.length - 1],
                  "projected_inventory",
                );

              return quarterlyProjectedInventory;
            },
          },
          CALCULATE_YEARLY_PROJECTED_INVENTORY: {
            handler(args, context) {
              const year =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  context.node,
                  "year",
                );
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const currentYearRowNodes = allRowNodes.filter((rowNode) => {
                return (
                  year ===
                  context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                    rowNode,
                    "year",
                  )
                );
              });
              const yearlyProjectedInventory =
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  currentYearRowNodes[currentYearRowNodes.length - 1],
                  "projected_inventory",
                );

              return yearlyProjectedInventory;
            },
          },
          CALCULATE_MOS: {
            handler(args, context) {
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const currentRowMonthYear = context.node.data.month_year;
              let currentRowProjectedInventory = stimulus.parseNumber(
                context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                  context.node,
                  "projected_inventory",
                ),
              );

              const selectedMosTypeTarget = stimulus.mosTypeTargets.find(
                (mosTypeTarget) => mosTypeTarget.checked,
              );
              if (selectedMosTypeTarget.value === "actual_future_demand") {
                const futureDemandRowNodes = allRowNodes.filter(
                  (rowNode) => rowNode.data.month_year > currentRowMonthYear,
                );
                if (
                  futureDemandRowNodes.length === 0 &&
                  currentRowProjectedInventory > 0
                )
                  return Infinity;

                let daysOfSupply = 0;
                for (
                  let index = 0;
                  index < futureDemandRowNodes.length;
                  index++
                ) {
                  const rowNode = futureDemandRowNodes[index];
                  let rowNodeMonthYear = new Date(
                    rowNode.data.month_year.getTime(),
                  );
                  let requirement = stimulus.parseNumber(
                    context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                      rowNode,
                      "calculated_demand",
                    ),
                  );

                  if (currentRowProjectedInventory < requirement) {
                    let previousDay = new Date(
                      rowNodeMonthYear.setDate(rowNodeMonthYear.getDate() - 1),
                    );
                    const differenceInMilliseconds =
                      previousDay - context.node.data.month_year;
                    const millisecondsPerDay = 1000 * 60 * 60 * 24;
                    daysOfSupply =
                      differenceInMilliseconds / millisecondsPerDay;
                    daysOfSupply +=
                      (currentRowProjectedInventory / requirement) *
                      stimulus.getDaysInMonth(rowNode.data.month_year);
                    break;
                  } else if (
                    rowNode ==
                    futureDemandRowNodes[futureDemandRowNodes.length - 1]
                  ) {
                    return Infinity;
                  }

                  currentRowProjectedInventory -= requirement;
                }

                let monthsOfSupply = daysOfSupply / 30;
                let roundedMonthsOfSupplyString = monthsOfSupply.toFixed(2);
                return parseFloat(roundedMonthsOfSupplyString);
              } else {
                const averageMonthValue = stimulus.monthsOfSupplyTarget.value;
                const lastRowMonthYear = moment(currentRowMonthYear)
                  .clone()
                  .add(averageMonthValue, "months")
                  .toDate();

                const futureDemandRowNodes = allRowNodes.filter(
                  (rowNode) =>
                    rowNode.data.month_year > currentRowMonthYear &&
                    rowNode.data.month_year <= lastRowMonthYear,
                );

                if (
                  futureDemandRowNodes.length === 0 &&
                  currentRowProjectedInventory > 0
                )
                  return Infinity;

                let forecast_sum = 0;
                for (
                  let index = 0;
                  index < futureDemandRowNodes.length;
                  index++
                ) {
                  const rowNode = futureDemandRowNodes[index];

                  let requirement = stimulus.parseNumber(
                    context.adaptableApi.gridApi.getDisplayValueFromRowNode(
                      rowNode,
                      "calculated_demand",
                    ),
                  );

                  forecast_sum += requirement;
                }

                let monthly_average_inventory =
                  forecast_sum / averageMonthValue;
                let roundedMonthsOfSupplyString = (
                  currentRowProjectedInventory / monthly_average_inventory
                ).toFixed(2);
                return parseFloat(roundedMonthsOfSupplyString);
              }
            },
          },
        },
      },
    };

    this.gridOptions = {
      enableCharts: true,
      enableCellChangeFlash: true,
      getChartToolbarItems: () => ["chartDownload"],
      defaultColDef: {
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { fontFamily: "Open Sans", fontWeight: "normal" },
      },
      columnDefs: [
        {
          field: "id",
          type: "abColDefNumber",
          hide: true,
        },
        {
          field: "uom_type",
          type: "abColDefString",
          hide: true,
        },
        {
          field: "month_year",
          headerName: "Period",
          type: "abColDefDate",
          valueFormatter: (params) => {
            const dateFormatted = new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "short",
            }).format(params.value);

            return dateFormatted;
          },
        },
        {
          field: "demand",
          headerName: "Base Demand",
          type: "abColDefNumber",
        },
        {
          field: "ddmrp_demand",
          headerName: "DDMRP Demand",
          type: "abColDefNumber",
        },
      ],
      customChartThemes: {
        scmple: {
          baseTheme: "ag-default",
          palette: {
            fills: ["#5DE1E6", "#22316D", "#FFBF00", "#3CAA84"],
            strokes: ["#5DE1E6", "#22316D", "#FFBF00", "#3CAA84"],
          },
        },
      },
      chartThemeOverrides: {
        line: {
          series: {
            marker: {
              enabled: true,
              size: 4,
              strokeWidth: 2,
            },
          },
        },
      },
      chartThemes: ["scmple"],
      rowData: [],
      valueCache: true,
    };

    const agGridConfig = {
      modules: agGridModules,
      gridOptions: this.gridOptions,
    };

    this.adaptableApi = await Adaptable.init(adaptableOptions, agGridConfig);
    this.createCharts();
  }

  async update({ isNewScenario, scenarioData }) {
    const materialID = this.materialTarget.value;
    const supplyPlanResponse = await fetch(
      `/materials/${materialID}/supply_plan.json`,
    );
    const supplyPlanData = await supplyPlanResponse.json();
    const forecastAnalysisDashboardValuesResponse = await fetch(
      `/materials/${materialID}/forecast_analysis_dashboard_values.json`,
    );
    const forecastAnalysisDashboardValuesData =
      await forecastAnalysisDashboardValuesResponse.json();
    const mergedData = this.mergeSupplyPlanAndForecastAnalysisData(
      supplyPlanData,
      forecastAnalysisDashboardValuesData,
    );
    this.baseYieldFactor = scenarioData.base_yield;
    this.parsedData = this.parseData(mergedData);
    this.baseParseData = this.parsedData;
    this.editedDemands = scenarioData.edited_demands || {};
    this.base_planned_orders = scenarioData.base_planned_orders || {};
    this.base_purchase_orders = scenarioData.base_purchase_orders || {};
    this.purchase_orders = scenarioData.purchase_orders || [];
    this.work_orders = scenarioData.work_orders || [];
    this.dynamicallyAddedMonths = [];
    this.supplierAllocation = [];
    this.campaign_values = {};
    this.updatePurchaseOrderAsPerScenario();
    this.removeNoDemandStartingMonths();
    this.setMonthlyStockData();
    this.setMonthlyPurchaseOrderData();
    this.setMonthlyWorkOrderData();
    this.resetTimeline();
    this.adaptableApi.gridApi.resetGridData(this.parsedData);
    this.updateDemand();
    this.destroyCharts();
    this.createCharts();
    this.populateErrorData();

    this.submitButtonTarget.innerHTML = "Create";
    $("#createScenarioModal").modal("hide");
    this.scenariosLoaderTarget.classList.add("d-none");
    setTimeout(function () {
      $("#scenarioSettingModal").modal("show");
    }, 1000);

    if (isNewScenario) {
      this.saveData(true);
    }
  }

  mergeSupplyPlanAndForecastAnalysisData(
    supplyPlanData,
    forecastAnalysisDashboardValuesData,
  ) {
    return supplyPlanData.map((item) => {
      const matchingItem = forecastAnalysisDashboardValuesData.find(
        (data) => data.month_year === item.month_year,
      );
      return {
        ...item,
        ...matchingItem,
      };
    });
  }

  destroyCharts() {
    this.chartRef1.destroyChart();
    this.chartRef2.destroyChart();
    this.chartRef3.destroyChart();
  }

  updateHeaderName() {
    let demand_header =
      this.adaptableApi.calculatedColumnApi.getCalculatedColumnForColumnId(
        "calculated_demand",
      );
    demand_header.FriendlyName = `'${
      this.scenarioTarget.options[this.scenarioTarget.selectedIndex].text
    }' Demand`;
    this.adaptableApi.calculatedColumnApi.editCalculatedColumn(demand_header);
  }

  updatePurchaseOrderAsPerScenario() {
    this.purchase_orders.forEach((purchase_order) => {
      const due_date = new Date(purchase_order.due_date);
      const formatted_due_date = `${due_date.getFullYear()}-${(due_date.getMonth() + 1).toString().padStart(2, "0")}-01`;
      const matchingData = this.parsedData.find((data) => {
        const data_date = new Date(data.month_year);
        const formatted_data_date = `${data_date.getFullYear()}-${(data_date.getMonth() + 1).toString().padStart(2, "0")}-01`;
        return formatted_data_date === formatted_due_date;
      });
      if (matchingData) {
        matchingData.projected_receipts = purchase_order.open_quantity;
      }
    });
  }

  checkForMissingPORowNode() {
    const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
    const firstRow = allRowNodes[0];
    const month_year = firstRow.data.month_year;
    stimulus.purchaseOrderTargets.forEach((purchaseOrderTarget) => {
      let due_date_input = purchaseOrderTarget.querySelector(".po_due_date");
      let due_month_year = moment(due_date_input.value, "YYYY-MM-DD").toDate();
      let po_value = purchaseOrderTarget.querySelector(".po_quantity").value;
      const isPastMonth =
        due_month_year.getFullYear() < month_year.getFullYear() ||
        (due_month_year.getFullYear() === month_year.getFullYear() &&
          due_month_year.getMonth() < month_year.getMonth());
      if (!isPastMonth) {
        const due_month_year_string =
          moment(due_month_year).format("YYYY-MM-01");
        const matchingData = this.baseParseData.find(
          (data) =>
            moment(data.month_year).format("YYYY-MM-DD") ===
            moment(due_month_year_string).format("YYYY-MM-DD"),
        );
        if (matchingData) {
          matchingData.projected_receipts = po_value;
          const isExisting = this.parsedData.some(
            (data) =>
              moment(data.month_year).format("YYYY-MM-DD") ===
              moment(matchingData.month_year).format("YYYY-MM-DD"),
          );
          if (!isExisting) {
            this.dynamicallyAddedMonths.push(matchingData.month_year);
            this.parsedData.push(matchingData);
            this.parsedData.sort(
              (a, b) =>
                moment(a.month_year).toDate() - moment(b.month_year).toDate(),
            );
          }
        }
      }
    });
  }

  removeDynamicAddedMonths() {
    const date_arr = stimulus.purchaseOrderTargets.map(
      (purchaseOrderTarget) => {
        let due_date_input = purchaseOrderTarget.querySelector(".po_due_date");
        let due_month_year = moment(
          due_date_input.value,
          "YYYY-MM-DD",
        ).toDate();
        return moment(due_month_year).format("YYYY-MM-01");
      },
    );

    const dynamicallyAddedMonthStrings = this.dynamicallyAddedMonths.map(
      (date) => moment(date).format("YYYY-MM-01"),
    );
    const monthsToRemove = dynamicallyAddedMonthStrings.filter((month) => {
      const demand = this.editedDemands[moment(month).toDate()];

      if (demand !== undefined) {
        return (
          !date_arr.includes(month) &&
          this.editedDemands[moment(month).toDate()] &&
          demand === "0"
        );
      } else {
        return !date_arr.includes(month);
      }
    });

    monthsToRemove.forEach((monthToRemove) => {
      const index = this.parsedData.findIndex(
        (data) =>
          moment(data.month_year).format("YYYY-MM-01") === monthToRemove,
      );
      if (index !== -1) {
        this.parsedData.splice(index, 1);
      }
    });
  }

  parseData(data) {
    return data.map((item) => ({
      ...item, // Spread operator to copy properties from original object
      id: item.id,
      month_year: moment(item.month_year, "YYYY-MM-DD").toDate(),
      demand: stimulus.parseNumber(item.demand),
      ddmrp_demand: stimulus.parseNumber(item.units),
      projected_receipts: stimulus.parseNumber(item.projected_receipts),
    }));
  }

  performCalculations() {
    this.gridOptions.api.expireValueCache();
    this.checkForMissingPORowNode();
    this.removeDynamicAddedMonths();
    this.setMonthlyStockData();
    this.setMonthlyPurchaseOrderData();
    this.setMonthlyWorkOrderData();
    this.adaptableApi.gridApi.resetGridData(this.parsedData);
    this.setSellByPeriod();
    this.adaptableApi.gridApi.refreshColumn("planned_orders");
    this.adaptableApi.gridApi.refreshColumn("campaign_planned_orders");
    this.adaptableApi.gridApi.refreshColumn("calculated_demand");
    this.adaptableApi.gridApi.refreshColumn("projected_receipts");
    this.adaptableApi.gridApi.refreshColumn("work_orders");
    this.adaptableApi.gridApi.refreshColumn("production_plan");
    this.adaptableApi.gridApi.refreshColumn("campaign_production_plan");
    this.adaptableApi.gridApi.refreshColumn("quarterly_production_plan");
    this.adaptableApi.gridApi.refreshColumn("yearly_production_plan");
    this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
    this.adaptableApi.gridApi.refreshColumn(
      "campaign_cumulated_production_plan",
    );
    this.adaptableApi.gridApi.refreshColumn(
      "quarterly_cumulated_production_plan",
    );
    this.adaptableApi.gridApi.refreshColumn("yearly_cumulated_production_plan");
    this.adaptableApi.gridApi.refreshColumn("available");
    this.adaptableApi.gridApi.refreshColumn("campaign_available");
    this.adaptableApi.gridApi.refreshColumn("quarterly_available");
    this.adaptableApi.gridApi.refreshColumn("yearly_available");
    this.adaptableApi.gridApi.refreshColumn("projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("campaign_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("mos");
    this.adaptableApi.gridApi.refreshColumn("quarterly_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("yearly_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("capacity");
    this.adaptableApi.gridApi.refreshColumn("view_alerts");
    this.populateErrorData();
    this.updateChart();
  }

  updateSafetyStock() {
    this.gridOptions.api.expireValueCache();
    this.adaptableApi.gridApi.refreshColumn("safety_stock");
    this.adaptableApi.gridApi.refreshColumn("ddmrp_safety_stock");
    this.adaptableApi.gridApi.refreshColumn("quarterly_safety_stock");
    this.adaptableApi.gridApi.refreshColumn("yearly_safety_stock");
    this.adaptableApi.gridApi.refreshColumn("planned_orders");
    this.adaptableApi.gridApi.refreshColumn("campaign_planned_orders");
    this.adaptableApi.gridApi.refreshColumn("production_plan");
    this.adaptableApi.gridApi.refreshColumn("campaign_production_plan");
    this.adaptableApi.gridApi.refreshColumn("quarterly_production_plan");
    this.adaptableApi.gridApi.refreshColumn("yearly_production_plan");
    this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
    this.adaptableApi.gridApi.refreshColumn(
      "campaign_cumulated_production_plan",
    );
    this.adaptableApi.gridApi.refreshColumn("available");
    this.adaptableApi.gridApi.refreshColumn("campaign_available");
    this.adaptableApi.gridApi.refreshColumn("quarterly_available");
    this.adaptableApi.gridApi.refreshColumn("yearly_available");
    this.adaptableApi.gridApi.refreshColumn("projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("campaign_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("mos");
    this.adaptableApi.gridApi.refreshColumn("quarterly_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("yearly_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("capacity");
    this.adaptableApi.gridApi.refreshColumn("view_alerts");
    this.populateErrorData();
    this.updateChart();
  }

  updateDemand() {
    this.gridOptions.api.expireValueCache();
    this.adaptableApi.gridApi.refreshColumn("calculated_demand");
    this.adaptableApi.gridApi.refreshColumn("quarterly_calculated_demand");
    this.adaptableApi.gridApi.refreshColumn("yearly_calculated_demand");
    this.adaptableApi.gridApi.refreshColumn("safety_stock");
    this.adaptableApi.gridApi.refreshColumn("ddmrp_safety_stock");
    this.adaptableApi.gridApi.refreshColumn("quarterly_safety_stock");
    this.adaptableApi.gridApi.refreshColumn("yearly_safety_stock");
    this.adaptableApi.gridApi.refreshColumn("planned_orders");
    this.adaptableApi.gridApi.refreshColumn("campaign_planned_orders");
    this.adaptableApi.gridApi.refreshColumn("production_plan");
    this.adaptableApi.gridApi.refreshColumn("campaign_production_plan");
    this.adaptableApi.gridApi.refreshColumn("quarterly_production_plan");
    this.adaptableApi.gridApi.refreshColumn("yearly_production_plan");
    this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
    this.adaptableApi.gridApi.refreshColumn(
      "campaign_cumulated_production_plan",
    );
    this.adaptableApi.gridApi.refreshColumn("available");
    this.adaptableApi.gridApi.refreshColumn("campaign_available");
    this.adaptableApi.gridApi.refreshColumn("quarterly_available");
    this.adaptableApi.gridApi.refreshColumn("yearly_available");
    this.adaptableApi.gridApi.refreshColumn("projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("campaign_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("mos");
    this.adaptableApi.gridApi.refreshColumn("quarterly_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("yearly_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("capacity");
    this.adaptableApi.gridApi.refreshColumn("view_alerts");
    this.populateErrorData();
    this.updateChart();
    this.updateForecastEditor();
  }

  updateProductionPlan() {
    this.gridOptions.api.expireValueCache();
    this.adaptableApi.gridApi.refreshColumn("planned_orders");
    this.adaptableApi.gridApi.refreshColumn("campaign_planned_orders");
    this.adaptableApi.gridApi.refreshColumn("production_plan");
    this.adaptableApi.gridApi.refreshColumn("campaign_production_plan");
    this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
    this.adaptableApi.gridApi.refreshColumn(
      "campaign_cumulated_production_plan",
    );
    this.adaptableApi.gridApi.refreshColumn("available");
    this.adaptableApi.gridApi.refreshColumn("campaign_available");
    this.adaptableApi.gridApi.refreshColumn("projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("campaign_projected_inventory");
    this.adaptableApi.gridApi.refreshColumn("mos");
    this.adaptableApi.gridApi.refreshColumn("capacity");
    this.adaptableApi.gridApi.refreshColumn("view_alerts");
    this.populateErrorData();
    this.updateChart();
  }

  updateCapacity() {
    this.gridOptions.api.expireValueCache();
    this.adaptableApi.gridApi.refreshColumn("capacity");
    this.adaptableApi.gridApi.refreshColumn("view_alerts");
    this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
    this.adaptableApi.gridApi.refreshColumn(
      "campaign_cumulated_production_plan",
    );
    this.populateErrorData();
    this.updateChart();
  }

  createCharts(aggregationType = "Monthly") {
    switch (aggregationType) {
      case "Monthly":
        this.adaptableApi.eventApi.on("AdaptableReady", (eventInfo) => {
          this.chartRef1 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "line",
            cellRange: {
              columns: ["month_year", "demand", "calculated_demand"],
            },
            chartThemeOverrides: {
              common: {
                title: {
                  enabled: false,
                  text: "Base Demand Vs Scenario Demand",
                  fontFamily: "Open Sans",
                },
              },
              line: {
                series: {
                  highlightStyle: {
                    item: {
                      fill: "rgba(255, 255, 255, 0.3)",
                    },
                  },
                  title: {
                    enabled: false,
                  },
                  label: {
                    enabled: false,
                  },
                  tooltip: {
                    renderer: (params) => {
                      const dateFormatted = new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                      }).format(params.datum.month_year);
                      const demand = stimulus.usFormattedNumber(
                        params.datum.calculated_demand,
                      );
                      return {
                        content: `${dateFormatted} - ${demand}`,
                      };
                    },
                  },
                },
              },
            },
            chartContainer: this.chartTargets[0],
          });
          this.chartRef2 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "columnLineCombo",
            cellRange: {
              columns: [
                "month_year",
                "campaign_cumulated_production_plan",
                "capacity",
              ],
            },
            chartThemeOverrides: {
              common: {
                title: {
                  enabled: false,
                  text: "Cumulated Production Plan Vs Capacity",
                  fontFamily: "Open Sans",
                },
              },
              line: {
                series: {
                  highlightStyle: {
                    item: {
                      fill: "rgba(255, 255, 255, 0.3)",
                    },
                  },
                  title: {
                    enabled: false,
                  },
                  label: {
                    enabled: false,
                  },
                  tooltip: {
                    renderer: (params) => {
                      const dateFormatted = new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                      }).format(params.datum.month_year);
                      const capacity = stimulus.usFormattedNumber(
                        params.datum.capacity,
                      );
                      return {
                        content: `${dateFormatted} - ${capacity}`,
                      };
                    },
                  },
                },
              },
              column: {
                series: {
                  highlightStyle: {
                    item: {
                      fill: "rgba(255, 255, 255, 0.3)",
                    },
                  },
                  title: {
                    enabled: false,
                  },
                  label: {
                    enabled: false,
                  },
                  tooltip: {
                    renderer: (params) => {
                      const dateFormatted = new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                      }).format(params.datum.month_year);
                      const campaign_cumulated_production_plan =
                        stimulus.usFormattedNumber(
                          params.datum.campaign_cumulated_production_plan,
                        );
                      return {
                        content: `${dateFormatted} - ${campaign_cumulated_production_plan}`,
                      };
                    },
                  },
                },
              },
            },
            chartContainer: this.chartTargets[1],
          });
          this.chartRef3 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "customCombo",
            cellRange: {
              columns: [
                "month_year",
                "campaign_production_plan",
                "campaign_projected_inventory",
                "safety_stock",
                "mos",
              ],
            },
            seriesChartTypes: [
              { colId: "campaign_production_plan", chartType: "groupedColumn" },
              { colId: "campaign_projected_inventory", chartType: "line" },
              { colId: "safety_stock", chartType: "line" },
              { colId: "mos", chartType: "line", secondaryAxis: true },
            ],
            chartThemeOverrides: {
              common: {
                title: {
                  enabled: false,
                  text: "Projected Inventory",
                  fontFamily: "Open Sans",
                },
              },
              line: {
                series: {
                  highlightStyle: {
                    item: {
                      fill: "rgba(255, 255, 255, 0.3)",
                    },
                  },
                  title: {
                    enabled: false,
                  },
                  label: {
                    enabled: false,
                  },
                  tooltip: {
                    renderer: function ({ datum, xKey, yKey }) {
                      return {
                        content: `${new Intl.DateTimeFormat("en-US", {
                          year: "numeric",
                          month: "short",
                        }).format(datum[xKey])} - ${stimulus.usFormattedNumber(
                          datum[yKey].toFixed(0),
                        )}`,
                        title: stimulus.humanize(yKey),
                      };
                    },
                  },
                },
              },
              column: {
                series: {
                  fill: "#B9D9FC",
                  stroke: "#B9D9FC",
                  highlightStyle: {
                    item: {
                      fill: "rgba(0, 0, 0, 0.5)",
                    },
                  },
                  title: {
                    enabled: false,
                  },
                  label: {
                    enabled: false,
                  },
                  tooltip: {
                    renderer: (params) => {
                      const dateFormatted = new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                      }).format(params.datum.month_year);
                      const campaign_production_plan =
                        stimulus.usFormattedNumber(
                          params.datum.campaign_production_plan,
                        );
                      return {
                        content: `${dateFormatted} - ${campaign_production_plan}`,
                      };
                    },
                  },
                },
              },
            },
            chartContainer: this.chartTargets[2],
          });
        });
        break;
      case "Quarterly":
        this.adaptableApi.eventApi.on("AdaptableReady", (eventInfo) => {
          this.chartRef1 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "line",
            cellRange: {
              columns: [
                "quarter",
                "quarterly_base_demand",
                "quarterly_calculated_demand",
              ],
            },
            chartContainer: this.chartTargets[0],
          });
          this.chartRef2 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "columnLineCombo",
            cellRange: {
              columns: [
                "quarter",
                "quarterly_cumulated_production_plan",
                "capacity",
              ],
            },
            chartContainer: this.chartTargets[1],
          });
          this.chartRef3 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "area",
            cellRange: {
              columns: [
                "quarter",
                "quarterly_projected_inventory",
                "quarterly_projected_receipts",
                "quarterly_safety_stock",
              ],
            },
            chartContainer: this.chartTargets[2],
          });
        });
        break;
      case "Yearly":
        this.adaptableApi.eventApi.on("AdaptableReady", (eventInfo) => {
          this.chartRef1 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "line",
            cellRange: {
              columns: [
                "year",
                "yearly_base_demand",
                "yearly_calculated_demand",
              ],
            },
            chartContainer: this.chartTargets[0],
          });
          this.chartRef2 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "columnLineCombo",
            cellRange: {
              columns: ["year", "yearly_cumulated_production_plan", "capacity"],
            },
            chartContainer: this.chartTargets[1],
          });
          this.chartRef3 = eventInfo.gridOptions.api.createRangeChart({
            chartType: "area",
            cellRange: {
              columns: [
                "year",
                "yearly_projected_inventory",
                "yearly_projected_receipts",
                "yearly_safety_stock",
              ],
            },
            chartContainer: this.chartTargets[2],
          });
        });
        break;
    }
  }

  updateChart() {
    this.gridOptions.api.updateChart({
      chartId: this.chartRef1.chartId,
      type: "rangeChartUpdate",
    });
    this.gridOptions.api.updateChart({
      chartId: this.chartRef2.chartId,
      type: "rangeChartUpdate",
    });
    this.gridOptions.api.updateChart({
      chartId: this.chartRef3.chartId,
      type: "rangeChartUpdate",
    });
  }

  getSafetyStockType() {
    const selectedSafetyStockTypeTarget = this.safetyStockTypeTargets.find(
      (safetyStockTypeTarget) => safetyStockTypeTarget.checked,
    );
    return selectedSafetyStockTypeTarget.value;
  }

  getLotSizeType() {
    const selectedLotSizeTypeTarget = this.lotSizeTypeTargets.find(
      (lotSizeTypeTarget) => lotSizeTypeTarget.checked,
    );
    return selectedLotSizeTypeTarget.value;
  }

  getTotalCapacityForYear(year) {
    let totalCapacity = 0;
    this.supplierTargets.forEach((supplierTarget) => {
      if (supplierTarget.checked) {
        let year_element = supplierTarget
          .closest(".card")
          .querySelector(`#year-${year} input`);
        if (year_element) {
          let capacity = year_element.value;
          totalCapacity += stimulus.parseNumber(capacity);
        }
      }
    });
    this.blankCapacityContainerTargets.forEach(
      (blankCapacityContainerTarget) => {
        let year_element = blankCapacityContainerTarget.querySelector(
          `#capacity-year-${year}`,
        );
        if (year_element) {
          let capacity = year_element.value;
          totalCapacity += stimulus.parseNumber(capacity);
        }
      },
    );
    return totalCapacity;
  }

  async saveData(newScenario = false) {
    const formData = new FormData(this.parametersFormTarget);
    const data = this.adaptableApi.gridApi.getGridData();
    const updatedData = data.map((item) => ({
      ...item,
      calculated_demand: this.getCalculatedColumnValue(
        item,
        "calculated_demand",
      ),
      cumulated_production_plan: this.getCalculatedColumnValue(
        item,
        "cumulated_production_plan",
      ),
      capacity: this.getCalculatedColumnValue(item, "capacity"),
      mos: this.getCalculatedColumnValue(item, "mos"),
    }));
    if (newScenario) {
      formData.append(
        "base_planned_orders",
        JSON.stringify(this.getFormattedPlannedOrders()),
      );
      formData.append(
        "base_purchase_orders",
        JSON.stringify(this.getFormattedPurchaseOrders()),
      );
      this.base_planned_orders = this.getFormattedPlannedOrders();
      this.base_purchase_orders = this.getFormattedPurchaseOrders();
    }
    formData.append("data", JSON.stringify(updatedData));
    formData.append("edited_demands", JSON.stringify(this.editedDemands));
    const scenario_id = formData.get("scenario");
    const scenario_name = formData.get("name");

    const request = new FetchRequest(
      "put",
      `/materials/${this.materialTarget.value}/scenarios/${this.scenarioTarget.value}`,
      {
        body: formData,
      },
    );
    const response = await request.perform();
    this.updateScenarioNameInList(scenario_id, scenario_name);

    return response;
  }

  async saveSettings(e) {
    e.preventDefault();
    const response = await this.saveData();
    if (response.ok) {
      $.notify(
        {
          message: "Data Saved Successfully",
        },
        {
          type: "success",
          placement: {
            align: "center",
          },
        },
      );
      $("#scenarioSettingModal").modal("hide");
      $("#settingActionModal").modal("hide");
      return;
    }
  }

  updateScenarioNameInList(scenario_id, scenario_name) {
    this.scenarioTarget.options[this.scenarioTarget.selectedIndex].text =
      scenario_name;
    $(this.scenarioTarget).select2().val(scenario_id).trigger("change");
  }

  getCalculatedColumnValue(item, columnId) {
    let currentIterationRow = this.adaptableApi.gridApi.getRowNodeForPrimaryKey(
      item.id,
    );
    let currentIterationCalculatedDemand = stimulus.parseNumber(
      this.adaptableApi.gridApi.getDisplayValueFromRowNode(
        currentIterationRow,
        columnId,
      ),
    );

    return currentIterationCalculatedDemand;
  }

  calculateProductionPlan(deficitQty, monthYear, campaignLot = null) {
    let currentIterationProductionPlan = 0;

    const allocation = stimulus.supplierAllocationSum();

    if (allocation === 0) {
      this.supplierAllocation = [];
      switch (stimulus.getLotSizeType()) {
        case "fixed":
          let lotSize;
          if (campaignLot) {
            lotSize = campaignLot;
          } else {
            lotSize = stimulus.parseNumber(stimulus.fixedLotSizeTarget.value);
          }

          let fulfillmentMultiplier = Math.ceil(Math.abs(deficitQty / lotSize));
          currentIterationProductionPlan = lotSize * fulfillmentMultiplier;
          break;
        case "minimum":
          let roundingMultiplier = 0;
          let minimumLotSize;
          if (campaignLot) {
            minimumLotSize = campaignLot;
          } else {
            minimumLotSize = stimulus.parseNumber(
              stimulus.minimumLotSizeTarget.value,
            );
          }
          const roundingValue = stimulus.parseNumber(
            stimulus.lotSizeRoundingValueTarget.value,
          );
          if (deficitQty > minimumLotSize) {
            roundingMultiplier = Math.ceil(
              Math.abs(deficitQty) / roundingValue,
            );
            currentIterationProductionPlan = roundingValue * roundingMultiplier;
          } else {
            currentIterationProductionPlan = minimumLotSize;
          }
          break;
        case "exact":
          currentIterationProductionPlan = Math.abs(deficitQty);
          break;

        default:
          break;
      }
    } else {
      this.supplierAllocation = [];
      this.supplierMaterialsContainerTargets.forEach(
        (supplierMaterialsContainerTarget) => {
          const radioButtons =
            supplierMaterialsContainerTarget.querySelectorAll(
              ".supplier-lot-size-type",
            );

          let selectedLotSizeTypeTarget;
          radioButtons.forEach((radioButton) => {
            if (radioButton.checked) {
              selectedLotSizeTypeTarget = radioButton;
            }
          });

          const percentage_allocation =
            supplierMaterialsContainerTarget.querySelector(
              ".supplier-percentage-allocation",
            ).value;
          const supplierId =
            supplierMaterialsContainerTarget.dataset.supplierId;

          const entryIndex = this.supplierAllocation.findIndex((entry) => {
            return (
              entry.supplierId === supplierId && entry.monthYear === monthYear
            );
          });

          const planned_order_receipt =
            (deficitQty * percentage_allocation) / 100;

          switch (selectedLotSizeTypeTarget.value) {
            case "fixed":
              let lotSize;
              if (campaignLot) {
                lotSize = campaignLot;
              } else {
                lotSize = stimulus.parseNumber(
                  supplierMaterialsContainerTarget.querySelector(
                    ".supplier-fixed-lot-size",
                  ).value,
                );
              }

              let fulfillmentMultiplier = Math.ceil(
                Math.abs(planned_order_receipt / lotSize),
              );
              currentIterationProductionPlan += lotSize * fulfillmentMultiplier;

              if (entryIndex !== -1) {
                this.supplierAllocation[entryIndex].quantity =
                  lotSize * fulfillmentMultiplier;
              } else {
                this.supplierAllocation.push({
                  supplierId: supplierId,
                  monthYear: monthYear,
                  quantity: lotSize * fulfillmentMultiplier,
                });
              }
              break;
            case "minimum":
              let roundingMultiplier = 0;

              let minimumLotSize;
              if (campaignLot) {
                minimumLotSize = campaignLot;
              } else {
                minimumLotSize = stimulus.parseNumber(
                  supplierMaterialsContainerTarget.querySelector(
                    ".supplier-minimum-lot-size",
                  ).value,
                );
              }
              const roundingValue = stimulus.parseNumber(
                supplierMaterialsContainerTarget.querySelector(
                  ".supplier-rounding-value",
                ).value,
              );
              if (planned_order_receipt > minimumLotSize) {
                roundingMultiplier = Math.ceil(
                  Math.abs(planned_order_receipt) / roundingValue,
                );
                currentIterationProductionPlan +=
                  roundingValue * roundingMultiplier;
                if (entryIndex !== -1) {
                  this.supplierAllocation[entryIndex].quantity =
                    roundingValue * roundingMultiplier;
                } else {
                  this.supplierAllocation.push({
                    supplierId: supplierId,
                    monthYear: monthYear,
                    quantity: roundingValue * roundingMultiplier,
                  });
                }
              } else {
                currentIterationProductionPlan += minimumLotSize;
                if (entryIndex !== -1) {
                  this.supplierAllocation[entryIndex].quantity = minimumLotSize;
                } else {
                  this.supplierAllocation.push({
                    supplierId: supplierId,
                    monthYear: monthYear,
                    quantity: minimumLotSize,
                  });
                }
              }
              break;
            case "exact":
              currentIterationProductionPlan = Math.abs(planned_order_receipt);
              if (entryIndex !== -1) {
                this.supplierAllocation[entryIndex].quantity =
                  planned_order_receipt;
              } else {
                this.supplierAllocation.push({
                  supplierId: supplierId,
                  monthYear: monthYear,
                  quantity: planned_order_receipt,
                });
              }
              break;

            default:
              break;
          }
        },
      );
    }

    return currentIterationProductionPlan;
  }

  parseNumber(numberString) {
    if (numberString === null || numberString === undefined) {
      return 0;
    }

    let number;
    if (typeof numberString === "number") {
      number = numberString;
    } else {
      number = parseFloat(numberString.replace(/,/g, ""));
    }
    return parseFloat(number.toFixed(2));
  }

  showAlertBox(button, context) {
    let err_arr = [];
    let err_hash = {};
    let cumu_produc_plan =
      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
        context.rowNode,
        "cumulated_production_plan",
      );
    let capacity = context.adaptableApi.gridApi.getDisplayValueFromRowNode(
      context.rowNode,
      "capacity",
    );
    let expired_lots = stimulus.getExpiredLot(context.rowNode.data.month_year);
    if (expired_lots && expired_lots.length > 0) {
      err_hash["expired_lot"] = expired_lots;
      err_arr.push(err_hash);
    }
    let expired_pos = stimulus.getExpiredPo(context.rowNode.data.month_year);
    if (expired_pos && expired_pos.length > 0) {
      err_hash = {};
      err_hash["expired_po"] = expired_pos;
      err_arr.push(err_hash);
    }
    if (
      parseInt(cumu_produc_plan.replace(/,/g, ""), 10) >
      parseInt(capacity.replace(/,/g, ""), 10)
    ) {
      err_hash = {};
      err_hash["capacity"] = "Cumulated Production Plan exceeds Capacity";
      err_arr.push(err_hash);
    }
    if (stimulus.pastPOAvailable(context.rowNode.data.month_year)) {
      let past_pos = stimulus.pastPOs(context.rowNode.data.month_year);
      err_hash = {};
      err_hash["past_po"] = past_pos;
      err_arr.push(err_hash);
    }
    if (stimulus.pastWOAvailable(context.rowNode.data.month_year)) {
      let past_wos = stimulus.pastWOs(context.rowNode.data.month_year);
      err_hash = {};
      err_hash["past_wo"] = past_wos;
      err_arr.push(err_hash);
    }
    if (stimulus.sellByPeriodExceedingMos(context.rowNode)) {
      err_hash = {};
      err_hash["mos"] = "MoS is greater than Sell By period";
      err_arr.push(err_hash);
    }
    const planned_order_changes = stimulus.basePlannedOrderChangedData(
      context.rowNode,
    );
    if (planned_order_changes.length > 0) {
      err_hash = {};
      err_hash["planned_orders_changed"] = planned_order_changes;
      err_arr.push(err_hash);
    }
    const seller_allocation = stimulus.plannedOrderAllocated(context.rowNode);
    if (seller_allocation) {
      const allocation = stimulus.supplierAllocation.filter(
        (data) => data.monthYear === context.rowNode.data.month_year,
      );
      err_hash = {};
      err_hash["seller_allocation"] = allocation;
      err_arr.push(err_hash);
    }
    stimulus.openErrorModal(err_arr, context.rowNode.data.month_year);
  }

  openErrorModal(error_msg_arr, month_year) {
    this.scenarioErrorModalContainerTarget.innerHTML = "";
    document.querySelector("#scenarioErrorModalLabel").textContent =
      `${month_year.toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      })} - Alerts`;

    error_msg_arr.forEach((err_msg) => {
      if (typeof err_msg === "string") {
        let content = `<li class="mb-5"> ${err_msg} </li>`;
        this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
          "beforeend",
          content,
        );
      } else if (typeof err_msg === "object") {
        for (const key in err_msg) {
          if (Array.isArray(err_msg[key])) {
            if (key === "past_po") {
              let content = `<li class="mb-5">Following Past Purchase Orders were cumulated in the first month:<ol>`;
              err_msg[key].forEach((errObj) => {
                let name = errObj.name;
                let quantity = errObj.quantity;
                let due_date = errObj.due_date.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                });
                content += `<li> ${name}</li> Quantity: ${quantity} <br>  Due Date: ${due_date}</li>`;
              });
              content += `</ol></li>`;
              this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
                "beforeend",
                content,
              );
            } else if (key === "past_wo") {
              let content = `<li class="mb-5">Following Past Work Orders were cumulated in the first month:<ol>`;
              err_msg[key].forEach((errObj) => {
                let name = errObj.name;
                let quantity = errObj.quantity;
                let due_date = errObj.due_date.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                });
                content += `<li> ${name}</li> Quantity: ${quantity} <br>  Due Date: ${due_date}</li>`;
              });
              content += `</ol></li>`;
              this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
                "beforeend",
                content,
              );
            } else if (key === "expired_po") {
              let content = `<li class="mb-5">Purchase Orders listed below are expired:<ol>`;
              err_msg[key].forEach((errObj) => {
                let po_number = errObj.poNumber;
                let quantity = errObj.quantity;
                let due_date = errObj.expirationDate.toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    year: "numeric",
                  },
                );
                content += `<li> ${po_number}</li> Quantity: ${quantity} <br>  Exp. Date: ${due_date}</li>`;
              });
              content += `</ol></li>`;
              this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
                "beforeend",
                content,
              );
            } else if (key === "seller_allocation") {
              let content = `<li class="mb-5">Production plan supplier allocation:<ol>`;
              err_msg[key].forEach((errObj) => {
                let supplier = errObj.supplierId;
                let quantity = errObj.quantity;
                content += `<li>Supplier ID: ${supplier}</li> Quantity: ${quantity}</li>`;
              });
              content += `</ol></li>`;
              this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
                "beforeend",
                content,
              );
            } else if (key === "planned_orders_changed") {
              let content = `<li class="mb-5">Base Planned Order Changed:<ul>`;
              err_msg[key].forEach((errObj) => {
                let base_value = errObj.base_planned_order;
                let updated_value = errObj.updated_planned_order;
                content += `<li> Base Value: ${base_value}</li>  <li>Updated Value: ${updated_value}</li>`;
              });
              content += `</ul></li>`;
              this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
                "beforeend",
                content,
              );
            } else {
              err_msg[key].forEach((errObj) => {
                let lotNumber = errObj.lotNumber;
                let quantity = errObj.quantity;
                let expirationDate = errObj.expirationDate.toLocaleDateString(
                  "en-US",
                  { month: "long", year: "numeric" },
                );
                let content = `<li class="mb-5">Lots listed below are non-usable due to insufficient shelf-life<ul><li> Lot Number: ${lotNumber} </li> <li>Exp. Quantity: ${quantity} </li> <li> Exp. Date: ${expirationDate}</li></ul></li>`;
                this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
                  "beforeend",
                  content,
                );
              });
            }
          } else if (typeof err_msg[key] === "string") {
            let content = `<li class="mb-2"> ${err_msg[key]} </li>`;
            this.scenarioErrorModalContainerTarget.insertAdjacentHTML(
              "beforeend",
              content,
            );
          }
        }
      }
    });
    $("#scenarioErrorModal").modal("show");
  }

  populateErrors() {
    this.errorBoxContainerTarget.innerHTML = "";
    let errors = this.bellIconTarget.dataset.errors;
    errors = JSON.parse(errors);

    errors.sort((a, b) => {
      let monthYearA = Object.keys(a)[0];
      let monthYearB = Object.keys(b)[0];
      return new Date(monthYearA) - new Date(monthYearB);
    });

    errors.forEach((error) => {
      let month_year = Object.keys(error)[0];
      let errorMessages = error[month_year];

      let row = document.createElement("tr");
      row.classList.add("tr-outline");

      let monthYearCell = document.createElement("td");
      monthYearCell.classList.add("text-center");
      monthYearCell.classList.add("align-middle");
      monthYearCell.innerHTML = `<b>${month_year}</b>`;
      row.appendChild(monthYearCell);

      let errorMessagesCell = document.createElement("td");
      let errorContent = "";

      if (typeof errorMessages === "object") {
        if (errorMessages.hasOwnProperty("expired_lot")) {
          errorMessages.expired_lot.forEach((lot) => {
            errorContent += `<li class="">Lots listed below are non-usable due to insufficient shelf-life<ul>`;
            errorContent += `<li> Lot Number: ${
              lot.lotNumber
            } </li> <li>Exp. Quantity: ${
              lot.quantity
            } </li> <li> Exp. Date: ${new Date(
              lot.expirationDate,
            ).toLocaleDateString("en-US", {
              month: "long",
              year: "numeric",
            })}</li>`;
            errorContent += `</ul></li>`;
          });
        }
        if (errorMessages.hasOwnProperty("expired_po")) {
          errorContent += `<li class="">Purchase Orders listed below are expired:<ol>`;
          errorMessages.expired_po.forEach((ep) => {
            errorContent += `<li>PO Number: ${ep.poNumber}</li> Quantity: ${ep.quantity}</li>`;
          });
          errorContent += `</ol></li>`;
        }
        if (errorMessages.hasOwnProperty("seller_allocation")) {
          errorContent += `<li class="">Production plan supplier allocation:<ol>`;
          errorMessages.seller_allocation.forEach((sa) => {
            errorContent += `<li>Supplier ID: ${sa.supplierId}</li> Quantity: ${sa.quantity}</li>`;
          });
          errorContent += `</ol></li>`;
        }
        if (errorMessages.hasOwnProperty("capacity")) {
          errorContent += `<li>${errorMessages.capacity}</li>`;
        }
        if (errorMessages.hasOwnProperty("past_po")) {
          errorContent += `<li>${errorMessages.past_po}</li>`;
        }
        if (errorMessages.hasOwnProperty("past_wo")) {
          errorContent += `<li>${errorMessages.past_wo}</li>`;
        }
        if (errorMessages.hasOwnProperty("mos")) {
          errorContent += `<li>${errorMessages.mos}</li>`;
        }
        if (errorMessages.hasOwnProperty("planned_orders_changed")) {
          errorContent += `<li class="">Base Planned Order Changed:<ul>`;
          errorMessages.planned_orders_changed.forEach((lot) => {
            errorContent += `<li> Base value: ${lot.base_planned_order} </li> <li> Updated value: ${lot.updated_planned_order} </li>`;
            errorContent += `</ul></li>`;
          });
        }
      } else if (typeof errorMessages === "string") {
        errorContent = `<li class="">${errorMessages}</li>`;
      }

      errorMessagesCell.innerHTML = `<ul>${errorContent}</ul>`;
      row.appendChild(errorMessagesCell);

      this.errorBoxContainerTarget.appendChild(row);
    });
    $("#errorNotificationModal").modal("show");
  }

  populateErrorData() {
    const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
    let err_arr = [];
    let err_count = 0;
    allRowNodes.forEach((rowNode) => {
      let err_hash = {};
      let formatted_month_year = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
      }).format(rowNode.data.month_year);
      let cumu_produc_plan =
        stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
          rowNode,
          "cumulated_production_plan",
        );
      let capacity = stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
        rowNode,
        "capacity",
      );
      if (
        parseInt(cumu_produc_plan.replace(/,/g, ""), 10) >
        parseInt(capacity.replace(/,/g, ""), 10)
      ) {
        err_hash[formatted_month_year] = {};
        err_hash[formatted_month_year]["capacity"] =
          "Cumulated Production Plan exceeds Capacity";
        err_count += 1;
      }
      let expired_lots = stimulus.getExpiredLot(rowNode.data.month_year);
      if (expired_lots && expired_lots.length > 0) {
        if (!err_hash.hasOwnProperty(formatted_month_year)) {
          err_hash[formatted_month_year] = {};
        }
        err_hash[formatted_month_year]["expired_lot"] = expired_lots;
        err_count += 1;
      }
      let expired_pos = stimulus.getExpiredPo(rowNode.data.month_year);
      if (expired_pos && expired_pos.length > 0) {
        if (!err_hash.hasOwnProperty(formatted_month_year)) {
          err_hash[formatted_month_year] = {};
        }
        err_hash[formatted_month_year]["expired_po"] = expired_pos;
        err_count += 1;
      }
      if (stimulus.pastPOAvailable(rowNode.data.month_year)) {
        if (!err_hash.hasOwnProperty(formatted_month_year)) {
          err_hash[formatted_month_year] = {};
        }
        err_hash[formatted_month_year]["past_po"] =
          "Past Purchase Orders are cumulated in the first month";
        err_count += 1;
      }
      if (stimulus.pastWOAvailable(rowNode.data.month_year)) {
        if (!err_hash.hasOwnProperty(formatted_month_year)) {
          err_hash[formatted_month_year] = {};
        }
        err_hash[formatted_month_year]["past_wo"] =
          "Past Work Orders are cumulated in the first month";
        err_count += 1;
      }
      if (stimulus.sellByPeriodExceedingMos(rowNode)) {
        if (!err_hash.hasOwnProperty(formatted_month_year)) {
          err_hash[formatted_month_year] = {};
        }
        err_hash[formatted_month_year]["mos"] =
          "MoS is greater than Sell By Period";
        err_count += 1;
      }
      let base_planned_order_changes =
        stimulus.basePlannedOrderChangedData(rowNode);
      if (base_planned_order_changes && base_planned_order_changes.length > 0) {
        if (!err_hash.hasOwnProperty(formatted_month_year)) {
          err_hash[formatted_month_year] = {};
        }
        err_hash[formatted_month_year]["planned_orders_changed"] =
          base_planned_order_changes;
        err_count += 1;
      }
      const seller_allocation = stimulus.plannedOrderAllocated(rowNode);
      if (seller_allocation) {
        if (!err_hash.hasOwnProperty(formatted_month_year)) {
          err_hash[formatted_month_year] = {};
        }
        const allocation = stimulus.supplierAllocation.filter(
          (data) => data.monthYear === rowNode.data.month_year,
        );
        err_hash[formatted_month_year]["seller_allocation"] = allocation;
        err_count += 1;
      }
      if (err_hash && Object.keys(err_hash).length > 0) {
        err_arr.push(err_hash);
      }
    });
    this.bellIconTarget.dataset.errors = JSON.stringify(err_arr);
    if (err_count > 0) {
      this.errorCountSpanTarget.innerText = err_count.toString();
      this.errorCountSpanTarget.classList.remove("d-none");
    } else {
      this.errorCountSpanTarget.classList.add("d-none");
    }
  }

  alertButtonToHide(context) {
    let cumu_produc_plan =
      context.adaptableApi.gridApi.getDisplayValueFromRowNode(
        context.rowNode,
        "cumulated_production_plan",
      );
    let capacity = context.adaptableApi.gridApi.getDisplayValueFromRowNode(
      context.rowNode,
      "capacity",
    );
    let month_year = context.rowNode.data.month_year;
    let expired_lots = stimulus.getExpiredStockQuantity(month_year);
    let expired_pos = stimulus.getExpiredPoQuantity(month_year);
    const planned_order_changes = stimulus.basePlannedOrderChangedData(
      context.rowNode,
    );
    const seller_allocation = stimulus.plannedOrderAllocated(context.rowNode);
    if (
      parseInt(cumu_produc_plan.replace(/,/g, ""), 10) >
        parseInt(capacity.replace(/,/g, ""), 10) ||
      expired_lots > 0 ||
      expired_pos > 0 ||
      stimulus.pastPOAvailable(month_year) ||
      stimulus.pastWOAvailable(month_year) ||
      stimulus.sellByPeriodExceedingMos(context.rowNode) ||
      planned_order_changes.length > 0 ||
      seller_allocation
    ) {
      return false;
    } else {
      return true;
    }
  }

  setMonthlyStockData() {
    this.monthlyStockData = [];
    this.monthlyStockData = this.parsedData.map((item) => ({
      monthYear: item.month_year,
      stockLots: stimulus.getStockLotsObject(),
      demandDeducted: false,
    }));
  }

  setMonthlyPurchaseOrderData() {
    this.monthlyPoData = [];
    this.monthlyPoData = this.parsedData.map((item) => ({
      monthYear: item.month_year,
      purchaseOrders: stimulus.getPurchaseOrdersObject(),
      demandDeducted: false,
    }));
  }

  setMonthlyWorkOrderData() {
    this.monthlyWoData = [];
    this.monthlyWoData = this.parsedData.map((item) => ({
      monthYear: item.month_year,
      workOrders: stimulus.getWorkOrdersObject(),
      demandDeducted: false,
    }));
  }

  setSellByPeriod() {
    this.sellByPeriodTarget.value = this.getSellByPeriod();
  }

  getTotalStock() {
    let totalStock = 0;
    this.stockTargets.forEach((stockTarget) => {
      const lotNumber = stockTarget.querySelector(".stock_lot_number").value;
      if (stockTarget.querySelector(`.form-check-input`).checked) {
        totalStock += stimulus.parseNumber(
          stockTarget.querySelector(".stock_quantity").value,
        );
      }
    });
    return totalStock;
  }

  getStockLotsObject() {
    let stockLots = [];
    const allRowNodes = this.adaptableApi.gridApi.getAllRowNodes();
    const firstRow = allRowNodes[0];

    let remainingShelfLife = Number(this.remainingShelfLifeTarget.value);
    this.stockTargets.forEach((stockTarget) => {
      let stockLot = {};
      const lotNumber = stockTarget.querySelector(".stock_lot_number").value;
      if (stockTarget.querySelector(`.form-check-input`).checked) {
        const quantity = stimulus.parseNumber(
          stockTarget.querySelector(".stock_quantity").value,
        );
        const expirationDateString = stockTarget.querySelector(
          ".stock_expiration_date",
        ).value;
        let expirationDate = new Date(expirationDateString);

        if (
          firstRow !== undefined &&
          expirationDate < firstRow.data.month_year
        ) {
          expirationDate = firstRow.data.month_year;
        } else {
          expirationDate.setDate(expirationDate.getDate() - remainingShelfLife);
        }
        stockLot["lotNumber"] = lotNumber;
        stockLot["quantity"] = quantity;
        stockLot["expirationDate"] = expirationDate;
        stockLots.push(stockLot);
      }
    });
    stockLots.sort(function (a, b) {
      return a.expirationDate.valueOf() - b.expirationDate.valueOf();
    });
    return stockLots;
  }

  updateStockLots(monthYear, demand) {
    let previousMonthStockData = this.monthlyStockData.find((data) => {
      let previousMonth = new Date(monthYear.getTime());
      previousMonth.setMonth(previousMonth.getMonth() - 1);
      return data.monthYear.valueOf() == previousMonth.valueOf();
    });
    let currentMonthStockData = this.monthlyStockData.find(
      (data) => data.monthYear == monthYear,
    );

    if (
      currentMonthStockData !== undefined &&
      previousMonthStockData !== undefined
    ) {
      currentMonthStockData.stockLots = previousMonthStockData.stockLots;
    }

    if (
      currentMonthStockData !== undefined &&
      currentMonthStockData.demandDeducted === true
    )
      return;

    if (currentMonthStockData !== undefined) {
      currentMonthStockData.stockLots.every((stockLot) => {
        if (stockLot.quantity < 0 || stockLot.expirationDate < monthYear)
          return true;

        if (stockLot.expirationDate > monthYear) {
          stockLot.quantity -= demand;
          if (stockLot.quantity < 0) {
            demand = Math.abs(stockLot.quantity);
            return true;
          } else {
            currentMonthStockData.demandDeducted = true;
            return false;
          }
        }
      });
    }
  }

  updatePurchaseOrders(monthYear, demand) {
    let previousMonthPoData = this.monthlyPoData.find((data) => {
      let previousMonth = new Date(monthYear.getTime());
      previousMonth.setMonth(previousMonth.getMonth() - 1);
      return data.monthYear.valueOf() == previousMonth.valueOf();
    });
    let currentMonthPoData = this.monthlyPoData.find(
      (data) => data.monthYear == monthYear,
    );

    if (currentMonthPoData !== undefined && previousMonthPoData !== undefined) {
      currentMonthPoData.purchaseOrders = previousMonthPoData.purchaseOrders;
    }

    if (
      currentMonthPoData !== undefined &&
      currentMonthPoData.demandDeducted === true
    )
      return;

    if (currentMonthPoData !== undefined) {
      currentMonthPoData.purchaseOrders.every((purchaseOrder) => {
        if (
          purchaseOrder.quantity < 0 ||
          purchaseOrder.expirationDate < monthYear
        )
          return true;

        if (purchaseOrder.expirationDate > monthYear) {
          purchaseOrder.quantity -= demand;
          if (purchaseOrder.quantity < 0) {
            demand = Math.abs(purchaseOrder.quantity);
            return true;
          } else {
            currentMonthPoData.demandDeducted = true;
            return false;
          }
        }
      });
    }
  }

  getPurchaseOrdersObject() {
    let purcahseOrders = [];
    const allRowNodes = this.adaptableApi.gridApi.getAllRowNodes();
    const firstRow = allRowNodes[0];

    let remainingShelfLife = Number(this.remainingShelfLifeTarget.value);
    this.purchaseOrderTargets.forEach((purchaseOrderTarget) => {
      let purchaseOrder = {};

      let po_number = purchaseOrderTarget.querySelector(".po_number").value;
      let po_line_number =
        purchaseOrderTarget.querySelector(".po_line_number").value;
      let supplier = purchaseOrderTarget.querySelector(
        ".purchase_order_supplier",
      ).value;
      let expiry_date_input = purchaseOrderTarget.querySelector(
        ".po_expiration_date",
      );
      let quantity = purchaseOrderTarget.querySelector(".po_quantity").value;

      const poNumber = `${po_number.trim()} - ${po_line_number.trim()} - ${supplier.trim()}`;
      let expirationDate = moment(expiry_date_input.value).toDate();

      if (firstRow !== undefined && expirationDate < firstRow.data.month_year) {
        expirationDate = firstRow.data.month_year;
      } else if (
        this.parsedData &&
        expirationDate < this.parsedData[0].month_year
      ) {
        expirationDate = this.parsedData[0].month_year;
      }
      purchaseOrder["poNumber"] = poNumber;
      purchaseOrder["quantity"] = quantity;
      purchaseOrder["expirationDate"] = expirationDate;
      purcahseOrders.push(purchaseOrder);
    });
    purcahseOrders.sort(function (a, b) {
      return a.expirationDate.valueOf() - b.expirationDate.valueOf();
    });
    return purcahseOrders;
  }

  getWorkOrdersObject() {
    let workOrders = [];
    const allRowNodes = this.adaptableApi.gridApi.getAllRowNodes();
    const firstRow = allRowNodes[0];

    let remainingShelfLife = Number(this.remainingShelfLifeTarget.value);
    this.workOrderTargets.forEach((workOrderTarget) => {
      let workOrder = {};

      let wo_number = workOrderTarget.querySelector(".wo_number").value;
      let batch_number = workOrderTarget.querySelector(".batch_number").value;
      let location = workOrderTarget.querySelector(
        ".work_order_supplier",
      ).value;
      let expiry_date_input = workOrderTarget.querySelector(".wo_due_date");
      let quantity = workOrderTarget.querySelector(".wo_quantity").value;

      const woNumber = `${wo_number.trim()} - ${batch_number.trim()} - ${location.trim()}`;
      let expirationDate = moment(expiry_date_input.value).toDate();

      if (firstRow !== undefined && expirationDate < firstRow.data.month_year) {
        expirationDate = firstRow.data.month_year;
      } else if (
        this.parsedData &&
        expirationDate < this.parsedData[0].month_year
      ) {
        expirationDate = this.parsedData[0].month_year;
      }
      workOrder["woNumber"] = woNumber;
      workOrder["quantity"] = quantity;
      workOrder["expirationDate"] = expirationDate;
      workOrders.push(workOrder);
    });
    workOrders.sort(function (a, b) {
      return a.expirationDate.valueOf() - b.expirationDate.valueOf();
    });
    return workOrders;
  }

  getExpiredPoQuantity(monthYear) {
    let expiredPo = 0;
    this.monthlyPoData.forEach((data) => {
      if (data.monthYear == monthYear) {
        data.purchaseOrders.forEach((purcahseOrder) => {
          if (
            purcahseOrder.expirationDate.getMonth() == monthYear.getMonth() &&
            purcahseOrder.expirationDate.getFullYear() ==
              monthYear.getFullYear() &&
            purcahseOrder.quantity > 0
          ) {
            expiredPo += purcahseOrder.quantity;
          }
        });
      }
    });
    return expiredPo;
  }

  getExpiredPo(monthYear) {
    let expiredPos = [];
    this.monthlyPoData.forEach((data) => {
      if (data.monthYear == monthYear) {
        data.purchaseOrders.forEach((purcahseOrder) => {
          if (
            purcahseOrder.expirationDate.getMonth() == monthYear.getMonth() &&
            purcahseOrder.expirationDate.getFullYear() ==
              monthYear.getFullYear() &&
            purcahseOrder.quantity > 0
          ) {
            expiredPos.push(purcahseOrder);
          }
        });
      }
    });
    return expiredPos;
  }

  getExpiredStockQuantity(monthYear) {
    let expiredStock = 0;
    this.monthlyStockData.forEach((data) => {
      if (data.monthYear == monthYear) {
        data.stockLots.forEach((stockLot) => {
          if (
            stockLot.expirationDate.getMonth() == monthYear.getMonth() &&
            stockLot.expirationDate.getFullYear() == monthYear.getFullYear() &&
            stockLot.quantity > 0
          ) {
            expiredStock += stockLot.quantity;
          }
        });
      }
    });
    return expiredStock;
  }

  getExpiredLot(monthYear) {
    let expiredStockLot = [];
    this.monthlyStockData.forEach((data) => {
      if (data.monthYear == monthYear) {
        data.stockLots.forEach((stockLot) => {
          if (
            stockLot.expirationDate.getMonth() == monthYear.getMonth() &&
            stockLot.expirationDate.getFullYear() == monthYear.getFullYear() &&
            stockLot.quantity > 0
          ) {
            expiredStockLot.push(stockLot);
          }
        });
      }
    });
    return expiredStockLot;
  }

  updateAggregationType() {
    const aggregationType = this.aggregationTypeTarget.value;
    this.adaptableApi.layoutApi.setLayout(aggregationType);
    this.chartRef1.destroyChart();
    this.chartRef2.destroyChart();
    this.chartRef3.destroyChart();
    this.createCharts(aggregationType);
  }

  removeNoDemandStartingMonths() {
    this.parsedData = this.parsedData.filter(
      (item) => item.demand > 0 || item.projected_receipts > 0,
    );
  }

  getSellByPeriod() {
    const shelf_life_value = parseFloat(this.shelfLifeValueTarget.value);
    const shelf_life_period = this.shelfLifePeriodTarget.value;
    const remaining_shelf_life_in_days = parseFloat(
      this.remainingShelfLifeTarget.value,
    );

    let shelf_life_value_in_months;
    if (shelf_life_period === "Months") {
      shelf_life_value_in_months = shelf_life_value;
    } else if (shelf_life_period === "Years") {
      shelf_life_value_in_months = shelf_life_value * 12;
    } else if (shelf_life_period === "Weeks") {
      shelf_life_value_in_months = shelf_life_value * 4.34524;
    } else if (shelf_life_period === "Days") {
      shelf_life_value_in_months = shelf_life_value / 30.436875;
    }

    const remaining_shelf_life_in_months =
      remaining_shelf_life_in_days / 30.436875;

    const sell_by_period_in_months =
      shelf_life_value_in_months - remaining_shelf_life_in_months;
    return Math.round(sell_by_period_in_months.toFixed(2));
  }

  pastPOAvailable(date) {
    let foundPastMonth = false;
    const month_year = date;
    stimulus.purchaseOrderTargets.forEach((purchaseOrderTarget) => {
      if (purchaseOrderTarget.querySelector(`.form-check-input`).checked) {
        let due_date_input = purchaseOrderTarget.querySelector(".po_due_date");
        let due_month_year = moment(
          due_date_input.value,
          "YYYY-MM-DD",
        ).toDate();
        let due_value = purchaseOrderTarget.querySelector(".po_quantity").value;

        const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
        const firstRow = allRowNodes[0];
        if (month_year === firstRow.data.month_year) {
          const currentDate = firstRow.data.month_year;
          const isPastMonth =
            (due_value > 0 &&
              due_month_year.getFullYear() < currentDate.getFullYear()) ||
            (due_month_year.getFullYear() === currentDate.getFullYear() &&
              due_month_year.getMonth() < currentDate.getMonth());

          if (isPastMonth) {
            foundPastMonth = true;
          }
        }
      }
    });
    return foundPastMonth;
  }

  pastWOAvailable(date) {
    let foundPastMonth = false;
    const month_year = date;
    stimulus.workOrderTargets.forEach((workOrderTarget) => {
      if (workOrderTarget.querySelector(`.form-check-input`).checked) {
        let due_date_input = workOrderTarget.querySelector(".wo_due_date");
        let due_month_year = moment(
          due_date_input.value,
          "YYYY-MM-DD",
        ).toDate();
        let due_value = workOrderTarget.querySelector(".wo_quantity").value;

        const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
        const firstRow = allRowNodes[0];
        if (month_year === firstRow.data.month_year) {
          const currentDate = firstRow.data.month_year;
          const isPastMonth =
            (due_value > 0 &&
              due_month_year.getFullYear() < currentDate.getFullYear()) ||
            (due_month_year.getFullYear() === currentDate.getFullYear() &&
              due_month_year.getMonth() < currentDate.getMonth());

          if (isPastMonth) {
            foundPastMonth = true;
          }
        }
      }
    });
    return foundPastMonth;
  }

  sellByPeriodExceedingMos(rowNode) {
    const sell_by_period_in_months = stimulus.getSellByPeriod();
    let mos = stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
      rowNode,
      "mos",
    );
    if (mos === "∞") {
      mos = Number.POSITIVE_INFINITY;
    }
    if (parseFloat(mos) > parseFloat(sell_by_period_in_months)) {
      return true;
    } else {
      return false;
    }
  }

  plannedOrderAllocated(rowNode) {
    let month_year = rowNode.data.month_year;
    if (stimulus.supplierAllocation && stimulus.supplierAllocation.length > 0) {
      const allocation = stimulus.supplierAllocation.find(
        (data) => data.monthYear === month_year,
      );
      if (allocation) {
        return true;
      } else {
        return false;
      }
    }
  }

  basePlannedOrderChangedData(rowNode) {
    let changed_planned_orders = [];
    const month_year = rowNode.data.month_year;
    const displayed_planned_order_value =
      stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
        rowNode,
        "campaign_planned_orders",
      );
    const database_planned_orders = stimulus.base_planned_orders;
    const database_planned_order_value = database_planned_orders[month_year];
    if (
      database_planned_orders &&
      database_planned_order_value !== undefined &&
      Object.keys(database_planned_orders).length !== 0 &&
      displayed_planned_order_value !== database_planned_order_value
    ) {
      changed_planned_orders.push({
        base_planned_order: database_planned_order_value,
        updated_planned_order: displayed_planned_order_value,
      });
    }
    return changed_planned_orders;
  }

  pastPOs(date) {
    let past_pos = [];
    const month_year = date;
    stimulus.purchaseOrderTargets.forEach((purchaseOrderTarget) => {
      let due_date_input = purchaseOrderTarget.querySelector(".po_due_date");
      let due_month_year = moment(due_date_input.value, "YYYY-MM-DD").toDate();
      let due_value = purchaseOrderTarget.querySelector(".po_quantity").value;
      let po_number = purchaseOrderTarget.querySelector(".po_number").value;
      let po_line_number =
        purchaseOrderTarget.querySelector(".po_line_number").value;
      let supplier = purchaseOrderTarget.querySelector(
        ".purchase_order_supplier",
      ).value;

      const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
      const firstRow = allRowNodes[0];
      if (month_year === firstRow.data.month_year) {
        const currentDate = firstRow.data.month_year;
        const isPastMonth =
          (due_value > 0 &&
            due_month_year.getFullYear() < currentDate.getFullYear()) ||
          (due_month_year.getFullYear() === currentDate.getFullYear() &&
            due_month_year.getMonth() < currentDate.getMonth());

        if (isPastMonth) {
          past_pos.push({
            name: `${po_number.trim()} - ${po_line_number.trim()} - ${supplier.trim()}`,
            due_date: due_month_year,
            quantity: due_value,
          });
        }
      }
    });
    return past_pos;
  }

  pastWOs(date) {
    let past_wos = [];
    const month_year = date;
    stimulus.workOrderTargets.forEach((workOrderTarget) => {
      let due_date_input = workOrderTarget.querySelector(".wo_due_date");
      let due_month_year = moment(due_date_input.value, "YYYY-MM-DD").toDate();
      let due_value = workOrderTarget.querySelector(".wo_quantity").value;
      let wo_number = workOrderTarget.querySelector(".wo_number").value;
      let batch_number = workOrderTarget.querySelector(".batch_number").value;
      let location = workOrderTarget.querySelector(
        ".work_order_supplier",
      ).value;

      const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
      const firstRow = allRowNodes[0];
      if (month_year === firstRow.data.month_year) {
        const currentDate = firstRow.data.month_year;
        const isPastMonth =
          (due_value > 0 &&
            due_month_year.getFullYear() < currentDate.getFullYear()) ||
          (due_month_year.getFullYear() === currentDate.getFullYear() &&
            due_month_year.getMonth() < currentDate.getMonth());

        if (isPastMonth) {
          past_wos.push({
            name: `${wo_number.trim()} - ${batch_number.trim()} - ${location.trim()}`,
            due_date: due_month_year,
            quantity: due_value,
          });
        }
      }
    });
    return past_wos;
  }

  humanize(str) {
    return str
      .replace("campaign_", "")
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/[_\s]+/g, " ")
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  usFormattedNumber(number) {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
  }

  formatNumberAsPerUom(number) {
    let uom_category =
      this.materialTarget.options[this.materialTarget.selectedIndex].dataset
        .uomCategory;
    return uom_category === "Other"
      ? Math.ceil(number)
      : stimulus.parseNumber(number);
  }

  getBarColumnFillColor() {
    return "#5DE1E6";
  }

  removeDemandRow(event) {
    event.preventDefault();
    const row = event.target.closest(".demand-variation-form-group");
    row.remove();
    this.updateDemand();
  }

  getDaysInMonth(date) {
    // Create a new Date object with the same year and month
    const year = date.getFullYear();
    const month = date.getMonth();

    // The day 0 of the next month is the last day of the current month
    return new Date(year, month + 1, 0).getDate();
  }

  updateForecastEditor() {
    const populateMaterialDetailsController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "populate-material-details",
      );
    const forecastData = this.adaptableApi.gridApi.getGridData();
    populateMaterialDetailsController.populateForecastEditTab(
      forecastData.map((item) => ({
        ...item,
        calculated_demand: this.getCalculatedColumnValue(
          item,
          "calculated_demand",
        ),
      })),
      this.editedDemands,
    );
  }

  updateMonthYear() {
    let start_date = this.timelineStartDateTarget.value;
    let end_date = this.timelineEndDateTarget.value;
    start_date = moment(start_date, "MM/DD/YYYY").format("YYYY-MM-DD");
    end_date = moment(end_date, "MM/DD/YYYY").format("YYYY-MM-DD");

    if (
      moment(start_date, "YYYY-MM-DD").isValid() &&
      moment(end_date, "YYYY-MM-DD").isValid()
    ) {
      this.adaptableApi.gridFilterApi.setGridFilterExpression(
        `([month_year] >= DATE('${start_date}') AND [month_year] <= DATE('${end_date}'))`,
      );
    } else {
      this.destroyCharts();
      this.createCharts();
    }
  }

  resetTimeline() {
    this.adaptableApi.gridFilterApi.clearGridFilter();
    const dateRangeController =
      this.application.getControllerForElementAndIdentifier(
        this.timelineDatePickerTarget,
        "date-range-picker",
      );
    dateRangeController.reset();
  }

  downloadScenario(event) {
    let params = this.getParams();
    params.processCellCallback = function (cellParams) {
      if (cellParams && cellParams.column.colId === "month_year") {
        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
        }).format(cellParams.value);
      } else return cellParams.value;
    };
    if (event.target.dataset.exportType === "excel") {
      this.gridOptions.api.exportDataAsExcel(params);
    } else {
      this.gridOptions.api.exportDataAsCsv(params);
    }
  }

  getParams() {
    return {
      allColumns: false,
      fileName: `Scenario_Planning_${this.materialTarget.options[this.materialTarget.selectedIndex].dataset.uid}_${this.scenarioTarget.options[this.scenarioTarget.selectedIndex].text}_${this.getDateRange()}_${this.getTimeStamp()}`,
    };
  }

  getDateRange() {
    let start_date = this.timelineStartDateTarget.value;
    let end_date = this.timelineEndDateTarget.value;
    start_date = moment(start_date, "MM/DD/YYYY").format("MM-DD-YYYY");
    end_date = moment(end_date, "MM/DD/YYYY").format("MM-DD-YYYY");

    const allRowNodes = this.adaptableApi.gridApi.getAllRowNodes();
    const firstRow = allRowNodes[0];
    const lastRow = allRowNodes[allRowNodes.length - 1];

    if (!moment(start_date, "MM-DD-YYYY").isValid()) {
      start_date = moment(firstRow.data.month_year, "MM-DD-YYYY").format(
        "MM-DD-YYYY",
      );
    }
    if (!moment(end_date, "MM-DD-YYYY").isValid()) {
      end_date = moment(lastRow.data.month_year, "MM-DD-YYYY").format(
        "MM-DD-YYYY",
      );
    }
    return `${start_date}_${end_date}`;
  }

  getTimeStamp() {
    const browserTimeController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "browser-time",
      );
    return browserTimeController.getFormattedTime();
  }

  updateEditedDemands(event) {
    const month =
      this.demandTableTarget.tHead.rows[0].cells[event.currentTarget.cellIndex]
        .innerText;
    const year = event.currentTarget.parentNode.cells[0].innerText;
    const monthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth();
    const monthYear = new Date(year, monthIndex);
    const demand = event.srcElement.value;
    this.editedDemands[monthYear] = demand;
    this.checkForMissingDemandRowNode(monthYear, demand);
    if (demand === "0") {
      this.removeDynamicAddedMonths();
    }
    this.adaptableApi.gridApi.resetGridData(this.parsedData);
    this.updateDemand();
  }

  checkForMissingDemandRowNode(monthYear, demand) {
    const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
    const firstRow = allRowNodes[0];
    const month_year = firstRow.data.month_year;
    let due_month_year = moment(monthYear, "YYYY-MM-DD").toDate();
    const isPastMonth =
      due_month_year.getFullYear() < month_year.getFullYear() ||
      (due_month_year.getFullYear() === month_year.getFullYear() &&
        due_month_year.getMonth() < month_year.getMonth());
    if (!isPastMonth) {
      const due_month_year_string = moment(due_month_year).format("YYYY-MM-01");
      const matchingData = this.baseParseData.find(
        (data) =>
          moment(data.month_year).format("YYYY-MM-DD") ===
          moment(due_month_year_string).format("YYYY-MM-DD"),
      );
      if (matchingData) {
        // matchingData.demand = demand;
        const populateMaterialDetailsController =
          this.application.getControllerForElementAndIdentifier(
            this.element,
            "populate-material-details",
          );
        const forecastData = this.adaptableApi.gridApi.getGridData();
        populateMaterialDetailsController.populateForecastEditTab(
          forecastData.map((item) => ({
            ...item,
            calculated_demand: this.getCalculatedColumnValue(
              item,
              "calculated_demand",
            ),
          })),
          this.editedDemands,
        );
        const isExisting = this.parsedData.some(
          (data) =>
            moment(data.month_year).format("YYYY-MM-DD") ===
            moment(matchingData.month_year).format("YYYY-MM-DD"),
        );
        if (!isExisting) {
          this.dynamicallyAddedMonths.push(matchingData.month_year);
          this.parsedData.push(matchingData);
          this.parsedData.sort(
            (a, b) =>
              moment(a.month_year).toDate() - moment(b.month_year).toDate(),
          );
        }
      }
    }
  }

  getFormattedPlannedOrders() {
    const data = this.adaptableApi.gridApi.getGridData();
    const formattedData = data.reduce((accumulator, item) => {
      const { month_year, planned_orders } = item;
      let currentRow = this.adaptableApi.gridApi.getRowNodeForPrimaryKey(
        item.id,
      );
      let currentIterationPlannedOrder =
        this.adaptableApi.gridApi.getDisplayValueFromRowNode(
          currentRow,
          "planned_orders",
        );
      accumulator[month_year] = currentIterationPlannedOrder;
      return accumulator;
    }, {});
    return formattedData;
  }

  getFormattedPurchaseOrders() {
    let formattedData = [];
    stimulus.purchaseOrderTargets.forEach((purchaseOrderTarget) => {
      let due_date_input = purchaseOrderTarget.querySelector(".po_due_date");
      let due_month_year = new Date(due_date_input.value);
      let po_value = purchaseOrderTarget.querySelector(".po_quantity").value;
      let po_number = purchaseOrderTarget.querySelector(".po_number").value;
      let po_line_number =
        purchaseOrderTarget.querySelector(".po_line_number").value;
      let supplier = purchaseOrderTarget.querySelector(
        ".purchase_order_supplier",
      ).value;

      formattedData.push({
        name: `${po_number.trim()} - ${po_line_number.trim()} - ${supplier.trim()}`,
        due_date: due_month_year,
        quantity: po_value,
      });
    });
    return formattedData;
  }

  resetPo(event) {
    const po_number = event.target.parentElement.dataset.poNumber;
    const basePurchaseOrder = this.base_purchase_orders.find(
      (po) => po.name === po_number,
    );
    if (basePurchaseOrder) {
      const formGroup = event.target.closest(".form-group");
      const qtyInputElement = formGroup.querySelector(".po_quantity");
      const dateInputElement = formGroup.querySelector(".po_due_date");

      qtyInputElement.value = basePurchaseOrder.quantity;
      dateInputElement.value = moment(basePurchaseOrder.due_date).format(
        "YYYY-MM-DD",
      );

      const qtyInputEvent = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      qtyInputElement.dispatchEvent(qtyInputEvent);

      const dateInputEvent = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      dateInputElement.dispatchEvent(dateInputEvent);
    }
  }

  resetEditedForecast() {
    this.editedDemands = {};
    this.updateDemand();
  }

  supplierAllocationSum() {
    let sum = 0;
    if (stimulus.hasSupplierPercentageAllocationTarget) {
      stimulus.supplierPercentageAllocationTargets.forEach((input) => {
        sum += parseInt(input.value, 10) || 0;
      });
    }
    return sum;
  }

  getValueAsPerCampaign(monthYear) {
    this.campaign_values = {};
    let campaign_planned_order = 0;
    let start_date = moment(
      this.campaignStartDateTarget.value,
      "YYYY-MM-DD",
    ).toDate();
    start_date.setDate(1);
    const campaignStrategyTarget = this.campaignStrategyTargets.find(
      (campaignStrategyTarget) => campaignStrategyTarget.checked,
    );
    let campaign_type = campaignStrategyTarget.value;
    const timeline_in_months = parseInt(
      this.campaignTimelineInMonthsTarget.value,
    );
    const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
    if (campaign_type === "single") {
      let end_date = moment(start_date, "YYYY-MM-DD").toDate();
      end_date.setMonth(start_date.getMonth() + timeline_in_months - 1);
      let firstNonZeroDate = null;
      let isFirstNonZeroFound = false;

      for (const rowNode of allRowNodes) {
        const planned_orders =
          stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
            rowNode,
            "planned_orders",
          );
        const parsed_planned_orders = parseFloat(
          planned_orders.replace(/,/g, ""),
        );
        const row_date = rowNode.data.month_year;
        start_date.setHours(0, 0, 0, 0);
        end_date.setHours(0, 0, 0, 0);
        row_date.setHours(0, 0, 0, 0);
        if (row_date >= start_date && row_date <= end_date) {
          campaign_planned_order += parsed_planned_orders;
          if (parsed_planned_orders > 0 && !isFirstNonZeroFound) {
            firstNonZeroDate = row_date;
            isFirstNonZeroFound = true;
          }
          this.campaign_values[row_date] = 0;
        }
      }

      this.campaign_values[firstNonZeroDate] = campaign_planned_order;
    } else {
      const duration_in_months = parseInt(
        this.campaignDurationInMonthsTarget.value,
      );
      let current_start_date = moment(start_date, "YYYY-MM-DD").toDate();
      const monthGap = Math.round(duration_in_months / timeline_in_months);
      const allRowNodes = stimulus.adaptableApi.gridApi.getAllRowNodes();
      for (let i = 0; i < monthGap; i++) {
        let current_end_date = moment(current_start_date)
          .add(timeline_in_months, "months")
          .subtract(1, "month")
          .toDate();

        let firstNonZeroDate = null;
        let isFirstNonZeroFound = false;
        let monthly_planned_order = 0;

        for (const rowNode of allRowNodes) {
          const row_date = moment(rowNode.data.month_year);
          const startOfMonth = moment(current_start_date).startOf("month");
          const endOfMonth = moment(current_end_date).endOf("month");

          if (
            row_date.isSameOrAfter(startOfMonth, "month") &&
            row_date.isSameOrBefore(endOfMonth, "month")
          ) {
            const planned_orders =
              stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
                rowNode,
                "planned_orders",
              );
            const parsed_planned_orders = parseFloat(
              planned_orders.replace(/,/g, ""),
            );

            monthly_planned_order += parsed_planned_orders;

            if (parsed_planned_orders > 0 && !isFirstNonZeroFound) {
              isFirstNonZeroFound = true;
              firstNonZeroDate = row_date.toDate();
            }

            this.campaign_values[row_date.toDate()] = 0;
          }
        }

        this.campaign_values[firstNonZeroDate] = monthly_planned_order;

        current_start_date = moment(current_end_date).add(1, "month").toDate();
      }
    }

    for (const rowNode of allRowNodes) {
      const planned_orders =
        stimulus.adaptableApi.gridApi.getDisplayValueFromRowNode(
          rowNode,
          "planned_orders",
        );
      const parsed_planned_orders = parseFloat(
        planned_orders.replace(/,/g, ""),
      );
      const row_date = rowNode.data.month_year;
      if (!this.campaign_values.hasOwnProperty(row_date)) {
        this.campaign_values[row_date] = parsed_planned_orders;
      }
    }

    if (this.campaign_values[monthYear]) {
      campaign_planned_order = this.campaign_values[monthYear];
    } else {
      campaign_planned_order = 0;
    }

    return campaign_planned_order;
  }

  resetCampaign() {
    this.campaignStartDateTarget.value = "";
    if (this.hasCampaignDurationInMonthsTarget) {
      this.campaignDurationInMonthsTarget.value = "";
      const qtyInputEvent = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      this.campaignDurationInMonthsTarget.dispatchEvent(qtyInputEvent);
    }
    this.campaignTimelineInMonthsTarget.value = "";

    const timeLineEvent = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    this.campaignTimelineInMonthsTarget.dispatchEvent(timeLineEvent);

    const dateInputEvent = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    this.campaignStartDateTarget.dispatchEvent(dateInputEvent);
  }

  importForecast(data) {
    data["rows"].forEach((row) => {
      const formattedDate = moment(row["Month Year"], "MM/YYYY").toDate();
      this.editedDemands[formattedDate] = row["Value"];
    });
    this.updateDemand();
  }

  confirmMessage(event) {
    const checkbox = event.target;
    const isChecked = checkbox.checked;

    if (!isChecked) {
      if (confirm(checkbox.dataset.confirmMessage)) {
        checkbox.removeAttribute("checked");
        this.performCalculations();
      } else {
        checkbox.checked = true;
        checkbox.setAttribute("checked", "checked");
      }
    } else {
      this.performCalculations();
    }
  }
}
