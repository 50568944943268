import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "inputGroupContainer"];

  connect() {
    if (this.hasinputGroupContainerTarget) {
      this.inputTarget.dispatchEvent(new Event("change"));
    }
  }

  updateInputGroup(event) {
    let inputSelect = event.target;
    let selectedOption = inputSelect.options[inputSelect.selectedIndex];
    this.inputGroupText.textContent = selectedOption.dataset.inputGroupValue;
  }

  get inputGroupText() {
    return this.inputGroupContainerTarget.querySelector(".input-group-text");
  }
}
