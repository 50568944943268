import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "template", "container", "persistedCountryCode"];

  connect() {
    $(this.selectTarget).on("select2:select", this.add.bind(this));
    $(this.selectTarget).on("select2:unselect", this.remove.bind(this));
  }

  remove(event) {
    let countryCode = event.params.data.id;
    let persistedCountryCodeInput = this.persistedCountryCodeTargets.find(
      (countryCodeInput) => countryCodeInput.value == countryCode
    );

    if (persistedCountryCodeInput) {
      this.removePersistedCountry(persistedCountryCodeInput);
    } else {
      this.removeNewCountry(countryCode);
    }
  }

  removePersistedCountry(countryCodeInput) {
    let destroyInput = this.destroyInput(countryCodeInput);
    destroyInput.value = 1;
  }

  removeNewCountry(countryCode) {
    let newCountryCodeInput = this.containerTarget.querySelector(
      `input[value="${countryCode}"]`
    );

    if (newCountryCodeInput) {
      let newCountryCodeDestroyInput = this.destroyInput(newCountryCodeInput);
      newCountryCodeDestroyInput.remove();
      newCountryCodeInput.remove();
    }
  }

  destroyInput(countryCodeInput) {
    let destroyInputId = countryCodeInput.id.replace(
      /country_code/,
      "_destroy"
    );
    return document.querySelector(`#${destroyInputId}`);
  }

  add(event) {
    let time = new Date().getTime();
    let content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      time
    );
    this.containerTarget.insertAdjacentHTML("beforeend", content);

    const countryCodeField = this.containerTarget.querySelector(
      `#material_material_countries_attributes_${time}_country_code`
    );
    countryCodeField.value = event.params.data.id;
  }
}
