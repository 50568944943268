import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "emailField",
    "passwordFieldTemplate",
    "submitButton",
  ];

  connect() {
    this.formTarget.addEventListener("ajax:success", (event) => {
      const [data, ..._rest] = event.detail;
      if (data.authentication_type == "okta" && data.identity_provider_id) {
        return this.samlLogin(data.identity_provider_id);
      }
      this.passwordLogin();
    });

    this.formTarget.addEventListener("ajax:error", (event) => {
      const [data, ..._rest] = event.detail;
      if (data.message) {
        $.notify(
          {
            message: data.message,
          },
          {
            type: "danger",
            placement: {
              align: "center",
            },
          }
        );
      }
    });
  }

  samlLogin(idpId) {
    const form = document.createElement("form");
    const tokenInput = document.createElement("input");
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    tokenInput.value = csrfToken;
    tokenInput.name = "authenticity_token";
    form.action = `/users/auth/saml/${idpId}`;
    form.hidden = true;
    form.method = "POST";

    form.appendChild(tokenInput);
    document.body.appendChild(form);

    form.submit();
  }

  passwordLogin() {
    if (!document.getElementById("user_password")) {
      this.formTarget.insertBefore(
        this.passwordFieldTemplateTarget.content.cloneNode(true),
        this.passwordFieldTemplateTarget
      );
      this.submitButtonTarget.innerText = "Sign in";
      this.formTarget.dataset.remote = false;
      this.formTarget.action = "/users/sign_in";
    }
  }
}
