import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "form"];

  submit(event) {
    event.preventDefault();

    if (this.hasInputTarget && this.inputTarget.files.length > 0) {
      this.blockPage();
    } else {
      this.blockPage();
    }

    this.formTarget.submit();
  }

  blockPage() {
    KTApp.blockPage({
      overlayColor: "#000000",
      type: "v2",
      state: "primary",
      message: "Processing...",
    });
  }
}
