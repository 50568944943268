import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['row', 'input', 'noResults'];

  search(event) {
    const query = event.target.value.toLowerCase();

    let matchCount = 0;
    this.rowTargets.forEach(row => {
      const inputValues = row.querySelectorAll('input[type="text"], input[type="number"],input[type="date"], select');
      let matches = 0;
      inputValues.forEach(inputValue => {
        let text = '';
        if (inputValue.tagName === 'SELECT') {
          const selectedOption = inputValue.selectedOptions[0];
          if (selectedOption) {
            text = selectedOption.textContent.toLowerCase();
          }
        } else {
          text = inputValue.value.toLowerCase();
        }
        if (text.includes(query)) {
          matches += 1;
        }
      });
      if (matches > 0) {
        row.style.display = "";
        matchCount += 1;
      } else {
        row.style.display = "none";
      }
    });

    if (matchCount === 0) {
      this.noResultsTarget.style.display = "";
    } else {
      this.noResultsTarget.style.display = "none";
    }
  }

}
