import { Controller } from "@hotwired/stimulus";
import { DateRangePicker } from "vanillajs-datepicker";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dateRangePicker = new DateRangePicker(this.inputTarget, {
      buttonClass: "btn",
      pickLevel: 1,
    });
  }

  reset() {
    this.dateRangePicker.setDates({ clear: true }, { clear: true });
  }
}
