import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "material",
    "scenarios",
    "modal",
    "form",
    "name",
    "creationTypeSelect",
    "scenarioToCloneSelectContainer",
    "scenarioToCloneSelect",
    "submitButton",
    "scenariosLoader",
  ];

  async select(e) {
    if (this.scenariosTarget.value != "new") {
      this.scenariosLoaderTarget.classList.remove("d-none");
      const scenariosResponse = await fetch(
        `/materials/${this.materialTarget.value}/scenarios/${this.scenariosTarget.value}`,
      );
      const scenarioData = await scenariosResponse.json();
      const populateMaterialDetailsController =
        this.application.getControllerForElementAndIdentifier(
          this.element,
          "populate-material-details",
        );
      populateMaterialDetailsController.populate({
        scenarioData: scenarioData,
        isNewScenario: false,
      });
    } else {
      let scenarioOptions = this.scenariosTarget.options;
      this.scenarioToCloneSelectTarget.innerHTML = "";
      for (const option of scenarioOptions) {
        if (option.value === "new") continue;
        let opt = document.createElement("option");
        opt.value = option.value;
        opt.innerHTML = option.innerHTML;
        this.scenarioToCloneSelectTarget.append(opt);
      }

      $(this.modalTarget).modal("show");
    }
  }

  toggleScenarioSelection() {
    if (this.creationTypeSelectTarget.value === "new") {
      this.scenarioToCloneSelectContainerTarget.classList.add("d-none");
      this.submitButtonTarget.disabled = false;
    } else {
      this.scenarioToCloneSelectContainerTarget.classList.remove("d-none");
      this.submitButtonTarget.disabled = true;
    }
  }

  updateSubmitButtonState() {
    if (this.scenarioToCloneSelectTarget.value != "") {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  updateFormAction() {
    const formAction = `/materials/${this.materialTarget.value}/scenarios`;
    this.formTarget.action = formAction;
  }

  async create(e) {
    e.preventDefault();

    if (this.formTarget.checkValidity()) {
      const request = new FetchRequest(
        "post",
        `/materials/${this.materialTarget.value}/scenarios`,
        {
          body: new FormData(this.formTarget),
        },
      );
      const response = await request.perform();
      if (response.ok) {
        const body = await response.text;
        const scenarioData = JSON.parse(body);
        if (scenarioData["success"]) {
          this.nameTarget.classList.remove("is-invalid");
          const data = scenarioData["data"];
          let opt = document.createElement("option");
          opt.value = data.id;
          opt.innerHTML = data.name;
          this.scenariosTarget.append(opt);
          this.scenariosTarget.value = data.id;
          let loader = `<div class="text-center">
                          <div class="spinner-border" role="status" style="width: 1.5rem; height: 1.5rem;">
                            <span class="visually-hidden"></span>
                          </div>
                        </div>`;
          this.submitButtonTarget.innerHTML = loader;

          const populateMaterialDetailsController =
            this.application.getControllerForElementAndIdentifier(
              this.element,
              "populate-material-details",
            );
          populateMaterialDetailsController.populate({
            scenarioData: data,
            isNewScenario: true,
          });
        } else {
          this.nameTarget.classList.add("is-invalid");
        }
      }
    } else {
      this.formTarget.reportValidity();
    }
  }
}
