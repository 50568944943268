import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";
import debounced from "debounced";

import Adaptable from "@adaptabletools/adaptable/agGrid";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { CsvExportModule } from "@ag-grid-community/csv-export";
import { LicenseManager } from "@ag-grid-enterprise/core";

const agGridModules = [
  ClientSideRowModelModule,
  GridChartsModule,
  ExcelExportModule,
  CsvExportModule,
];

LicenseManager.setLicenseKey(
  "[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-052646}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{29 February 2024}____[v3]_[0102]_MTcwOTE2NDgwMDAwMA==b1896dfdfbb4dc28dfd4e4366ce39bbd",
);

let stimulus = {};

export default class extends Controller {
  static targets = ["material", "scenarios", "chart", "disabledButton"];

  initialize() {
    debounced.initialize({ ...debounced.events, changeDate: { wait: 750 } });
  }

  async connect() {
    stimulus = this;
    localStorage.removeItem("Scenario Planning Comparison");

    const adaptableOptions = {
      primaryKey: "month_year",
      adaptableId: "Scenario Planning Comparison",
      licenseKey:
        "AppName=scmple|Owner=SCmple|StartDate=2024-05-16|EndDate=2025-05-16|Ref=AdaptableLicense|TS=1715858732458|C=3296523092,500258536,1260976079,2023408945,3740126853,1302728593,2345346467",
      exportOptions: {
        exportDateFormat: "MMM yyyy",
      },
      predefinedConfig: {
        Dashboard: {
          IsHidden: true,
        },
        Layout: {
          CurrentLayout: "Default",
          Layouts: [
            {
              Columns: [
                "month_year",
                "demand",
                "cumulated_production_plan",
                "capacity",
                "base_mos",
                "scenario_one_demand",
                "scenario_one_production_plan",
                "scenario_one_capacity",
                "scenario_one_mos",
                "scenario_two_demand",
                "scenario_two_production_plan",
                "scenario_two_capacity",
                "scenario_two_mos",
              ],
              Name: "Default",
              ColumnWidthMap: {
                month_year: 90,
                demand: 90,
                cumulated_production_plan: 100,
                capacity: 100,
                base_mos: 80,
                scenario_one_demand: 100,
                scenario_one_production_plan: 120,
                scenario_one_capacity: 120,
                scenario_one_mos: 100,
                scenario_two_demand: 100,
                scenario_two_production_plan: 120,
                scenario_two_capacity: 120,
                scenario_two_mos: 100,
              },
            },
          ],
        },
        FormatColumn: {
          FormatColumns: [
            {
              CellAlignment: "Right",
              Scope: {
                DataTypes: ["Number"],
              },
              DisplayFormat: {
                Formatter: "NumberFormatter",
              },
            },
          ],
        },
        CalculatedColumn: {
          CalculatedColumns: [
            {
              ColumnId: "cumulated_production_plan",
              FriendlyName: "Base Prod. Plan",
              Query: {
                ScalarExpression:
                  "CALCULATE_CUMULATED_PRODUCTION_PLAN([month_year])",
              },
              CalculatedColumnSettings: {
                DataType: "Number",
              },
            },
          ],
        },
      },
      expressionOptions: {
        customScalarFunctions: {
          CALCULATE_CUMULATED_PRODUCTION_PLAN: {
            handler(args, context) {
              const month_year = args[0];
              const allRowNodes = context.adaptableApi.gridApi.getAllRowNodes();
              const rowNodesToSum = allRowNodes.filter(
                (rowNode) =>
                  rowNode.data.month_year.getFullYear() ===
                    month_year.getFullYear() &&
                  rowNode.data.month_year.getMonth() <= month_year.getMonth(),
              );
              const cumulatedProductionPlan = rowNodesToSum.reduce(
                (accumulator, currentNode) => {
                  return (
                    accumulator +
                    parseFloat(
                      context.adaptableApi.gridApi
                        .getDisplayValueFromRowNode(
                          currentNode,
                          "production_plan",
                        )
                        .replace(/,/g, ""),
                    )
                  );
                },
                0,
              );

              return cumulatedProductionPlan;
            },
          },
        },
      },
    };

    this.gridOptions = {
      enableCharts: true,
      columnDefs: [
        {
          field: "month_year",
          headerName: "Period",
          type: "abColDefDate",
          valueFormatter: (params) => {
            const dateFormatted = new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "short",
            }).format(params.value);

            return dateFormatted;
          },
        },
        {
          field: "demand",
          headerName: "Base Demand",
          type: "abColDefNumber",
        },
        {
          field: "scenario_one_demand",
          headerName: "Scenario 1 Demand",
          type: "abColDefNumber",
        },
        {
          field: "scenario_two_demand",
          headerName: "Scenario 2 Demand",
          type: "abColDefNumber",
        },
        {
          field: "projected_receipts",
          headerName: "Projected Receipts",
          type: "abColDefNumber",
          hide: true,
        },
        {
          field: "production_plan",
          headerName: "Base Prod. Plan",
          type: "abColDefNumber",
          hide: true,
        },
        {
          field: "scenario_one_production_plan",
          headerName: "Scenario 1 Prod. Plan",
          type: "abColDefNumber",
        },
        {
          field: "scenario_two_production_plan",
          headerName: "Scenario 2 Prod. Plan",
          type: "abColDefNumber",
        },
        {
          field: "capacity",
          headerName: "Base Capacity",
          type: "abColDefNumber",
        },
        {
          field: "scenario_one_capacity",
          headerName: "Scenario 1 Capacity",
          type: "abColDefNumber",
        },
        {
          field: "scenario_two_capacity",
          headerName: "Scenario 2 Capacity",
          type: "abColDefNumber",
        },
        {
          field: "base_mos",
          headerName: "Base MoS",
          type: "abColDefNumber",
        },
        {
          field: "scenario_one_mos",
          headerName: "Scenario 1 MoS",
          type: "abColDefNumber",
        },
        {
          field: "scenario_two_mos",
          headerName: "Scenario 2 MoS",
          type: "abColDefNumber",
        },
      ],
      customChartThemes: {
        groupChart: {
          baseTheme: "ag-default",
          palette: {
            fills: [
              "#B9D9FC",
              "#FCE7BD",
              "#ACE3D0",
              "#498AD4",
              "#FFBF00",
              "#3CAA84",
            ],
            strokes: [
              "#B9D9FC",
              "#FCE7BD",
              "#ACE3D0",
              "#498AD4",
              "#FFBF00",
              "#3CAA84",
            ],
          },
        },
        lineChart: {
          baseTheme: "ag-default",
          palette: {
            fills: ["#5DE1E6", "#498AD4", "#FFBF00"],
            strokes: ["#5DE1E6", "#498AD4", "#FFBF00"],
          },
        },
      },
      chartThemes: ["lineChart", "groupChart"],
      chartThemeOverrides: {
        line: {
          series: {
            highlightStyle: {
              item: {
                fill: "rgba(255, 255, 255, 0.3)",
              },
            },
            marker: {
              enabled: true,
              size: 4,
              strokeWidth: 2,
            },
            tooltip: {
              renderer: function ({ datum, xKey, yKey }) {
                return {
                  content: `${new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "short",
                  }).format(datum[xKey])} - ${stimulus.usFormattedNumber(
                    datum[yKey].toFixed(0),
                  )}`,
                  title: stimulus.humanize(yKey),
                };
              },
            },
          },
        },
        column: {
          series: {
            highlightStyle: {
              item: {
                fill: "rgba(255, 255, 255, 0.3)",
              },
            },
            tooltip: {
              renderer: function ({ datum, xKey, yKey }) {
                return {
                  content: `${new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "short",
                  }).format(datum[xKey])} - ${stimulus.usFormattedNumber(
                    datum[yKey].toFixed(0),
                  )}`,
                  title: stimulus.humanize(yKey),
                };
              },
            },
          },
        },
      },
      rowData: [],
      defaultColDef: {
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
    };

    const agGridConfig = {
      modules: agGridModules,
      gridOptions: this.gridOptions,
    };

    this.adaptableApi = await Adaptable.init(adaptableOptions, agGridConfig);
    this.createChart();
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  createChart() {
    this.adaptableApi.eventApi.on("AdaptableReady", (eventInfo) => {
      this.chartRef1 = eventInfo.gridOptions.api.createRangeChart({
        chartType: "line",
        cellRange: {
          columns: [
            "month_year",
            "demand",
            "scenario_one_demand",
            "scenario_two_demand",
          ],
        },
        chartThemeName: "lineChart",
        chartContainer: this.chartTargets[0],
      });
      this.chartRef2 = eventInfo.gridOptions.api.createRangeChart({
        chartType: "customCombo",
        cellRange: {
          columns: [
            "month_year",
            "cumulated_production_plan",
            "scenario_one_production_plan",
            "scenario_two_production_plan",
            "capacity",
            "scenario_one_capacity",
            "scenario_two_capacity",
          ],
        },
        seriesChartTypes: [
          { colId: "cumulated_production_plan", chartType: "groupedColumn" },
          { colId: "scenario_one_production_plan", chartType: "groupedColumn" },
          { colId: "scenario_two_production_plan", chartType: "groupedColumn" },
          { colId: "capacity", chartType: "line" },
          { colId: "scenario_one_capacity", chartType: "line" },
          { colId: "scenario_two_capacity", chartType: "line" },
        ],
        chartThemeName: "groupChart",
        chartContainer: this.chartTargets[1],
      });
    });
  }

  async addBaseData() {
    this.materialID = this.materialTarget.value;
    const response = await fetch(
      `/materials/${this.materialID}/supply_plan.json`,
    );
    const responseData = await response.json();
    this.parsedData = this.parseData(responseData);
    this.parsedData = this.removeNoDemandStartingMonths(this.parsedData);
    this.adaptableApi.gridApi.resetGridData(this.parsedData);
    this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
    this.chartRef1.destroyChart();
    this.chartRef2.destroyChart();
    this.createChart();
    this.disabledButtonTargets.forEach((button) => {
      button.disabled = false;
    });
  }

  parseData(data) {
    return data.map((item) => ({
      ...item, // Spread operator to copy properties from original object
      id: item.id,
      month_year: moment(item.month_year, "YYYY-MM-DD").toDate(),
      demand: stimulus.parseNumber(item.demand),
      projected_receipts: stimulus.parseNumber(item.projected_receipts),
      production_plan: stimulus.parseNumber(item.production_plan),
      capacity: stimulus.parseNumber(item.capacity),
      base_mos: stimulus.parseNumber(item.months_of_supply),
    }));
  }

  parseNumber(numberString) {
    if (typeof numberString === "number" || numberString === null)
      return numberString;
    return parseFloat(numberString.replace(/,/g, ""));
  }

  async populateScenarios() {
    this.materialID = this.materialTarget.value;
    const scenariosResponse = await fetch(
      `/materials/${this.materialID}/scenarios.json`,
    );
    const scenarios = await scenariosResponse.json();
    this.scenariosTargets.forEach((scenariosTarget) => {
      scenariosTarget.innerHTML = "";
      let opt = document.createElement("option");
      opt.value = "";
      opt.innerHTML = "Select";
      scenariosTarget.append(opt);
      scenarios.map((scenario, i) => {
        let opt = document.createElement("option");
        opt.value = scenario.id;
        opt.innerHTML = scenario.name;
        scenariosTarget.append(opt);
      });

      $(scenariosTarget).select2({
        placeholder: "Select",
        width: "100%",
      });
      $(scenariosTarget).select2().trigger("change");
    });
  }

  async compare(event) {
    let scenarioIDs = this.scenariosTargets
      .filter((target) => target.value)
      .map((target) => target.value);
    if (scenarioIDs.length < 1) {
      stimulus.addBaseData();
      return;
    }

    this.materialID = this.materialTarget.value;
    const scenariosRequest = new FetchRequest(
      "get",
      `/materials/${this.materialID}/scenarios.json?scenario_ids=${scenarioIDs}`,
    );
    const scenariosResponse = await scenariosRequest.perform();
    if (scenariosResponse.ok) {
      const scenarios = await scenariosResponse.json;
      this.handleScenariosResponse(scenarios, event);
    }
  }

  handleScenariosResponse(scenarios, event) {
    const scenarioData = scenarios[0].data;
    const scenarioOnePrefix = "scenario_one_";
    const scenarioTwoPrefix = "scenario_two_";
    if (scenarios.length == 1) {
      const scenarioPrefix =
        event.target.id == "scenario_1" ? scenarioOnePrefix : scenarioTwoPrefix;
      const prefixToRemove =
        scenarioPrefix == scenarioOnePrefix
          ? scenarioTwoPrefix
          : scenarioOnePrefix;
      if (event.target.value) {
        this.updateData(
          scenarioData,
          this.parsedData,
          ...this.getSuffixes(scenarioPrefix),
        );
        this.updateNull(
          scenarioData,
          this.parsedData,
          ...this.getSuffixes(prefixToRemove),
        );
      } else {
        this.updateNull(
          scenarioData,
          this.parsedData,
          ...this.getSuffixes(scenarioPrefix),
        );
      }
    } else {
      if (scenarios[1]) {
        this.updateData(
          scenarioData,
          this.parsedData,
          ...this.getSuffixes(scenarioOnePrefix),
        );
        this.updateData(
          scenarios[1].data,
          this.parsedData,
          ...this.getSuffixes(scenarioTwoPrefix),
        );
      }
    }

    this.refreshGridAndCharts();
  }

  getSuffixes(prefix) {
    return ["demand", "production_plan", "capacity", "mos"].map(
      (suffix) => prefix + suffix,
    );
  }

  refreshGridAndCharts() {
    this.adaptableApi.gridApi.resetGridData(this.parsedData);
    this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
    this.chartRef1.destroyChart();
    this.chartRef2.destroyChart();
    this.createChart();
  }

  async unselect_compare() {
    const scenarioIDs = $("#scenarios").val();
    if (scenarioIDs.length < 1) {
      stimulus.addBaseData();
      return;
    }

    this.materialID = this.materialTarget.value;
    const scenariosRequest = new FetchRequest(
      "get",
      `/materials/${this.materialID}/scenarios.json?scenario_ids=${scenarioIDs}`,
    );
    const scenariosResponse = await scenariosRequest.perform();
    if (scenariosResponse.ok) {
      const scenarios = await scenariosResponse.json;
      if (scenarios[0]) {
        this.updateNull(
          scenarios[0].data,
          this.parsedData,
          "scenario_two_demand",
          "scenario_two_production_plan",
          "scenario_two_capacity",
          "scenario_two_mos",
        );
      }
      this.adaptableApi.gridApi.resetGridData(this.parsedData);
      this.adaptableApi.gridApi.refreshColumn("cumulated_production_plan");
      this.chartRef1.destroyChart();
      this.chartRef2.destroyChart();
      this.createChart();
    }
  }

  removeNoDemandStartingMonths(data) {
    return data.filter((item) => item.demand > 0);
  }

  usFormattedNumber(number) {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
  }

  humanize(str) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/[_\s]+/g, " ")
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  downloadScenario(event) {
    if (event.target.dataset.exportType === "excel") {
      this.adaptableApi.exportApi.exportVisualDataToExcel();
    } else {
      let params = this.getParams();
      params.processCellCallback = function (cellParams) {
        if (cellParams && cellParams.column.colId === "month_year") {
          return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
          }).format(cellParams.value);
        } else return cellParams.value;
      };
      this.gridOptions.api.exportDataAsCsv(params);
    }
  }

  getParams() {
    return {
      allColumns: false,
      fileName: "Scenario Planning Comparison.csv",
    };
  }

  updateData(
    scenarioData,
    parsedData,
    demandKey,
    productionPlanKey,
    capacityKey,
    mosKey,
  ) {
    scenarioData.forEach((data) => {
      const matchingData = parsedData.find((item) => {
        return (
          moment(item.month_year).format("YYYY-MM") ===
          moment(data.month_year).format("YYYY-MM")
        );
      });

      if (matchingData) {
        matchingData[demandKey] = data["calculated_demand"];
        matchingData[productionPlanKey] = data["cumulated_production_plan"];
        matchingData[capacityKey] = data["capacity"];
        matchingData[mosKey] = data["mos"];
      }
    });
  }

  updateNull(
    scenarioData,
    parsedData,
    demandKey,
    productionPlanKey,
    capacityKey,
    mosKey,
  ) {
    scenarioData.forEach((data) => {
      const matchingData = parsedData.find((item) => {
        return (
          moment(item.month_year).format("YYYY-MM") ===
          moment(data.month_year).format("YYYY-MM")
        );
      });

      if (matchingData) {
        matchingData[demandKey] = null;
        matchingData[productionPlanKey] = null;
        matchingData[capacityKey] = null;
        matchingData[mosKey] = null;
      }
    });
  }
}
