import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "daySelect"];

  connect() {
    this.setContainerInnerHtml(this.daySelectTarget);
  }

  toggle(event) {
    const input = event.currentTarget;
    this.setContainerInnerHtml(input);
  }

  setContainerInnerHtml(input) {
    let selected = input.options[input.selectedIndex].value;
    const template = document.getElementById("last-day-notice");
    this.containerTarget.innerHTML = "";
    if (selected > 28) {
      this.containerTarget.insertAdjacentHTML(
        "beforeend",
        template.innerHTML.replace(/NUMBER/g, selected)
      );
    }
  }
}
