import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "button"];

  connect() {
    const checkedButton = this.buttonTargets.find((button) => button.checked);
    if (checkedButton) {
      let template = this.getTemplate(checkedButton);
      this.setContainerInnerHtml(template);
    }
  }

  toggle(event) {
    const input = event.currentTarget;
    let template = this.getTemplate(input);
    this.setContainerInnerHtml(template);
  }

  setContainerInnerHtml(template) {
    if (!template) {
      this.containerTarget.innerHTML = "";
      return;
    }

    const fields = template.content.cloneNode(true);
    this.containerTarget.innerHTML = "";
    this.containerTarget.appendChild(fields);
    const selectFields = this.containerTarget.querySelectorAll("select");
    if (selectFields) {
      selectFields.forEach((selectField) =>
        selectField.dispatchEvent(new Event("select2:add", { bubbles: true }))
      );
    }
  }

  getTemplate(input) {
    const templateId = input.dataset.templateId;
    const template = document.getElementById(templateId);

    return template;
  }
}
