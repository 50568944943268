import useCsvPlugin from "@usecsv/js";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["settingModal"];

  validateDataAndReturnErrors(rows, metadata, localStorageValue = null) {
    const materialId =
      event.target.parentElement.dataset.foresightForecastImportMaterialIdValue;
    return new Promise((resolve, reject) => {
      return fetch(
        `/${event.target.parentElement.dataset.foresightForecastImportResourceNameValue}/import/validate_foresight_csv.json`,
        {
          method: "POST",
          body: JSON.stringify({
            rows,
            metadata,
            localStorageValue,
            materialId,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "X-CSRF-Token":
              event.target.parentElement.dataset
                .foresightForecastImportFormAuthenticityTokenValue,
          },
        },
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Validation failed");
          }
        })
        .then((data) => {
          console.log(data);
          resolve(data.data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  sendDataToScenarioPlanning(data) {
    const scenarioPlanningController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "scenario-planning",
      );
    scenarioPlanningController.importForecast(data);
  }

  launch() {
    setTimeout(function () {
      $("#scenarioSettingModal").modal("hide");
    }, 1000);
    useCsvPlugin({
      importerKey: "3b6e89aa-a5c3-476f-971e-1c4001ca854e",
      user: { userId: "12345" },
      onData: (data) => {
        this.sendDataToScenarioPlanning(data);
      },
      onClose: () => {
        localStorage.removeItem("forecastRowsData");
        $("#scenarioSettingModal").modal("show");
        console.log("Importer is closed");
      },
      onRecordsInitial: ({
        uploadId,
        fileName,
        importerId,
        batch,
        user,
        metadata,
        rows,
      }) => {
        localStorage.setItem("forecastRowsData", JSON.stringify(rows));
        return this.validateDataAndReturnErrors(rows, metadata)
          .then((results) => {
            const arr = results.filter((data) => !!data);

            if (arr.length > 0) {
              return arr;
            } else {
              return;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
      onRecordEdit: ({
        uploadId,
        fileName,
        importerId,
        user,
        metadata,
        row,
      }) => {
        const rowsData = JSON.parse(localStorage.getItem("forecastRowsData"));
        if (rowsData) {
          const matchingRow = rowsData.find((data) => data.row === row.row);
          if (matchingRow) {
            Object.assign(matchingRow.data, row.data);
            localStorage.setItem("forecastRowsData", JSON.stringify(rowsData));
          }
        }
        return this.validateDataAndReturnErrors(
          row,
          metadata,
          JSON.parse(localStorage.getItem("forecastRowsData")),
        )
          .then((results) => {
            const arr = results.filter((data) => !!data);

            if (arr.length > 0) {
              return arr[0];
            } else {
              return;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      },
    });
  }
}
