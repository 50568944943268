import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "container", "removeYearButton"];

  static values = {
    lastYear: Number,
  };

  static classes = ["removeYearButtonContainer"];

  initialize() {
    this.lastYearValueChanged();
  }

  addAssociation(event) {
    event.preventDefault();

    this.setContent();
    this.appendContent();
    this.lastYearValue++;
  }

  setContent() {
    this.content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().getTime(),
    );
    this.content = this.content.replace(/RECORD_YEAR/g, this.lastYearValue + 1);
  }

  appendContent() {
    this.containerTarget.insertAdjacentHTML("beforeend", this.content);
  }

  removeAssociation(event) {
    event.preventDefault();

    const row = event.target.closest("tr");
    if (this.unitInputsHaveValues(row)) {
      Swal.fire({
        title: "",
        text: "Are you sure you want to remove?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.remove(row);
        }
      });
    } else {
      this.remove(row);
    }
  }

  unitInputsHaveValues(element) {
    const inputs = element.querySelectorAll(
      '[id^="forecast_forecast_values_attributes"][id$="_units"]',
    );
    const inputsArray = Array.from(inputs);
    return inputsArray.some(this.haveValue);
  }

  haveValue(input) {
    return input.value != "";
  }

  remove(row) {
    this.markForDestroy(row);
    this.hide(row);
    row.removeAttribute("id");
    this.lastYearValue--;
  }

  markForDestroy(row) {
    row.querySelectorAll("input[name*='_destroy']").forEach((input) => {
      input.value = 1;
    });
  }

  hide(element) {
    element.classList.toggle("d-none", true);
  }

  show(element) {
    element.classList.toggle("d-none", false);
  }

  lastYearValueChanged() {
    let currentYear = new Date().getFullYear();
    if (this.lastYearValue != currentYear) {
      let lastRow = document.getElementById(`year_${this.lastYearValue}`);
      this.removeYearButtonTargets.forEach((button) => {
        this.hide(button);
      });
      this.show(
        lastRow.querySelector(`.${this.removeYearButtonContainerClass} button`),
      );
    }
  }
}
