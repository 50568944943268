import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "table",
    "date",
    "action",
    "whodunnit",
    "resourceName",
    "resourceId",
  ];

  connect() {
    const tableTarget = this.tableTarget;

    $(this.modalTarget).on("show.bs.modal", (event) => {
      $(tableTarget).find("tr").remove();

      const button = $(event.relatedTarget); // Button that triggered the modal
      const changeset = button.data("changeset"); // Extract info from data-* attributes

      this.dateTarget.innerHTML = `${button.data("date")}`;
      this.actionTarget.innerHTML = `${button.data("event")}`;
      this.whodunnitTarget.innerHTML = `${button.data("whodunnit")}`;
      this.resourceNameTarget.innerHTML = `${button.data("resourceName")}`;
      this.resourceIdTarget.innerHTML = `${button.data("resourceId")}`;

      for (const key in changeset) {
        const changes = changeset[key];

        if (typeof changes[0] === "object" && changes[0] !== null) {
          continue;
        }

        const row = tableTarget.insertRow();

        const fieldNameCell = row.insertCell(0);
        const fieldNameNode = document.createTextNode(this.humanize(key));
        fieldNameCell.appendChild(fieldNameNode);

        const beforeChangeCell = row.insertCell(1);
        const beforeChange = changes[0] == null ? "-" : this.humanize(changes[0]);
        const beforeChangeNode = document.createTextNode(beforeChange);
        beforeChangeCell.appendChild(beforeChangeNode);

        const afterChangeCell = row.insertCell(2);
        const afterChange = changes[1] == null ? "-" : this.humanize(changes[1]);
        const afterChangeNode = document.createTextNode(afterChange);
        afterChangeCell.appendChild(afterChangeNode);
      }
    });
  }

  humanize(value) {
    if (typeof value === "string") {
      return value
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    } else if (value instanceof Date) {
      return value.toLocaleString();
    } else {
      return value;
    }
  }
}
