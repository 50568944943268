import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const socket = new WebSocket(
      this.data.get("socketUrl") + this.data.get("userId")
    );
    const material_uid = this.data.get("materialUid");

    socket.onopen = (e) => {
      console.log("Supply Plan Connection Established");
      const msg = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "SupplyPlanNotificationChannel",
          code: this.data.get("userId"),
        }),
      };
      socket.send(JSON.stringify(msg));
    };

    socket.onmessage = (event) => {
      if (
        JSON.parse(event.data).type !== "ping" &&
        JSON.parse(event.data).type !== "welcome" &&
        JSON.parse(event.data).type !== "confirm_subscription"
      ) {
        console.log(event.data);
        const rcd_msg = JSON.parse(event.data).message.message;
        if (rcd_msg.job_run === "true") {
          if (this.data.get("page") == "index") {
            if (rcd_msg.msg === "Generated") {
              set_generated(rcd_msg.material_hash);
            }
          } else {
            refresh_page(rcd_msg.material_hash);
          }
        } else {
          if (this.data.get("page") == "index") {
            if (rcd_msg.msg === "Generating") {
              set_generating(rcd_msg.material_ids);
            }
          }
        }
      }
    };

    function refresh_page(material_hash) {
      for (const key in material_hash) {
        if (material_hash.hasOwnProperty(key)) {
          if (material_uid === key) {
            setTimeout(function() {
              location.reload();
            }, 1000);
          }
        }
      }
    }

    function set_generating(material_uids) {
      material_uids.forEach((uid) => {
        const div = document.querySelector(`.material_${uid}`);
        if (div) {
          div.innerHTML = `<div class='material_${uid}'>Supply plan is generating..<i class='la la-circle-o-notch la-spin' style='font-size:24px'></i></div>`;
        }
      });
    }

    function set_generated(material_hash) {
      for (const key in material_hash) {
        if (material_hash.hasOwnProperty(key)) {
          const value = material_hash[key];
          const div = document.querySelector(`.material_${key}`);
          if (div) {
            const date = new Date(value);

            // const month = months[date.getMonth()];
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const year = date.getFullYear();
            let hours = date.getHours(); // Use "let" to allow reassignment
            const minutes = date.getMinutes();

            // Convert hours to 12-hour format
            const ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = hours ? hours : 12; // 0 should be converted to 12

            const formattedDate = `${month}/${day}/${year} ${hours}:${
              (minutes < 10 ? "0" : "") + minutes
            } ${ampm}`;
            div.innerHTML = formattedDate;
          }
        }
      }
    }

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `[close] Connection Closed, code=${event.code} reason=${event.reason}`
        );
        setTimeout(() => {
          this.connect();
        }, 1000);
      } else {
        console.log("[Close] Connection Died");
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  }
}
