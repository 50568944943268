import { Controller } from "@hotwired/stimulus";
import authThenLoad from "../packs/tableau_auth";

const server_url = "https://www.scmind-tableau.com";

export default class extends Controller {
  static targets = ["tabPane", "container"];
  static values = {
    view: String,
    showTabs: Boolean,
    tabbed: Boolean,
  };

  connect() {
    if (this.hasTabPaneTarget) {
      var tabPane = this.tabPaneTarget;
    } else {
      var tabPane = this.containerTarget;
    }

    this.respondToVisibility(tabPane, async (visible) => {
      if (visible && !this.tabbedValue) {
        this.tabbedValue = true;
        // const token = await this.get_token();
        this.set_height();
      }
    });
  }

  async get_token() {
    const response = await fetch("/tableau/get_token");
    const result = await response.json();
    return result.token;
  }

  set_height() {
    // this.containerTarget.src = this.url();
    // this.containerTarget.token = token;
    let height = "100vh";
    if (document.querySelector(".card-body")) {
      height = `${window.innerHeight - 46}px`;
    }
    this.containerTarget.height = height;
    this.containerTarget.width = "100%";
    this.setFullScreenEvent(height);
  }

  setFullScreenEvent(height) {
    let fullscreenchanged = (event) => {
      if (document.fullscreenElement) {
        if (document.fullscreenElement.dataset.fullscreenTarget === "content") {
          this.containerTarget.height = "100vh";
        }
      } else {
        this.containerTarget.height = height;
      }
    };
    document.addEventListener("fullscreenchange", fullscreenchanged);
  }

  initViz(container, url, hideTabs) {
    let height = "100vh";
    if (document.querySelector(".card-body")) {
      height = `${window.innerHeight - 46}px`;
    }

    let viz = new tableau.Viz(container, url, {
      hideTabs: hideTabs,
      hideToolbar: true,
      width: "100%",
      height: height,
    });

    let fullscreenchanged = (event) => {
      // document.fullscreenElement will point to the element that
      // is in fullscreen mode if there is one. If there isn't one,
      // the value of the property is null.
      if (document.fullscreenElement) {
        if (document.fullscreenElement.dataset.fullscreenTarget === "content") {
          viz.setFrameSize("100%", "100vh");
        }
      } else {
        viz.setFrameSize("100%", `${height}`);
      }
    };

    document.addEventListener("fullscreenchange", fullscreenchanged);

    window.addEventListener("refreshTableauData", function () {
      viz.refreshDataAsync();
    });
  }

  url() {
    return `${server_url}/views/${this.viewValue}`;
  }

  respondToVisibility = function (element, callback) {
    var options = {
      root: document.documentElement,
    };

    var observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry.intersectionRatio > 0);
      });
    }, options);

    observer.observe(element);
  };
}
