import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["toHide"];
  static targets = ["selectInput", "toToggle"];
  static values = {
    showOn: Array,
    selectorToHide: String,
  };

  connect() {
    if (this.hasSelectInputTarget) {
      const selectedOption = this.selectInputTarget.selectedOptions[0];
      const elementSelectors = eval(selectedOption.dataset.show);

      if (!elementSelectors) {
        return;
      }

      this.hideAll();
      this.showElements(elementSelectors);
    }
  }

  toggle(event) {
    const selectedOption = event.currentTarget.selectedOptions[0];
    const elementSelectors = eval(selectedOption.dataset.show);

    this.hideAll();

    if (!!elementSelectors) {
      this.showElements(elementSelectors);
    }
  }

  showElements(elementSelectors) {
    elementSelectors.forEach((elementSelector) => {
      const element = document.querySelector(elementSelector);
      this.show(element);
    });
  }

  show(element) {
    element.classList.toggle(this.toHideClass, false);
  }

  hideAll() {
    this.toToggleTargets.forEach((elementToToggle) => {
      this.hide(elementToToggle);
    });
  }

  hide(element) {
    element.classList.toggle(this.toHideClass, true);
  }

  get elementToToggle() {
    return document.querySelector(this.selectorToHideValue);
  }

  get selectedValue() {
    return this.selectInputTarget.value;
  }
}
