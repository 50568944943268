import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const socket = new WebSocket(
      this.data.get("socketUrl") + this.data.get("userId")
    );

    socket.onopen = (e) => {
      console.log("Auto Refresh Notification Connection Established");
      const msg = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "AutoRefreshNotificationChannel",
          code: this.data.get("userId"),
        }),
      };
      socket.send(JSON.stringify(msg));
    };

    socket.onmessage = (event) => {
      if (
        JSON.parse(event.data).type !== "ping" &&
        JSON.parse(event.data).type !== "welcome" &&
        JSON.parse(event.data).type !== "confirm_subscription"
      ) {
        console.log(event.data);
        const rcd_msg = JSON.parse(event.data).message.message;

        if (rcd_msg.job_run === "true") {
          location.reload();
        }
      }
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        console.log(
          `[close] Connection Closed, code=${event.code} reason=${event.reason}`
        );
        setTimeout(() => {
          this.connect();
        }, 1000);
      } else {
        console.log("[Close] Connection Died");
      }
    };

    socket.onerror = (error) => {
      console.log(`[error] ${error.message}`);
    };
  }
}
