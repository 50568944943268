import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "template", "addButton"];

  connect() {
    this.add();
  }

  add() {
    const newTemplate = this.template();
    const uniqueId = `custom-select-${Date.now()}`;
    const selectField = newTemplate.querySelector(".custom-select");
    selectField.setAttribute("id", uniqueId);
    this.containerTarget.insertBefore(newTemplate, this.addButtonTarget);
    $(`#${uniqueId}`).select2();
    selectField.dispatchEvent(new Event("select2:add", { bubbles: true }));
  }

  remove(e) {
    e.currentTarget.closest(".card").remove();
  }

  template() {
    return this.templateTarget.content.cloneNode(true);
  }
}
