import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["templateRow", "tableBody"];

  remove(event) {
    event.preventDefault();

    const row = event.target.closest("tr");

    if (!row.matches("[data-add-shipment-row-target='templateRow']")) {
      row.remove();
    }
  }

  add(event) {
    event.preventDefault();
    let content = this.templateRowTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    this.tableBodyTarget.insertAdjacentHTML("beforeend", content);

    const selectFields = this.tableBodyTarget.querySelectorAll("select");
    if (selectFields) {
      selectFields.forEach((selectField) =>
        selectField.dispatchEvent(new Event("select2:add", { bubbles: true }))
      );
    }
  }
}
