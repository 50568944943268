import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "supplierID",
    "ContactPersonName",
    "ContactPersonEmail",
    "ContactPersonPhoneNumber",
    "MaterialID",
  ];

  handleSelectChange() {
    const sourceId = this.supplierIDTarget.value;
    if (isNaN(parseInt(sourceId))) {
      this.clearInputs();
    } else {
      this.populateInputs(sourceId);
    }
  }

  populateInputs(sourceId) {
    fetch(`/suppliers/${sourceId}.json`, {
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        this.ContactPersonNameTarget.value = data.contact_person;
        this.ContactPersonPhoneNumberTarget.value =
          data.contact_person_phone_number;
        this.ContactPersonEmailTarget.value = data.contact_person_email;
      });
  }

  clearInputs() {
    this.ContactPersonNameTarget.value = "";
    this.ContactPersonPhoneNumberTarget.value = "";
    this.ContactPersonEmailTarget.value = "";
  }

  fetchSupplierData() {
    const materialId =
      this.MaterialIDTarget.options[this.MaterialIDTarget.selectedIndex].value;
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch("/supplier_materials/fetch_supplier_options.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        materialId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.populate(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the AJAX call
        console.error(error);
      });
  }

  populate(options) {
    if (options) {
      const supplierSelect = document.getElementById(
        "supplier_material_supplier_id"
      );

      if (supplierSelect) {
        supplierSelect.innerHTML = "";

        // Add the prompt option
        const promptOption = document.createElement("option");
        promptOption.value = "";
        promptOption.textContent = "Select Supplier";
        supplierSelect.appendChild(promptOption);

        for (const optionHtml of options) {
          const tempDiv = document.createElement("div");
          tempDiv.innerHTML = optionHtml;
          const optionElement = tempDiv.firstChild;

          supplierSelect.appendChild(optionElement);
        }
      }
    }
  }
}
