import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  update(event) {
    const checkbox = event.target;
    const id = checkbox.dataset.userId;
    const status = checkbox.checked;
    const notification_type = checkbox.dataset.type;
    const url = checkbox.dataset.url;

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({ id, status, notification_type }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          $.notify(
            {
              message: "Status updated successfully",
            },
            {
              type: "success",
              placement: {
                align: "center",
              },
            },
          );
        } else {
          $.notify(
            {
              message: "Failed to update the status: " + data.errors.join(", "),
            },
            {
              type: "error",
              placement: {
                align: "center",
              },
            },
          );
        }
      })
      .catch((error) => {
        $.notify(
          {
            message: "An error occurred: " + error.message,
          },
          {
            type: "error",
            placement: {
              align: "center",
            },
          },
        );
      });
  }
}
