$(document).on("turbo:before-cache", function () {
  $("select").each(function () {
    destroy(this);
  });
});

$(document).on("turbo:load", function () {
  $("select").each(function () {
    setup(this);
  });
});

$(document).on("select2:add", function (event) {
  setup(event.target);
});

function destroy(select) {
  if ($(select).hasClass("select2-hidden-accessible")) {
    $(select).select2("destroy");
  }
}

function setup(select) {
  let placeholder =
    select.options[0] && select.options[0].label == " "
      ? "Select"
      : select.options[0]?.label || "Select";

  if (select.dataset.select2Disabled === "true") return;

  $(select).select2({
    placeholder: placeholder,
    minimumResultsForSearch: searchType(select),
    width: "100%",
  });

  $(select).on("select2:select", function () {
    let event = new Event("change", { bubbles: true });
    select.dispatchEvent(event);
  });

  $(select).on("select2:select", function (e) {
    var data = e.params.data.text;
    if (data == "All") {
      $(select).find("option").prop("selected", "selected");
      $(e.params.data.element).prop("selected", false);
      $(select).trigger("change");
    }
  });
}

function searchType(select) {
  let search = select.parentElement.dataset.search || select.dataset.search;
  return search == "false" ? -1 : true;
}
