import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    icon: String,
    confirmButtonText: String,
    targetUrl: String,
    redirectUrl: String,
    showConfirmButton: Boolean,
  };

  confirm(event) {
    event.preventDefault();

    Swal.fire({
      title: this.titleValue,
      text: this.textValue,
      icon: this.iconValue || "warning",
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextValue || "Yes",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = this.targetUrlValue;
      }
    });
  }

  delete(event) {
    event.preventDefault();

    Swal.fire({
      title: this.titleValue,
      text: this.textValue,
      icon: this.iconValue || "warning",
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextValue || "Yes, delete it!",
      showConfirmButton: this.showConfirmButtonValue ?? true,
      showLoaderOnConfirm: true,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
      preConfirm: () => {
        return fetch(this.targetUrlValue, {
          method: "DELETE",
          headers: {
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "",
          icon: "success",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
          },
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary",
          },
        }).then(() => {
          window.location.href = this.redirectUrlValue;
        });
      } else if (result.dismiss) {
        Swal.fire({
          title: "Cancelled",
          text: "",
          icon: "error",
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
          },
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-secondary",
          },
        });
      }
    });
  }
}
