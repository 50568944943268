import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "modalMenu",
    "modalContent",
    "material",
    "scenarios",
    "newScenarioOptionTemplate",
    "parametersContainer",
    "stocksContainer",
    "stockTemplate",
    "purchaseOrdersContainer",
    "poTemplate",
    "noPurchaseOrdersContainer",
    "remainingShelfLife",
    "safetyStockType",
    "dynamicSafetyStockInputContainer",
    "safetyStockDuration",
    "safetyStockDurationPeriod",
    "fixedSafetyStockQuanity",
    "lotSizeType",
    "fixedLotSize",
    "minimumLotSize",
    "lotSizeRoundingValue",
    "yield",
    "name",
    "supplierTemplate",
    "suppliersContainer",
    "capacityTemplate",
    "addDemandVariationButton",
    "demandVariationsContainer",
    "demandVariation",
    "demandVariationType",
    "demandVariationStartDate",
    "demandVariationEndDate",
    "editForecastTab",
    "disabledButton",
    "compareButton",
    "aggregationType",
    "deleteBtn",
    "editForecastYearTemplate",
    "editForecastContainer",
    "forecastNestedForm",
    "blankCapacityTemplate",
    "blankCapacityContainer",
    "capacityYear",
    "addCapacityYearBtn",
    "blankStockTemplate",
    "addCapacityBtnTemplate",
    "noStocksContainer",
    "shelfLifeValue",
    "shelfLifePeriod",
    "sellByPeriod",
    "supplierMaterialsContainer",
    "supplierMaterials",
    "supplierPercentageAllocation",
    "uploadForecastBtn",
    "blankPoTemplate",
    "workOrdersContainer",
    "woTemplate",
    "noWorkOrdersContainer",
    "blankWoTemplate",
    "viewerDisabledButton",
    "mosType",
    "monthsOfSupply",
  ];

  static values = {
    lastYear: Number,
  };

  connect() {
    const stimulus = this;
    $(this.modalTarget).on("show.bs.modal", function () {
      stimulus.resizeModal();
    });
    $('#scenarioSettingModal a[data-toggle="pill"]').on(
      "shown.bs.tab",
      function () {
        stimulus.resizeModal();
      },
    );
  }

  async populateScenarios() {
    this.materialID = this.materialTarget.value;
    const scenariosResponse = await fetch(
      `/materials/${this.materialID}/scenarios.json`,
    );
    const scenarios = await scenariosResponse.json();

    this.scenariosTarget.innerHTML = "";
    let opt = document.createElement("option");
    opt.value = "";
    opt.innerHTML = "";
    this.scenariosTarget.append(opt);
    scenarios.map((scenario, i) => {
      let opt = document.createElement("option");
      opt.value = scenario.id;
      opt.innerHTML = scenario.name;
      this.scenariosTarget.append(opt);
    });

    const newScenarioOption = this.newScenarioOptionTemplateTarget.innerHTML;
    this.scenariosTarget.insertAdjacentHTML("afterbegin", newScenarioOption);
    this.scenariosTarget.disabled = false;

    $(this.scenariosTarget).select2({
      placeholder: "Select",
      width: "100%",
    });
  }

  async populate({ scenarioData, isNewScenario }) {
    this.disabledButtonTargets.forEach((button) => {
      button.disabled = false;
    });
    $("#v-pills-material-tab").click();

    const disabledLinks = document.querySelectorAll(".nav-link.disabled");
    // Remove the "disabled" class from each link
    disabledLinks.forEach((link) => {
      link.classList.remove("disabled");
    });

    const scenarioPlanningController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "scenario-planning",
      );

    this.scenarioData = scenarioData;
    this.yieldTarget.value = scenarioData.yield;
    this.nameTarget.value = scenarioData.name;
    this.remainingShelfLifeTarget.value =
      scenarioData.remaining_shelf_life_in_days;
    this.shelfLifeValueTarget.value = this.scenarioData.shelf_life_value;
    this.shelfLifePeriodTarget.value = this.scenarioData.shelf_life_period;
    this.sellByPeriodTarget.value =
      scenarioPlanningController.getSellByPeriod();
    this.deleteBtnTarget.href = `/materials/${this.materialID}/scenarios/${this.scenarioData.id}`;

    this.populateStocks();
    this.populatePurchaseOrders();
    this.populateWorkOrders();
    this.populateSafetyStock();
    this.populateLotSize();
    this.populateMonthOfSupplyType();
    this.populateSuppliers();
    this.populateSupllierMaterials();
    this.populateDemandVariations();
    this.populateForecastEditTab(
      this.scenarioData.data,
      this.scenarioData.edited_demands,
    );

    this.disableSettingForViwer();

    scenarioPlanningController.update({
      isNewScenario: isNewScenario,
      scenarioData: scenarioData,
    });
  }

  populateStocks() {
    const stocksData = this.scenarioData.stocks;
    this.stocksContainerTarget.innerHTML = "";
    this.noStocksContainerTarget.classList.remove("d-none");

    if (stocksData && stocksData.length > 0) {
      stocksData.forEach((stock) => {
        let content;
        if (stock.lot_number === "NA") {
          content = this.blankStockTemplateTarget.innerHTML.replace(
            /LOT_NUMBER/g,
            stock.lot_number,
          );
          if (stock.units !== "0" && stock.expiration_date !== "") {
            this.noStocksContainerTarget.classList.add("d-none");
          }
        } else {
          content = this.stockTemplateTarget.innerHTML.replace(
            /LOT_NUMBER/g,
            stock.lot_number,
          );
          this.noStocksContainerTarget.classList.add("d-none");
        }
        content = content.replace(
          new RegExp(`value="${stock.location}"`, "g"),
          `value="${stock.location}" selected`,
        );

        content = content.replace(/QUANTITY/g, stock.units);
        content = content.replace(/EXPIRATION_DATE/g, stock.expiration_date);
        this.stocksContainerTarget.insertAdjacentHTML("beforeend", content);
        if (stock.status && stock.status === "on") {
          document.querySelector(`#check-${stock.lot_number}`).checked = true;
        }
      });
    } else {
      let content = this.blankStockTemplateTarget.innerHTML.replace(
        /LOT_NUMBER/g,
        "NA",
      );
      content = content.replace(/LOCATION/g, "NA");
      content = content.replace(/QUANTITY/g, "0");
      this.stocksContainerTarget.insertAdjacentHTML("beforeend", content);
    }
  }

  populatePurchaseOrders() {
    const poData = this.scenarioData.purchase_orders;
    this.purchaseOrdersContainerTarget.innerHTML = "";
    this.noPurchaseOrdersContainerTarget.classList.remove("d-none");

    if (poData) {
      poData.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));

      poData.forEach((po) => {
        let content = this.poTemplateTarget.innerHTML.replace(
          /PO_NUMBER_UNPARSED/g,
          po.po_number,
        );
        content = content.replace(
          /PO_NUMBER/g,
          po.po_number.replace(/[^a-zA-Z0-9-_]/g, "_"),
        );
        content = content.replace(/PO_LINE_NUMBER/g, po.po_line_number);
        content = content.replace(
          new RegExp(`value="${po.supplier}"`, "g"),
          `value="${po.supplier}" selected`,
        );
        content = content.replace(/SUPPLIER/g, po.supplier);
        content = content.replace(/QUANTITY/g, po.open_quantity);
        content = content.replace(/DUE_DATE/g, po.due_date);
        content = content.replace(/MANUFACTURING_DATE/g, po.manufacturing_date);
        content = content.replace(/EXPIRATION_DATE/g, po.expiration_date);
        this.purchaseOrdersContainerTarget.insertAdjacentHTML(
          "beforeend",
          content,
        );
        if (po.status && po.status === "on") {
          document.querySelector(
            `#check-${po.po_number.replace(/[^a-zA-Z0-9-_]/g, "_")}-${po.po_line_number}`,
          ).checked = true;
        }
        this.noPurchaseOrdersContainerTarget.classList.add("d-none");
      });
    }
  }

  populateWorkOrders() {
    const woData = this.scenarioData.work_orders;
    this.workOrdersContainerTarget.innerHTML = "";
    this.noWorkOrdersContainerTarget.classList.remove("d-none");

    if (woData) {
      woData.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));

      woData.forEach((wo) => {
        let content = this.woTemplateTarget.innerHTML.replace(
          /WO_NUMBER_UNPARSED/g,
          wo.wo_number,
        );
        content = content.replace(
          /WO_NUMBER/g,
          wo.wo_number.replace(/\s+/g, "-"),
        );
        content = content.replace(/BATCH_NUMBER_UNPARSED/g, wo.batch_number);
        content = content.replace(
          new RegExp(`value="${wo.location}"`, "g"),
          `value="${wo.location}" selected`,
        );
        content = content.replace(/LOCATION/g, wo.location);
        content = content.replace(/QUANTITY/g, wo.quantity);
        content = content.replace(/START_DATE/g, wo.start_date);
        content = content.replace(/DUE_DATE/g, wo.due_date);
        this.workOrdersContainerTarget.insertAdjacentHTML("beforeend", content);
        if (wo.status && wo.status === "on") {
          document.querySelector(
            `#check-${wo.wo_number.replace(/\s+/g, "-")}`,
          ).checked = true;
        }
        this.noWorkOrdersContainerTarget.classList.add("d-none");
      });
    }
  }

  addWo(event) {
    event.preventDefault();
    const date_time = new Date().getTime();
    let content = this.blankWoTemplateTarget.innerHTML.replace(
      /WO_NUMBER/g,
      date_time,
    );
    content = content.replace(/QUANTITY/g, "0");
    this.workOrdersContainerTarget.insertAdjacentHTML("beforeend", content);
  }

  populateSafetyStock() {
    const safetyStockTypeElement = this.safetyStockTypeTargets.find(
      (element) => element.value === this.scenarioData.safety_stock_type,
    );
    safetyStockTypeElement.click();

    if (this.scenarioData.safety_stock_type === "fixed") {
      this.fixedSafetyStockQuanityTarget.value =
        this.scenarioData.fixed_safety_stock_quantity;
    } else {
      this.safetyStockDurationTarget.value =
        this.scenarioData.safety_stock_duration;
      this.safetyStockDurationPeriodTarget.value =
        this.scenarioData.safety_stock_duration_period;
    }
  }

  populateLotSize() {
    const lotSizeTypeElement = this.lotSizeTypeTargets.find(
      (element) => element.value === this.scenarioData.lot_size_type,
    );
    lotSizeTypeElement.click();

    if (this.scenarioData.lot_size_type === "fixed") {
      this.fixedLotSizeTarget.value = this.scenarioData.lot_size;
    } else if (this.scenarioData.lot_size_type === "minimum") {
      this.minimumLotSizeTarget.value = this.scenarioData.lot_size;
      this.lotSizeRoundingValueTarget.value = this.scenarioData.rounding_value;
    }
  }

  populateMonthOfSupplyType() {
    if (this.scenarioData.mos_calculation_type) {
      const monthOfSupplyTypeElement = this.mosTypeTargets.find(
        (element) => element.value === this.scenarioData.mos_calculation_type,
      );
      monthOfSupplyTypeElement.click();

      if (this.scenarioData.mos_calculation_type === "average_future_demand") {
        this.monthsOfSupplyTarget.value = this.scenarioData.months_of_supply;
      }
    } else {
      const monthOfSupplyTypeElement = this.mosTypeTargets.find(
        (element) => element.value === "actual_future_demand",
      );
      monthOfSupplyTypeElement.click();
    }
  }

  populateSupllierMaterials() {
    const supplierMaterialsData = this.scenarioData.supplier_materials;
    this.supplierMaterialsContainerTarget.innerHTML = "";
    if (supplierMaterialsData) {
      for (const supplierID in supplierMaterialsData) {
        const smd = supplierMaterialsData[supplierID];
        let content = this.supplierMaterialsTarget.innerHTML
          .replace(/SUPPLIER_ID/g, supplierID)
          .replace(/SUPPLIER_NAME/g, smd["location"])
          .replace(/PERCENTAGE/g, smd["percentage_allocation"]);

        if (smd["lot_size_type"] === "fixed") {
          content = content.replace(
            /data-template-id="supplier_fixed_template"/g,
            'data-template-id="supplier_fixed_template" data-initialized="true"',
          );
          content = content.replace(
            /SUPPLIER_FIXED_LOT_SIZE/g,
            smd["lot_size"],
          );
        } else if (smd["lot_size_type"] === "minimum") {
          content = content.replace(
            /data-template-id="supplier_minimum_template"/g,
            'data-template-id="supplier_minimum_template" data-initialized="true"',
          );
          content = content.replace(
            /SUPPLIER_MINIMUM_LOT_SIZE/g,
            smd["lot_size"],
          );
          content = content.replace(
            /SUPPLIER_ROUNDING_VALUE/g,
            smd["rounding_value"],
          );
        }

        this.supplierMaterialsContainerTarget.insertAdjacentHTML(
          "beforeend",
          content,
        );
        const radioInput = this.supplierMaterialsContainerTarget.querySelector(
          `.radio-${supplierID}`,
        );
        if (radioInput) {
          radioInput.checked = true;
        }
      }
    }
  }

  async populateSuppliers() {
    const today = new Date();
    const suppliersData = this.scenarioData.suppliers;
    this.suppliersContainerTarget.innerHTML = "";
    this.blankCapacityContainerTarget.innerHTML = "";
    if (suppliersData) {
      for (const supplierID in suppliersData) {
        if (supplierID === "NA") {
          const capacityData = suppliersData[supplierID];
          for (const year in capacityData) {
            this.populateBlankCapacity(year, capacityData[year], supplierID);
          }
        } else {
          this.addCapacityYearBtnTarget.classList.add("d-none");
          let content = this.supplierTemplateTarget.innerHTML
            .replace(/SUPPLIER_ID/g, supplierID)
            .replace(/SUPPLIER_NAME/g, suppliersData[supplierID]["name"]);

          const capacityData = suppliersData[supplierID];

          this.suppliersContainerTarget.insertAdjacentHTML(
            "beforeend",
            content,
          );
          this.checkForBlankCapacity(capacityData, supplierID);

          const keys = Object.keys(capacityData);
          for (const [index, year] of keys.entries()) {
            if (Number(year) < today.getFullYear()) {
              continue;
            } else if (year === "status") {
              document.querySelector(`#check-${supplierID}`).checked = true;
              continue;
            } else if (year == "name") {
              continue;
            }

            let capacityContent = this.capacityTemplateTarget.innerHTML.replace(
              /RECORD_YEAR/g,
              year,
            );
            capacityContent = capacityContent.replace(
              /RECORD_VALUE/g,
              capacityData[year],
            );
            capacityContent = capacityContent.replace(
              /SUPPLIER_ID/g,
              supplierID,
            );
            document
              .querySelector(`#collapse-${supplierID}`)
              .insertAdjacentHTML("beforeend", capacityContent);
            const isLastIndex = index === keys.length - 3;
            if (isLastIndex) {
              let btnContent =
                this.addCapacityBtnTemplateTarget.innerHTML.replace(
                  /SUPPLIER_ID/g,
                  supplierID,
                );
              btnContent = btnContent.replace(/LAST_YEAR/g, year);
              document
                .querySelector(`#collapse-${supplierID}`)
                .insertAdjacentHTML("beforeend", btnContent);
            }
          }
        }
      }
    } else {
      const currentDate = new Date();
      const year = currentDate.getFullYear();

      this.populateBlankCapacity(year, "0", "NA");
    }
  }

  populateBlankCapacity(year, value, supplierID) {
    let capacityContent = this.blankCapacityTemplateTarget.innerHTML.replace(
      /RECORD_YEAR/g,
      year,
    );
    capacityContent = capacityContent.replace(/SUPPLIER_ID/g, supplierID);
    capacityContent = capacityContent.replace(/CAPACITY_VALUE/g, value);
    capacityContent = capacityContent.replace(
      /CONTAINER/g,
      "blankCapacityContainer",
    );
    this.lastYearValue = year;
    this.blankCapacityContainerTarget.insertAdjacentHTML(
      "beforeend",
      capacityContent,
    );
    this.addCapacityYearBtnTarget.classList.remove("d-none");
    let remove_btn = document.querySelector(`.remove-${supplierID}-${year}`);
    if (remove_btn) {
      remove_btn.remove();
    }
  }

  checkForBlankCapacity(capacityData, supplierID) {
    const expectedData = { name: "", status: "on" };
    const hasExactKeys =
      Object.keys(capacityData).length === Object.keys(expectedData).length &&
      Object.keys(expectedData).every((key) =>
        capacityData.hasOwnProperty(key),
      );

    if (hasExactKeys) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();

      let capacityContent = this.blankCapacityTemplateTarget.innerHTML.replace(
        /RECORD_YEAR/g,
        year,
      );
      capacityContent = capacityContent.replace(/CAPACITY_VALUE/g, "0");
      capacityContent = capacityContent.replace(/SUPPLIER_ID/g, supplierID);
      document
        .querySelector(`#collapse-${supplierID}`)
        .insertAdjacentHTML("beforeend", capacityContent);

      let btnContent = this.addCapacityBtnTemplateTarget.innerHTML.replace(
        /SUPPLIER_ID/g,
        supplierID,
      );
      btnContent = btnContent.replace(/LAST_YEAR/g, year);
      document
        .querySelector(`#collapse-${supplierID}`)
        .insertAdjacentHTML("beforeend", btnContent);
    }
  }

  populateDemandVariations() {
    this.demandVariationsContainerTarget.innerHTML = "";
    if (
      this.scenarioData.demand_variations === null ||
      this.scenarioData.demand_variations.length === 0
    ) {
      this.addDemandVariationButtonTarget.click();
      return;
    }
    const demandVariations = this.scenarioData.demand_variations;
    demandVariations.forEach((variation) => {
      this.addDemandVariationButtonTarget.click();
      this.demandVariationTargets.pop().value = variation.value;
      this.demandVariationTypeTargets.pop().value = variation.type;
      this.demandVariationStartDateTargets.pop().value = variation.start_date;
      this.demandVariationEndDateTargets.pop().value = variation.end_date;
    });
  }

  populateForecastEditTab(forecastData, editedDemands = {}) {
    if (!forecastData) return;

    const groupedData = {};
    this.editForecastContainerTarget.innerHTML = "";

    forecastData.forEach((data) => {
      const year = new Date(data.month_year).getFullYear();

      if (!groupedData[year]) {
        groupedData[year] = [];
      }

      groupedData[year].push(data);
    });

    // Now you can use the groupedData object to populate the forecast edit tab
    // For example, you can iterate over the years and their corresponding data
    for (const year in groupedData) {
      const yearData = groupedData[year];

      let content = this.editForecastYearTemplateTarget.innerHTML.replace(
        /TEMPLATE_RECORD/g,
        new Date().getTime(),
      );
      content = content.replace(/RECORD_YEAR/g, year);

      // Do something with the yearData, such as creating HTML elements or updating the UI
      // ...
      this.editForecastContainerTarget.insertAdjacentHTML("beforeend", content);
      let inputs = this.editForecastContainerTarget.querySelectorAll(
        `#year_${year} .demand_input`,
      );

      yearData.forEach((data, i) => {
        const month = new Date(data.month_year).getMonth();
        inputs[month].value = data.calculated_demand;
        if (editedDemands && editedDemands[data.month_year]) {
          inputs[month].classList.add("bg-warning");
        }
      });
    }
    this.uploadForecastBtnTarget.dataset.foresightForecastImportMaterialIdValue =
      this.materialID;
  }

  updateParametersContainerDisplayState() {
    if (this.scenariosTarget.value == "") {
      this.settingsButtonTarget.disabled = false;
      this.aggregationTypeTarget.disabled = false;
    }
  }

  resizeModal() {
    const activeTab = this.modalTarget.querySelector(".nav-link.active");
    if (activeTab.innerText === "Demand") {
      this.modalTarget.querySelector(".modal-lg").style.maxWidth = "90%";
      this.modalMenuTarget.classList.replace("col-3", "col-2");
      this.modalContentTarget.classList.replace("col-9", "col-10");
    } else if (
      activeTab.innerText === "Purchase Orders" ||
      activeTab.innerText === "Work Orders"
    ) {
      this.modalTarget.querySelector(".modal-lg").style.maxWidth = "90%";
      this.modalMenuTarget.classList.replace("col-3", "col-2");
    } else {
      this.modalTarget.querySelector(".modal-lg").style.maxWidth = "800px";
      this.modalMenuTarget.classList.replace("col-2", "col-3");
      this.modalContentTarget.classList.replace("col-10", "col-9");
    }
  }

  emptyDemandVariationValue(event) {
    const index = Array.from(this.demandVariationTypeTargets).findIndex(
      (target) => target === event.currentTarget,
    );
    this.demandVariationTargets[index].value = 0;
    const scenarioPlanningController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "scenario-planning",
      );
    scenarioPlanningController.updateDemand();
  }

  addCapacityYear(event) {
    event.preventDefault();
    const supplierID = event.target.dataset.supplierId;
    const container = event.target.dataset.targetContainer;
    if (container == "blankCapacityContainer") {
      let year = this.lastYearValue + 1;
      let capacityContent = this.blankCapacityTemplateTarget.innerHTML.replace(
        /RECORD_YEAR/g,
        year,
      );
      capacityContent = capacityContent.replace(/SUPPLIER_ID/g, supplierID);
      capacityContent = capacityContent.replace(/CAPACITY_VALUE/g, "0");
      capacityContent = capacityContent.replace(/CONTAINER/g, container);
      let btn = document.querySelector(
        `.remove-${supplierID}-${this.lastYearValue}`,
      );
      if (btn) {
        btn.classList.add("d-none");
      }
      this.lastYearValue++;
      this.blankCapacityContainerTarget.insertAdjacentHTML(
        "beforeend",
        capacityContent,
      );
    } else {
      let year = parseInt(event.target.dataset.lastYear);
      year = year + 1;
      let capacityContent = this.blankCapacityTemplateTarget.innerHTML.replace(
        /RECORD_YEAR/g,
        year,
      );
      capacityContent = capacityContent.replace(/SUPPLIER_ID/g, supplierID);
      capacityContent = capacityContent.replace(/CAPACITY_VALUE/g, "0");
      capacityContent = capacityContent.replace(/CONTAINER/g, container);
      let btn = document.querySelector(
        `.remove-${supplierID}-${event.target.dataset.lastYear}`,
      );
      if (btn) {
        btn.classList.add("d-none");
      }
      event.target.dataset.lastYear = year;
      document
        .querySelector(`.add-${supplierID}`)
        .insertAdjacentHTML("beforebegin", capacityContent);
    }
  }

  removeCapacityYear(event) {
    event.preventDefault();
    const supplierID = event.target.closest(".btn-icon").dataset.supplierId;
    const container = event.target.closest(".btn-icon").dataset.targetContainer;
    const row = event.target.closest(".blank-capacity-data");
    let btn;
    if (container == "blankCapacityContainer") {
      this.lastYearValue--;
      btn = document.querySelector(
        `.remove-${supplierID}-${this.lastYearValue}`,
      );
    } else {
      const dataRow = event.target.closest(".btn-icon");
      const button = document.querySelector(`[id="btn-${supplierID}"]`);
      const year = parseInt(button.dataset.lastYear) - 1;
      button.dataset.lastYear = year;
      btn = document.querySelector(`.remove-${supplierID}-${year}`);
    }
    row.remove();

    if (btn) {
      btn.classList.remove("d-none");
    }
    const scenarioPlanningController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "scenario-planning",
      );
    scenarioPlanningController.updateCapacity();
  }

  addStock(event) {
    event.preventDefault();
    const date_time = new Date().getTime();
    let content = this.blankStockTemplateTarget.innerHTML.replace(
      /LOT_NUMBER/g,
      date_time,
    );
    content = content.replace(/QUANTITY/g, "0");
    this.stocksContainerTarget.insertAdjacentHTML("beforeend", content);
  }

  removeStockRow(event) {
    event.preventDefault();
    const dataRow = event.target.closest(".form-group");
    dataRow.remove();
    const scenarioPlanningController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "scenario-planning",
      );
    scenarioPlanningController.performCalculations();
  }

  addPo(event) {
    event.preventDefault();
    const date_time = new Date().getTime();
    let content = this.blankPoTemplateTarget.innerHTML.replace(
      /PO_NUMBER/g,
      date_time,
    );
    content = content.replace(
      /PO_LINE_NUMBER/g,
      new Date().setHours(new Date().getHours() + 1),
    );
    content = content.replace(/QUANTITY/g, "0");
    this.purchaseOrdersContainerTarget.insertAdjacentHTML("beforeend", content);
  }

  removePoRow(event) {
    event.preventDefault();
    const dataRow = event.target.closest(".form-group");
    dataRow.remove();
    const scenarioPlanningController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "scenario-planning",
      );
    scenarioPlanningController.performCalculations();
  }

  validateDateInput(event) {
    const input = event.target;
    const enteredDate = input.value;

    const isValidDate = /^(\d{4})-(\d{2})-(\d{2})$/.test(enteredDate);

    input.classList.toggle("is-invalid", !isValidDate);
  }

  validatePercentageSum(event) {
    const input = event.target;
    let sum = 0;
    this.supplierPercentageAllocationTargets.forEach((input) => {
      sum += parseInt(input.value, 10) || 0;
    });

    const isValid = sum === 0 || sum === 100;

    this.supplierPercentageAllocationTargets.forEach((input) => {
      input.classList.toggle("is-invalid", !isValid);
    });
  }

  updateExpirationDates(manufacturingDate) {
    const shelfLifeValue = parseFloat(this.shelfLifeValueTarget.value);
    const shelfLifePeriod = this.shelfLifePeriodTarget.value;
    const remainingShelfLifeInDays = parseFloat(
      this.remainingShelfLifeTarget.value,
    );

    let shelfLifeInMonths;
    if (shelfLifePeriod === "Months") {
      shelfLifeInMonths = shelfLifeValue;
    } else if (shelfLifePeriod === "Years") {
      shelfLifeInMonths = shelfLifeValue * 12;
    } else if (shelfLifePeriod === "Weeks") {
      shelfLifeInMonths = shelfLifeValue * 4;
    } else if (shelfLifePeriod === "Days") {
      shelfLifeInMonths = shelfLifeValue / 30;
    }

    const manufacturingInputs = document.querySelectorAll(
      ".po_manufacturing_date",
    );

    manufacturingInputs.forEach((input) => {
      const expDateField = document.querySelector(
        `#${input.dataset.expDateId}`,
      );
      let expirationDate = moment(input.value, "YYYY-MM-DD").toDate();

      expirationDate.setMonth(expirationDate.getMonth() + shelfLifeInMonths);
      expirationDate.setDate(
        expirationDate.getDate() - remainingShelfLifeInDays,
      );

      expirationDate = moment(expirationDate)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");

      expDateField.value = expirationDate;
    });
  }

  disableSettingForViwer() {
    const inputs = this.modalTarget.querySelectorAll(
      "input, select, checkbox, button",
    );

    if (this.modalTarget.dataset.userIsViewer === "true") {
      inputs.forEach(function (input) {
        if (input.getAttribute("data-dismiss") !== "modal") {
          input.disabled = true;
        }
      });
      this.viewerDisabledButtonTargets.forEach((button) => {
        button.classList.add("d-none");
      });
    } else {
      inputs.forEach(function (input) {
        input.disabled = false;
      });
      this.viewerDisabledButtonTargets.forEach((button) => {
        button.classList.remove("d-none");
      });
    }
  }
}
