import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "hiddenField"];

  submit(event) {
    event.preventDefault();

    this.hiddenFieldTarget.value = event.target.dataset.hiddenFieldValue;

    this.formTarget.submit();
  }
}
