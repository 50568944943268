import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "button"];

  connect() {
    const statusButton = this.buttonTargets.find((button) => button.checked);
    this.enableDisabledFields(statusButton);
  }

  toggle(event) {
    const input = event.currentTarget;
    this.enableDisabledFields(input);
  }

  enableDisabledFields(input) {
    if (input.value == "true") {
      this.containerTarget.classList.remove("d-none");
      this.containerTarget.classList.add("d-block");
    } else {
      this.containerTarget.classList.add("d-none");
      this.containerTarget.classList.remove("d-block");
    }
  }
}
