import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["blink"];

  connect(event) {
    if (this.blinkTarget.dataset.blinking) {
      this.blinkTarget.style.border = "2px solid #22316D";
      this.intervalId = setInterval(() => {
        this.blinkTarget.style.border = this.blinkTarget.style.border ? "" : "2px solid #22316D";
      }, 1000); // Blink every second

      // Remove blinking effect when the select input is clicked
      this.blinkTarget.addEventListener("click", () => {
        clearInterval(this.intervalId);
        this.blinkTarget.style.border = "";
      });
    }
  }
}
