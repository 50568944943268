import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "form"];

  resetAndClose(event) {
    event.preventDefault();
    this.formTarget.reset();
    $(this.modalTarget).modal("hide");
  }
}
