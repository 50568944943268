import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["requiredInput", "submitButton"];

  connect() {
    this.activate();
  }

  activate() {
    if (this.requiredInputTarget.value != "") {
      this.submitButtonTargets.forEach(
        (submitButtonTarget) => (submitButtonTarget.disabled = false)
      );
    } else {
      this.submitButtonTargets.forEach(
        (submitButtonTarget) => (submitButtonTarget.disabled = true)
      );
    }
  }
}
