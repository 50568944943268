import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["message"];
  static classes = ["success", "danger"];

  connect() {
    if (this.messageTarget.textContent) {
      this.showNotification();
    }
  }

  showNotification() {
    const message = this.messageTarget.textContent;
    const type = this.data.get("type");
    const alignment = this.data.get("alignment") || "center";
    const className = type === "alert" ? this.dangerClass : this.successClass;

    $.notify(
      {
        message: message,
      },
      {
        type: className,
        placement: {
          align: alignment,
        },
      }
    );
    this.messageTarget.style.display = "none";
  }
}
