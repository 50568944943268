import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alert"];

  connect() {
    if (!sessionStorage.getItem("notShowBrowserSupport")) {
      this.alertTarget.style.display = "block";
      this.alertTarget.classList.add("show");
    }
  }

  dontShowAlert() {
    sessionStorage.setItem("notShowBrowserSupport", true);
    this.alertTarget.classList.remove("show");
    this.alertTarget.style.display = "none";
  }
}
