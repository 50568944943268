import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "container", "parentSelect", "materialSelect"];

  addAssociation(event) {
    event.preventDefault();
    let content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().getTime()
    );
    this.containerTarget.insertAdjacentHTML("beforeend", content);

    const selectFields = this.containerTarget.querySelectorAll("select");
    if (selectFields) {
      selectFields.forEach((selectField) =>
        selectField.dispatchEvent(new Event("select2:add", { bubbles: true }))
      );
    }
  }

  connect() {
    this.updateChildOptions();
  }

  updateChildOptions() {
    const parentBaseUom =
      this.materialSelectTarget.options[this.materialSelectTarget.selectedIndex]
        .dataset.baseUom;
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch("/bills_of_materials/fetch_uom_options.json", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        parentBaseUom,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.populate(data.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the AJAX call
        console.error(error);
      });

    const selectFields = this.containerTarget.querySelectorAll("select");
    if (selectFields) {
      selectFields.forEach((selectField) =>
        selectField.dispatchEvent(new Event("select2:add", { bubbles: true }))
      );
    }
  }

  populate(options) {
    if (options) {
      this.parentSelectTarget.innerHTML = "";
      for (const optionHtml of options) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = optionHtml;
        const optionElement = tempDiv.firstChild;

        this.parentSelectTarget.appendChild(optionElement);
      }
    }
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-form-fields");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.classList.add("d-none");
  }
}
