import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["productFamilySelect", "materialSelect"];

  connect() {
    this.addAllOption();
  }

  fetchMaterials() {
    const productFamilyId = this.productFamilySelectTarget.value;

    fetch(`/product_families/${productFamilyId}/materials.json`)
      .then((response) => response.json())
      .then((materials) => this.populate(materials));
  }

  populate(materials) {
    $(this.materialSelectTarget).empty();

    materials.forEach((material) => {
      let newOption = new Option(material.uid, material.id, false, false);
      $(this.materialSelectTarget).append(newOption).trigger("change");
    });

    this.addAllOption();
  }

  addAllOption() {
    let data = {
      id: 1,
      text: "All",
    };
    let newOption = new Option(data.text, data.id, false, false);

    $(this.materialSelectTarget).append(newOption).trigger("change");
  }
}
