import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "template", "addButtonContainer", "select"];

  connect() {
    this.add();
  }

  add() {
    this.addButtonContainerTarget.insertAdjacentHTML(
      "beforebegin",
      this.template()
    );
  }

  remove(e) {
    e.currentTarget.closest(".card").remove();
  }

  template() {
    let content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    return content;
  }

  selectTargetConnected(element) {
    element.dispatchEvent(new Event("select2:add", { bubbles: true }));
  }
}
