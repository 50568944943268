import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "target"];

  confirm(event) {
    if (!this.inputTarget.checked) {
      Swal.fire({
        title: "Are you sure?",
        text: "Disabling shelf life extension will clear future expiry date for all stock lots belonging to this material. Are you sure you want to disable shelf life extension?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.targetTargets.forEach((target) =>
            target.classList.add("d-none"),
          );
        } else {
          event.preventDefault();
          this.inputTarget.checked = true;
          this.inputTarget.dispatchEvent(new Event("change"));
        }
      });
    }
  }
}
