import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "container", "removeYearButton", "uomLabel"];
  static values = {
    lastYear: Number,
  };
  static classes = ["removeYearButtonContainer"];

  initialize() {
    this.lastYearValueChanged();
  }

  addAssociation(event) {
    event.preventDefault();
    this.setContent();
    this.appendContent();
    this.lastYearValue++;
    this.setUomLabels();
  }

  setUom() {
    const uom =
      this.umosourceTarget.options[this.umosourceTarget.selectedIndex].text;
    this.uomtargetTargets.forEach((span) => {
      span.textContent = `/ ${uom}`;
    });
  }

  setContent() {
    this.content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().getTime()
    );
    this.content = this.content.replace(/RECORD_YEAR/g, this.lastYearValue + 1);
  }

  appendContent() {
    this.containerTarget.insertAdjacentHTML("beforeend", this.content);
  }

  setUomLabels() {
    if (this.uomSelectedText != "") {
      this.uomLabelTargets[
        this.uomLabelTargets.length - 1
      ].textContent = `/ ${this.uomSelectedText}`;
    }
  }

  hide(element) {
    element.classList.toggle("d-none", true);
  }

  show(element) {
    element.classList.toggle("d-none", false);
  }

  lastYearValueChanged() {
    let lastRow = document.getElementById(this.lastYearValue);
    if (lastRow) {
      this.removeYearButtonTargets.forEach((button) => {
        this.hide(button);
      });
      this.show(
        lastRow.querySelector(`.${this.removeYearButtonContainerClass} button`)
      );
    }
  }

  removeAssociation(event) {
    event.preventDefault();
    let item = event.target.closest(".nested-form-fields");
    item.removeAttribute("id");
    item.querySelector("input[name*='value']").removeAttribute("required");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.classList.add("d-none");
    this.lastYearValue--;
  }

  get uomSelectedText() {
    let ele = document.getElementById(
      "supplier_material_purchasing_unit_of_measure_id"
    );
    return ele.options[ele.selectedIndex].text;
  }
}
