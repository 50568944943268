import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectInput", "yieldInput"];

  static values = {
    skip: Boolean,
  };

  connect() {
    if (this.hasSelectInputTarget) {
      const selectedOption = this.selectInputTarget.selectedOptions[0];
      this.setDefaultValue(selectedOption);
    }
  }

  toggle(event) {
    const selectedOption = event.currentTarget.selectedOptions[0];
    this.setDefaultValue(selectedOption);
  }

  setDefaultValue(selectedOption) {
    if (selectedOption.dataset.yieldValue && !this.skipValue) {
      this.yieldInputTarget.value = selectedOption.dataset.yieldValue;
    }
  }
}
