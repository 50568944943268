import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(event) {
    let target = event.target;
    if (target.value == "2") {
      this.updateCheckbox(target, document.getElementById("user_role_ids_3"));
    } else if (target.value == "3") {
      this.updateCheckbox(target, document.getElementById("user_role_ids_2"));
    }
  }

  toggleTooltip(checkbox) {
    let tooltip = checkbox.closest(".role-tooltip");
    tooltip.dataset.originalTitle = checkbox.disabled
      ? "A user can either have a Supply Planner role or Viewer role"
      : "";
  }

  updateCheckbox(target, checkbox) {
    checkbox.disabled = target.checked;
    checkbox.checked = false;
    this.toggleTooltip(checkbox);
  }
}
