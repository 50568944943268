import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "target"];

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.inputTarget.checked) {
      this.targetTargets.forEach((target) => target.classList.remove("d-none"));
    } else {
      if (this.inputTarget.name != "material[shelf_life_extension]") {
        this.targetTargets.forEach((target) => target.classList.add("d-none"));
      }
    }
  }
}
